import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseSelect, BaseImgChip, BaseFileSelect, MasterLayoutMiddle, FooterInner, DrawerListRadioButton, DrawerListCheckbox, DrawerDateTime} from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import { getTeamMembers } from '../ContactUs/TeamMember/api';
import Moment from "moment-timezone";

function OrderFrom(props) {
  const [open, setOpen] = React.useState(false);
  const [uploadedImage, setuploadedImage] = useState(null)
  const [device, setdevice] = useState({ appStream: "", deviceId: "", macAddress: "", status: "Available", image: "", price: "" })

  const [deviceError, setdeviceError] = useState({ appStreamError: false, macAddressError: false, imageError: false, priceError: false })
  const [details, setdetails] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
  const [chipData, setchipData] = useState([])
  const [ itemName, setItemName] = useState('');
  const [itemId, setItemId] = useState('');
  const [orderId, setOrderId] = useState('');
  const [teamMembers, setteamMembers] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [assgineeDrawer, setAssgineeDrawer] = useState(false);
  const [startDateDrawer, setStartDateDrawer] = useState(false);
  const [endDateDrawer, setEndDateDrawer] = useState(false);
  const [assginee, setAssginee] = useState([]);
  const [values, setvalues] = useState([]);

  const [endDateToFormat, setEndDateToFormat] = useState('');
  const [endTimeToFormat, setEndTimeToFormat] = useState('');
  const [endDate, setEndDate] = useState('');
  const [endTime, setEndTime] = useState('');
  const [endDateandTime, setEndDateandTime] = useState('');
  const [tempEndDateandTime, setTempEndDateandTime] = useState('');

  const [startDate, setStartDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [startDateandTime, setStartDateandTime] = useState('');
  const [tempStartDateandTime, setTempStartDateandTime] = useState('');

  const [status, setStatus] = useState('New');


  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    try {
      const token = localStorage.getItem("authToken")
      const getData = async () => {

        const res2 = await getTeamMembers(token);
        setteamMembers(res2?.data?.body?.data);
        
        const member = res2?.data?.body?.data ?? [];
        if(member[0]) {

          const tempList = member.map((text, index) => {
            let nameArr = text?.name?.lastName ?  [text.name.firstName, text.name.lastName] : [text.name.firstName];
            let initials;
            if (nameArr.length > 1) {
              initials = nameArr[0][0] + nameArr[1][0];
            } else {
              initials = nameArr[0][0];
            }
        
            return ({
              id: index + 1,
              primary: nameArr.join(' '),
              fullName: nameArr.join(' '),
              secondary: text.role.map((r, i) => {
                return (
                  r.roles[0].toUpperCase() + r.roles.slice(1) + `${text.role.length === i + 1 ? '' : ', '}`
                );
              }),
              initials: !text?.imageDetails?.image ? initials : '',
              src: text?.imageDetails?.image ? text.imageDetails.image : '' /* initials.toUpperCase() */
            })
          });

          setTeamMemberList(tempList);
          
          let chipMember;
          let i;
          for(i = 0; i < member.length; i++) {
            const memberRole = member[i]?.role.filter(el => el.roles?.toLowerCase() === 'hikmah_owner');
            if(memberRole.length > 0) {
              chipMember =  member[i];
              break;
            }
          }

          let nameArr = chipMember ? [chipMember?.name?.firstName, chipMember?.name?.lastName] : [member[0]?.name?.firstName, member[0]?.name?.lastName]
          let initials;
          if (nameArr?.length > 1) {
            initials = nameArr[0][0] + nameArr[1][0];
          } else {
            initials = nameArr[0][0];
          }
          
          if(chipMember) {
            setchipData([{ key: chipMember._id, label: nameArr.join(' '), src: chipMember?.imageDetails?.image ?? "", alt: nameArr.join(' ')}])
            setAssginee([{ name: nameArr.join(' '), imageUrl: chipMember?.imageDetails?.image ?? "", role: chipMember?.role?.[0]?.roles}]);
            // setselectedData([{
            //    id: i + 1, primary: nameArr.join(' '), src: chipMember.imageUrl, secondary: chipMember.role,
            // }])
          } else {
            member?.length && setchipData([{ key: member[0]._id, label: nameArr.join(' '), src: member[0]?.imageDetails?.image ?? "", alt: nameArr.join(' ')}])
          }
          // setcontact({ ...contact, id: contactData?._id, status: contactData?.status ?? "New Order", assignee: [{name: nameArr.join(' '), imageUrl: member[0]?.imageDetails?.image ?? "", role: member[0]?.role?.[0]?.roles}] });
        }
      }
      getData();
    } catch(err) {
      console.log("Error Occured: ", err);
    }
    if(props?.location?.state?.itemName?.length) {
      setItemName(props.location.state.itemName);
      setOrderId(props.location.state.orderId);
      setItemId(props.location.state.itemId)

    }
    console.log("Props.location are: ", props.location)
  }, [])

  let handleChange = (prop) => (event) => {
    if ((prop === "price") && event.target.value && event.target.value <= 0) {
      return
    }
    setdevice({
      ...device, [prop]: event.target.value
    });

    let streamInitials = '';
    let value = event.target.value;

    if (prop === "appStream") {
      if (event.target.value === 'DEENKIOSK') {
        streamInitials = 'DK'
      } else if (event.target.value === 'DEENSCREEN') {
        streamInitials = 'DS'
      } else {
        streamInitials = ''
      }
      const orgBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
      axios.get(`${orgBaseUrl}/digital-device/getUniqueDeviceId?appStream=${streamInitials}`).then((result) => {
        console.log(result.data)
        setdevice({ ...device, appStream: value, deviceId: result.data })
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  const onChange = (e) => {
    setdetails(e)
  };

  let onSubmit = (e) => {
    e.preventDefault();
    // console.log("submit", details)
   
    let formData = {
      assginee,
      startDate: startDateandTime,
      endDate: endDateandTime,
      itemId,
      orderId,
      status
    };
    props.onUpdateOrderItem(formData);

    console.log("data to be submitted: ", formData);
  }

  let styles = {
    txt: {
      color: '#4D4D4D ',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '15px'
    },
    chipInput: {
      width: "100%"
    },
    backBtn: {
      backgroundColor: 'white',
      borderRadius: '18px',
      color: '#4D4D4D',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      width: '86px',
      height: '36px'
    },
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
      display: 'flex'
    },
    input: {
      height: '48px',
      marginTop: '20px',
      width: '100%',
      border: "#707070",
    },
    input2: {
      height: '48px',
      marginTop: '20px',
      marginRight: '22px',
      width: '48%',
      float: 'left',
      border: "#707070",
    },
    input3: {
      height: '48px',
      marginTop: '20px',
      width: '48%',
      border: "#707070",
    },
    inviteBtn: {
      textAlign: 'center',
      color: 'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    disableBtn: {
      textAlign: 'center',
      color: '#222',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#eee',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    cancelBtn: {
      textAlign: 'center',
      color: 'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'white',
      padding: '14px 45px'
    }
  }

  const style = {
    width:'100%',
    background: "#fff",
  };

  const callback = (selectedFiles) => {
    console.log(selectedFiles[0])
    if (selectedFiles.length > 0 && selectedFiles[0].src) {
      setuploadedImage(selectedFiles[0])
    }
  };

  const closeDrawer = () => {
    setAssgineeDrawer(false);
    setStartDateDrawer(false);
    setEndDateDrawer(false);
  };

  const onCancel = () => {
    setAssgineeDrawer(false);
    setStartDateDrawer(false);
    setEndDateDrawer(false);
  };

  const onSave = async () => {
    if(assgineeDrawer) {
      setAssgineeDrawer(false);
      setAssginee(values?.map((el, i) => {
          return { name: el.primary, imageUrl: el.src, role: el.secondary[0] }
      }));
      setchipData(values?.map((el, i) => {
          return { key: i, label: el.primary, src: el.src, alt: "",
        }
      }));
    } else if(endDateDrawer) {
      setEndDateDrawer(false);
      setEndDateandTime(tempEndDateandTime);
    } else if(startDateDrawer) {
      setStartDateDrawer(false);
      setStartDateandTime(tempStartDateandTime);
    }

    // setselectedData(values?.map((el, i) => {
    //   return {id: i , primary: nameArr.join(' '), src: chipMember.imageUrl, secondary: chipMember.role,}
    // }));

  }

  // const onSave = () => {
  //   if (uploadedImage) {
  //     setchipData([{ key: 0, label: uploadedImage.name, src: uploadedImage.src, alt: uploadedImage.name }])
  //     setdevice({ ...device, image: uploadedImage.src })
  //     setOpen(!open);
  //   }
  // };


  const onInputClick = () => {
    setAssgineeDrawer(true)
    console.log("input clicked");
  };

  const onChipInputClick = () => {
    console.log("input chip clicked");
    // setOpen(!open);
    setAssgineeDrawer(true)

  };

  const deleteChip = () => {
    setdevice({ ...device, image: "" })
    setchipData([])
    setAssginee([]);
  }

  const { appStream, deviceId, macAddress, image, price } = device;
  const { appStreamError, macAddressError, priceError } = deviceError;
  let disableBtn =  assginee.length > 0 && status.length > 0 && startDateandTime.length > 0 && endDateandTime.length > 0  ? false : true;




  const [content, setContent] = React.useState('');
  const [repeatcontent, setRepeatContent] = React.useState('');

  const childRef = React.useRef();

  const saveRepeats = (e) => {
    console.log('e', e)
    setRepeatContent(e)
    setContent(e.repeat_content)
    setOpen(false);
  };

  const cancleRepeats = (e) => {
    setOpen(false);
  };


  const data1 = [
    {
      id: 1,
      primary: "Discount Coupons",
      secondary: "Special event discount codes (e.g. EID2021, RAMADAN27)",
    },
    {
      id: 2,
      primary: "Promotion",
      secondary: "Automatically applied discount based on shopping cart",
    },

    {
      id: 3,
      primary: "Referral Codes",
      secondary: "Referral program with rewards (e. g. $20 for you and every invited friend)",
    },

  ];

  const getData = (values) => {
    console.log("Values are: ", values);
    if(!values?.length) return
    setvalues(values)
  }

  const selectedData = {
    id: 4,
    primary: "Salāt al-Eid al-Adha",
    secondary: "It is possible that some time this subtitle can be two line",
  };

  const getAssigneeData = (value, secondary) => {

  }

  const getEndDateTime = (value, boolean) => {
    let endDateTemp = '';
    if (!boolean) {
      endDateTemp = Moment(value).format("ddd, MMM DD, yyyy");
    }
    setEndDate(endDateTemp);
    setTempEndDateandTime((endDateTemp ? endDateTemp + ", " : "") + endTime)
    console.log("value is: ", value);
    console.log("Boolean is: ", boolean);
  }

  const endTimeFunc = (value) => {
    let endTimeTemp = Moment(value).format("hh:mm A");
    setEndTime(endTimeTemp);
    setTempEndDateandTime((endDate ? endDate + ", " : "") + endTimeTemp)
    console.log("End time value sis: ", value);
  }

  const getEndCheck = (value) => { 
    console.log("getEndCheck values is: ", value);
  }

  const getStartDateTime = (value, boolean) => {
    let startDateTemp = '';
    if (!boolean) {
      startDateTemp = Moment(value).format("ddd, MMM DD, yyyy");
    }
    setStartDate(startDateTemp);
    setTempStartDateandTime((startDateTemp ? startDateTemp + ", " : "") + startTime)
 
  }

  const startTimeFunc = (value) => {
    let startTimeTemp = Moment(value).format("hh:mm A");
    setStartTime(startTimeTemp);
    setTempStartDateandTime((startDate ? startDate + ", " : "") + startTimeTemp);
  }

  const getStartCheck = (value) => { 
    console.log("getStartCheck values is: ", value);
  }


  const getDrawerTitle = () => {
    if(assgineeDrawer) {
      return 'Select Member';
    } else if (endDateDrawer) {
      return 'End Date and Time';
    } else if(startDateDrawer) {
      return 'Start Date and Time';
    } else {
      return '';
    }
  }

  return (
    <Fragment>
      <DrawerMain
        open={open}
        openHandler={closeDrawer}
        title="Image"
        onCancel={onCancel}
        onSave={onSave}
        loading={false}
        // img={Logo}
        saveDisabled={false}
      >

        <BaseFileSelect
          selectedFiles={callback}
        />
      </DrawerMain>

      <MasterLayoutMiddle
        header={
          <div>
            {/* {
                    props.errors && props.errors.error && <AlertAction
                    alert={props.errors.message}
                    alertType={"error"}
                    //   onClick={this.closeAlert}
                    />
                    } */}
            {/* <Link to="/admin-organization" style={{textDecoration: 'none'}} > */}
            <div className="backBtnFrom">
              <BaseButton
                onClick={() => props.history.push("/hardware")}
                text="back"
                textStyle={styles.txt}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                style={styles.backBtn}
                startIcon={<ChevronLeftIcon />}
              />
            </div>
            {/* </Link> */}
            {props.page && props.page.loading && <Spinner />}
          </div>
        }
        isForm={true}
        formData={
          <div>
            <form onSubmit={onSubmit}>
              <Grid container spacing={3}>

                <Grid item xs={12}>
                  <h1 className="title_top">{itemName}</h1>
                </Grid>
                {console.log("Assignee is: ", assginee)}

                <Grid item xs={12}>
                  <div className="BaseImgChipField" onClick={onChipInputClick}>
                    <BaseImgChip
                      chipData={chipData}
                      onInputClick={onInputClick}
                      onChipInputClick={onChipInputClick}
                      deleteChip={deleteChip}
                      label="Assignee"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={startDateandTime}
                      // onChange={handleChange("name")}
                      InputProps={true}
                      style={{ width: '100%' }}
                      onClick={() => setStartDateDrawer(true)}
                      disabled={false}
                      onArrowClick={() => setStartDateDrawer(true)}
                      label="Start Date and Time"

                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={endDateandTime}
                      // onChange={handleChange("name")}
                      InputProps={true}
                      style={{ width: '100%' }}
                      onClick={() => setEndDateDrawer(true)}
                      disabled={false}
                      onArrowClick={() => setEndDateDrawer(true)}
                      label="End Date and Time"

                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseSelect
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      label='Status'
                      placeholder="Status"
                      object={[
                        { value: "New", label: "New" },
                        { value: "In-progress", label: "In-progress" },
                        { value: "Canceled", label: "Canceled" },
                        { value: "Shipped", label: "Shipped" },
                        { value: "On-hold", label: "On hold" },
                        { value: "Completed", label: "Completed" },
                        
                      ]}
                      style={styles.input}

                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="form_button">
                    <BaseButton
                      onClick={() => props.history.push("/hardware")}
                      text="cancel"
                      textStyle={styles.txt}
                      disableElevation={false}
                      disableFocusRipple={false}
                      disableRipple={false}
                      variant='text'
                    />
                    <BaseButton
                      onClick={onSubmit}
                      type="submit"
                      text={'Add Now'}
                      disabled={disableBtn}
                      disableElevation={false}
                      disableFocusRipple={false}
                      disableRipple={false}
                      style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">

                  </div>
                </Grid>

              </Grid>
            </form>


            <DrawerMain
              open={assgineeDrawer || endDateDrawer || startDateDrawer}
              openHandler={closeDrawer}
              title={getDrawerTitle()}
              onCancel={onCancel}
              loading={false}
              saveDisabled={false}
              onSave={onSave}
            >
              <div className="cardDrawer">
              {assgineeDrawer && <DrawerListCheckbox objects={teamMemberList}  getData={getData} />}
                  {/* <DrawerListRadioButton objects={teamMemberList}  getData={getData} selectedItems={assginee}/> */}

              {startDateDrawer && 
                <DrawerDateTime
                  title={""}
                  check={true}
                  checkValue = {true}
                  // defaultDate={endDateToFormat}
                  // defaultTime={endTimeToFormat}
                  dateLabel={"Date"}
                  timeLabel={"Time"}
                  switchLabel={"Date"}
                  getDateTime={getStartDateTime}
                  getTime={startTimeFunc}
                  getCheck={getStartCheck}
                  // dateError={dateError && !endDateToFormat}
                  // useAsPublish={false}
                  // dateHelperText={
                  //   dateError && !endDateToFormat && "End Date and Time required"
                  // }
                  // timeError={timeError && !this.state.endTime}
                  // timeHelperText={
                  //   timeError && !this.state.endTime && "End time required"
                  // }
                />
              }

              {endDateDrawer && 
                <DrawerDateTime
                  title={""}
                  check={true}
                  checkValue = {true}
                  // defaultDate={endDateToFormat}
                  // defaultTime={endTimeToFormat}
                  dateLabel={"Date"}
                  timeLabel={"Time"}
                  switchLabel={"Date"}
                  getDateTime={getEndDateTime}
                  getTime={endTimeFunc}
                  getCheck={getEndCheck}
                  // dateError={dateError && !endDateToFormat}
                  // useAsPublish={false}
                  // dateHelperText={
                  //   dateError && !endDateToFormat && "End Date and Time required"
                  // }
                  // timeError={timeError && !this.state.endTime}
                  // timeHelperText={
                  //   timeError && !this.state.endTime && "End time required"
                  // }
                />
              }
              </div>
            </DrawerMain>

            {/* <DrawerMain
              open={open}
              openHandler={() => closeDrawer('')}
              title="Campaign Type"
              onCancel={() => childRef.current.cancle()}
              onSave={() => childRef.current.save()}
              loading={false}
            >

              <DrawerListRadioButton objects={data1} getData={getData} selectedData={selectedData} />

            </DrawerMain> */}

          </div>

        }



        isFooter={true}
        footer={
          <FooterInner style={style} />
        }

        isWhiteBackground={true}


      />


    </Fragment>
  )

}

const mapStateToProps = state => {
  return {
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onAddDevice: (device) => dispatch(actions.addDevice(device, ownProps)),
    onUpdateOrderItem: (orderItem) => dispatch(actions.updateOrderItem(orderItem, ownProps)),
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(OrderFrom);