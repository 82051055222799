import React from 'react';
import { SimpleTable, CardDetailsTable } from "@dclab/dc-react-ui-kit";
import check_circle from '../../../assets/img/check_circle.svg';
import moment from 'moment';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import './../tab.css'


export default function tabContent3(props) {
  let organization = props.organization;
  let stripeOrgDetails = props.stripeOrgDetails;
  let orgOrders = props.orgOrders;

  console.log("stripeOrgDetails", stripeOrgDetails)

  let columns = [

    { type: 'number', name: 'serial_number', label: 'NO.', width: 'auto' },
    { type: "string", name: "category", label: "ORDER NO.", width: '20%' },
    { type: "string", name: "date/time", label: "ORDER DATE", width: '20%' },
    { type: "string", name: "date/time", label: "ORG NAME", width: '30%' },
    { type: "string", name: "date/time", label: "AMOUNT", width: '20%' },
    { type: "status", name: "status", label: "STATUS", width: '20%' },
    { type: "action", name: "action", label: "ACTION", width: '15%' },
    { type: "action", name: "action", label: "", width: '15%' },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      // delete obj.id;
      // delete obj.name;
      return obj;
    });

  let rows = orgOrders.map((item, index) => {
    console.log("orgOrders.map((item,: ", item)
    return ([
      { type: 'id', value: index + 1, data: { ...item } },

      {
        type: 'link',
        name: 'invoice_number',
        value: item.orderNo,
        url: '',
        linkType: "external"
      },
      { type: "string", name: "category", value: item.updatedAt ? moment(item.updatedAt).format("MMM DD, YYYY") : '' },
      { type: "string", name: "category", value: item?.orgId?.orgName ?? '' },
      { type: "string", name: "category", value: `$${(item?.totalAmount / 100).toFixed(2)}` },
      { type: "status", status: "success", value: item.status },
      { type: "action", status: "details", value: "See details" },
    ]);
  });

  // let rows = [
  //   [
  //     { type: 'id' },

  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "category", value: "May 06, 2020" },
  //     { type: "string", name: "category", value: "Islamic Institute of Brooklyn" },
  //     { type: "string", name: "category", value: "$19.99" },
  //     { type: "status", status: "success", value: "Approved" },
  //     { type: "action", status: "details", value: "See details" },
  //   ],

  //   [
  //     { type: 'id' },
  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "category", value: "May 06, 2020" },
  //     { type: "string", name: "category", value: "Islamic Institute of Brooklyn" },
  //     { type: "string", name: "category", value: "$19.99" },
  //     { type: "status", status: "success", value: "Approved" },
  //     { type: "action", status: "details", value: "See details" },
  //   ],

  //   [
  //     { type: 'id' },
  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "category", value: "May 06, 2020" },
  //     { type: "string", name: "category", value: "Islamic Institute of Brooklyn" },
  //     { type: "string", name: "category", value: "$19.99" },
  //     { type: "status", status: "success", value: "Approved" },
  //     { type: "action", status: "details", value: "See details" },
  //   ],

  //   [
  //     { type: 'id' },
  //     {
  //       type: 'link',
  //       name: 'invoice_number',
  //       value: '1848-5131',
  //       url: 'https://www.google.com',
  //       linkType: "external"
  //     },
  //     { type: "string", name: "category", value: "May 06, 2020" },
  //     { type: "string", name: "category", value: "Islamic Institute of Brooklyn" },
  //     { type: "string", name: "category", value: "$19.99" },
  //     { type: "status", status: "success", value: "Approved" },
  //     { type: "action", status: "details", value: "See details" },
  //   ],




  // ];

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite')
  }
  // console.log(rowFinder(rows));
  // console.log(value);

  // <SimpleTable rows={rowFinder(rows)} columns={columns1(columns)} colSpan={6} />;

  const actionLinkHandler = (name) => {
    console.log("checking name: ", name);
    // props.history.push('/varify-ein');
  }




  return (
    <React.Fragment>

      <SimpleTable
        showHeader={true}
        title="Orders"
        rows={rows}
        columns={columns1(columns)}
        colSpan={6}
        actionSendButton={true}
        classProps={changeClassProps()}
        onClick={(message, index) => click(message)}
        showFilter={false}
        showAdd={false}
        donationsCount={10}
        noPara="Add donation categories so that people can donate for a specific cause."
        noBtnText="Invite Donors"
        onInviteClick={() => btnClick()}
        showIconCol={true}
        showFooter={false}
        actionLink={(name) => props.history.push({ pathname: '/order/order-details', state: { orderData: name[0].data, returnUrl: `/orgorders/${props.orgId}` } })}

        onInviteClick={() => btnClick()}
        iconTextSize={14}
        sortColumnIndex={[4]}
      />
    </React.Fragment>
  )
}





