import React, { Component } from 'react';
import { BaseButton, BaseTextField, BaseSwitch, BaseSelect, MasterLayoutMiddle,
  FooterInner, FormSwitch  } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classes from './add.module.css';
// import { Link } from 'react-router-dom';
import RoleTable from './roleTable';
import plusImg from '../../assets/img/add_circle-24px.svg'; 

import Alert from '../../components/common/AlertAction';
import {connect} from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';



class Index extends Component {
  constructor(props){
    super(props);
    this.state = {
      showAlert: false,
      errors: {},
      loading : false,

      id:'',
      name: "",
      email: '',
      roles: [],
      role:'',
      application: '',
      checked: true,
      showAdditionalInfo: false,

      show: false,

      applicationDropdown: [
        {
          value: "DEENFUND",
          label: "DEENFUND",
        },
        {
          value: "DEENKIOSK",
          label: "DEENKIOSK",
        },
        {
          value: "others",
          label: "Others",
        },
      ],
      rolesDropdown: [
        {
          value: "Owner/Super Admin",
          label: "Owner/Super Admin",
        },
        {
          value: "Admin",
          label: "Admin",
        },
        {
          value: "Editor",
          label: "Editor",
        }
      ],
      nameError: false,
      emailError: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
  
    let errors = props.errors;
    let page = props.page;

  
    let stateChanged = false;
    let changedState = {};
  
    if(errors && JSON.stringify(state.errors) !== JSON.stringify(errors)){
      changedState.errors= errors;
      stateChanged = true;
    }
    
    if(page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)){
        changedState.loading = page.loading;
        stateChanged = true;            
    }
    
    if(stateChanged){
        return changedState;
    }  
      return null;
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({id: id});
  }

  showAlertHandler = () => {
    console.log("into showAlertHandler");
    console.log(this.state.showAlert);
    if(this.state.errors && this.state.errors.message){
      this.props.onClearError();
    }
    else{
      this.setState({
        showAlert: !this.state.showAlert
      });
    }
    
  }
  componentWillMount(){
    this.props.onClearError();
  }

  click = () => {
    console.log("button clicked");
  }

  handleChange = (prop) => (event) => {
    if(prop === 'checked'){
      this.setState({
        checked: !this.state.checked
      });
    }
    else{
      this.setState({
        [prop]: event.target.value
      });
    }
  }

  handleAdditionalInfo = () => {
    this.setState({ show: !this.state.show});
  }

  onSaveHandler = () => {
    const {application, role, roles} = this.state;
    console.log("checking previous data: ",roles );
    const index = roles.findIndex(r => r.roles === role || r.application === application);
    console.log("find index: ", index);
    if(index > -1){
      roles[index].roles = role;
      roles[index].application = application;
      console.log("checking data after editing: ", roles);
    }else{
      roles.push({
        application: application,
        roles: role
      });
    }
    
    this.setState({
      application: '',
      role: '',
      roles: roles,
      show: false
    });
  }

  onDeleteRoleHandler = (data) => {
    let {roles} = this.state;
    const updatadRoles =  roles.filter(role => data[0].value !== role.application);
    this.setState({
      roles: updatadRoles
    });
  }

  onEditRoleHandler = (data) => {

    this.setState({
      role: data[1].value,
      application: data[0].value,
      show: true
    });
  }

  onSubmitHandler = () => {
    const {name, email, roles, checked} = this.state;
    const id = this.props.match.params.id;
    console.log("Checking Id or organization: ", id);
    const formData = {
      firstName:name ? name.split(" ")[0] : "",
      lastName:name ? name.split(" ")[1] : "",
      userName:email,
      role: roles,
      orgId: id,
      consoleAccess: checked
    }
    this.props.onSubmit(formData, this.props.history);
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  styles = {
    txt:{
      color:'#4D4D4D',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '15px'
    },
    backBtn: {
      backgroundColor: 'white', 
      borderRadius: '18px', 
      color:'#4D4D4D',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      width: '86px',
      height: '36px'
    },
    // routes: {
    //   paddingTop: '30px',
    //   paddingLeft: '70px',
    //   height: '965px',
    //   width: '100%',
    //   display: 'flex',
    //   background: this.state.show && '#F2F2F2'
    // },
    input:{
      height: '48px',
      marginTop: '20px',
      width: '100%',
      border:"#707070",
    },
    inviteBtn:{
      textAlign: 'center',
      color:'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    cancelBtn:{
      textAlign: 'center',
      color:'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'transparent',
      padding: '14px 45px'
    },
    cancelBtn2:{
      textAlign: 'center',
      color:'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'transparent',
      padding: '14px 35px'
    },
    saveBtn2:{
      textAlign: 'center',
      color:'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 35px'
    },
    disableBtn: {
      textAlign: 'center',
      color: '#222',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#eee',
      borderRadius: '23px',
      padding: '14px 45px'
    },


  };

  render() {
    const styles = this.styles;
    let pageContent = '';
    let message = 'Invite sent successfully! ';
    let type = 'success';

    let { 
      errors, loading, showAlert,
      show, name, email, role, application, checked, roles,
      rolesDropdown, applicationDropdown,
      id, nameError, emailError
    } = this.state;

    console.log("checking State: ", this.state);

    if(loading){
      pageContent = <Spinner />
    }

    if(errors && errors.message ){
      message = errors.message;
      type = 'error';
      showAlert = true;
    }

    let disableBtn = name && this.validateEmail(email) && roles.length && id ? false : true; 

    const style = {
      width:'100%',
      background: "#fff",
    };
    return (
      <React.Fragment>

        <MasterLayoutMiddle


          header={
            
          <div>
            <Alert message={message} type={type} click={this.showAlertHandler} show={showAlert} />
              {/* <Link to="/organization-detail" style={{textDecoration: 'none'}} > */}

              <div className="backBtnFrom">
              <BaseButton 
                onClick={() => this.props.history.push(`/organization-detail-${id}`)} 
                text="back" 
                textStyle={styles.txt}
                disableElevation={false}
                disableFocusRipple={false}
                disableRipple={false}
                startIcon={<ChevronLeftIcon />}
              />
              </div>
            {/* </Link> */}
          </div>
          }

          isForm={true}

          formData ={

          <div onClick={() => show ? this.setState({show: false}) : ''} style={{ background: show ? '#F2F2F2' : '' }}>
              
            <Grid container spacing={3}>

              <Grid item xs={12}> 
                <h1 className="title_top">Add Team Member</h1>
              </Grid>	      

              <Grid item xs={12}> 
              <div className="field_box">
                <BaseTextField
                value={name}
                onChange={this.handleChange("name")}
                label='Name'
                placeholder="Name"
                InputProps={false}
                phone={false}
                search={false}
                //count={100}
                color="green"
                onBlur={() => name ? this.setState({ nameError: false }) : this.setState({ nameError: true })}
                error={!name && nameError ? true : false}
                // type="search"
              />
              </div>
              </Grid>

              <Grid item xs={12}> 
              <div className="field_box">
                <BaseTextField
                value={email}
                onChange={this.handleChange("email")}
                label='Email Address'
                placeholder="Email Address"
                InputProps={false}
                phone={false}
                search={false}
                //count={100}
                color="green"
                onBlur={() => this.validateEmail(email) ? this.setState({ emailError: false }) : this.setState({ emailError: true })}
                error={!this.validateEmail(email) && emailError ? true : false}            />
                </div>
              </Grid>

              <Grid item xs={12}> 
              <div className={classes.roletable}>
                {roles && roles.length ?
                    <RoleTable roles={roles} editAction={(data) => this.onEditRoleHandler(data)} deleteAction={(data) => this.onDeleteRoleHandler(data)} />
                    :
                    ''
                } 
                <div onClick={this.handleAdditionalInfo} className={classes.addtionText}><img src={plusImg} style={{paddingRight: '5px'}} alt='' /> ADD ADDITIONAL ROLE</div>
            </div>
                
                
              </Grid>

              <Grid item xs={12}>
                
              <div className="border_top_bottm">
              <FormSwitch
                headline='Member Console Access '
                body="Allow member to login to DEENCONNECT Console or revoke access"
                values={checked}
               // handleSwitchChange={(e) => handleSwitchChange(e)}
              />
              </div>


                {/* <div className={classes.memberConsole}>
                
                  <p>Member Console Access </p>
                
                  <div style={{marginLeft: '333px'}}>
                    <BaseSwitch
                      checked={checked}
                      onChange={this.handleChange("checked")}
                      name="harry"
                    />
                  </div>
                  <p className={classes.allowMemberText}>Allow member to login to DEENCONNECT Console or revoke access</p>

              </div> */}
              
              </Grid>

              

              <Grid item xs={12}> 
              <div className="form_button">
              <BaseButton 
                onClick={() => this.props.history.push(`/organization-detail-${id}`)} 
                text="cancel" 
              textStyle={styles.txt}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              variant='text'
            />
            <BaseButton 
              onClick={this.onSubmitHandler}
              text={'Add Now'}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              style={!disableBtn ? styles.inviteBtn : styles.disableBtn}
              disabled={disableBtn}
            />
              </div>
              </Grid>


              {show &&

              <div className="userRollBox">


              <Grid item xs={12}> 
                <h1 className="sub_title">Application and User Role</h1>
              </Grid>

              <Grid item xs={12}> 
                <div className="field_box">
                  <BaseSelect
                      value={application}
                      onChange={this.handleChange("application")}
                      label="Assign Application"
                      placeholder="Assign Application"
                      object={applicationDropdown}
                      //helperText="helper text"
                    />
                  </div>
              </Grid>


              <Grid item xs={12}> 
                <div className="field_box">
                  <BaseSelect
                      value={role}
                      onChange={this.handleChange("role")}
                      label="Role"
                      placeholder="Role"
                      object={rolesDropdown}
                      //helperText="helper text"
                    />
                </div>
              </Grid>


              <Grid item xs={12}> 
              <div className="form_button">
              <BaseButton 
                onClick={() => this.props.history.push(`/organization-detail-${id}`)} 
                text="cancel" 
                  textStyle={styles.txt}
                  disableElevation={false}
                  disableFocusRipple={false}
                  disableRipple={false}
                  variant='text'
                />
                <BaseButton 
                  onClick={this.onSaveHandler}
                  text={'SAVE'}
                  disableElevation={false}
                  disableFocusRipple={false}
                  disableRipple={false}
                  style={role && application ? styles.saveBtn2 : styles.disableBtn}
                  disabled={role && application ? false : true}
                />
              </div>

              {pageContent}
              </Grid>



          </div>

        }

            </Grid>
          </div>


          }



          isFooter={true}
          footer={
          <FooterInner style={style}/>
          }

          isWhiteBackground={true}


        />


      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
     page: state.page,
     errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
      onSubmit: (formData, history) => dispatch(actions.addTeamMember(formData, history)),
      onClearError: () => dispatch(actions.clearErrors())
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(Index);
