import React, { useState, useEffect } from 'react'
import { MasterLayoutMiddle, FooterInner, BaseButton, CardDetailsTable, Initials, CardStatus, SimpleTable } from "@dclab/dc-react-ui-kit";
import Grid from '@material-ui/core/Grid';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './style.css'
import moment from 'moment'
import OrgLogo from './OrgLogo.png'



function OrgPlanDetails(props) {

  const [plan, setplan] = useState({})
  const [details, setdetails] = useState({})

  useEffect(() => {
    if(props?.location?.state?.plan) {
      props?.location?.state?.plan && setplan(props.location.state.plan)
        props?.location?.state?.details && setdetails(props.location.state.details)
    }
}, [props?.location?.state?.plan, props?.location?.state?.details])


const capitalizeFLetter = (val) => {  
  return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
} 

let columns = [

  { id: 'NO', label: 'NO.', width: 'auto' },
{ id: "RECEIPT", label: "INVOICE NO.", width: "20%" },
{ type: "string", name: "name", label: "INVOICE Date", width: "20%" },
{ type: "string", name: "name", label: "Amount", width: "20%" },
{ type: "string", name: "name", label: "PAYMENT METHOD", width: "20%" },
{ type: "string", name: "status", label: "Status", width: '15%' },
{ type: "action", name: "action", label: "ACTION", width: "10%" },
  ];

  const rows =  [[
      { type: 'id', value: 1 },
      {
        type: "link",
        name: "invoice_number",
        value: plan?.receipt_number ? plan?.receipt_number.replace("#", '')  : '' ,
        url: plan?.receipt_url
      },
      { type: 'string', name: 'date', value: moment(plan?.created * 1000).format("LL")  },
      { type: "string", name: "name", value: plan.amount / 100 },
      { type: "string", name: "frequency", value: plan?.payment_method_details?.card?.brand?.toUpperCase() ?? '<span className="ondisableText">None</span>'  },
      { type: 'status', status: 'success', value: 'Success' },
      { type: "action", status: "active", value:  "VIEW RECEIPT" },
  ]];


  const style = {
    width:'100%',
    background: "#f7f8fa",
  };


  let header = {
    tableName: `Details`,

  };

 
//   let cardData1 = [
//     {
//         field: "Status ",
//         value: "Active"
//     },
//     {
//         field: "Current Plan",
//         value: plan?.subscription_plan ? `${plan?.subscription_plan ? plan.subscription_plan.toUpperCase() : ""} - ${plan?.subscription_plan_type?.toUpperCase() ?? ""}` : `${details?.name?.toUpperCase()}`
//         // - ${details?.appType?.toLowerCase() === "integration" ? "Integration" : "Free"}
//     },
//     {
//         field: "Plan Price",
//         value: plan?.amount ? `$${plan.amount / 100}/mo` : "$0"
//     },
//     {
//         field: "Start Date",
//         value: plan?.created ? moment(plan?.created * 1000).format('lll') : "N/A"
//     },
//     {
//         field: "Billing Cycle",
//         value : plan?.frequency ? capitalizeFLetter(plan?.frequency) : ""
//     },
// ];

let cardData1 = [
  {
      field: "Current Plan",
      value: details?.appType?.toLowerCase() === "integration" 
            ? plan?.amount ? 'Paid' : 'Free'
            : plan?.subscription_plan ? `${plan?.subscription_plan ? plan.subscription_plan.toUpperCase() : ""} - ${plan?.subscription_plan_type?.toUpperCase() ?? ""}` : `${details?.name?.toUpperCase()}`
      // - ${details?.appType?.toLowerCase() === "integration" ? "Integration" : "Free"}
  },
  {
      field: "Plan Price",
      value: plan?.amount ? `$${plan.amount / 100}/${plan?.frequency?.toLowerCase() === 'monthly' ? 'mo' : 'yr'}` : "$0"
  },
  {
      field: "Start Date",
      value: plan?.created ? moment(plan?.created * 1000).format("MMM DD, yyyy") : '<span className="ondisableText">None</span>'
  },
  {
      field: "Billing Cycle",
      value : plan?.frequency ? capitalizeFLetter(plan?.frequency) : '<span className="ondisableText">None</span>'
  },
];

// let cardData2 = [
//   {
//       field: "Next Payment on",
//       value: ""
//   },
//   {
//       field: "Next Payment Amount",
//       value: plan?.amount ? `$${plan.amount / 100}` : "$0"
//   },
//   {
//       field: "Payment Method",
//       value: plan?.payment_method_details?.card?.brand?.toUpperCase() ?? "" 
//   },
//   {
//       field: "Total Billed",
//       value: ""
//   },
// ];
let createdAt = moment(plan?.created * 1000);
let currentDate = moment();

let cardData2 = [
  {
      field: "Next Payment on",
      value: plan.amount 
        ? plan?.frequency?.toLowerCase() === 'monthly' 
          ? (createdAt.get('date') <= currentDate.get('date')) 
            ? createdAt.get('date') === currentDate.get('date') && createdAt.format('HH:mm') < currentDate.format('HH:mm')
              ? moment({ year: currentDate.get('month') + 1 > 11 ? currentDate.get('year') + 1 : currentDate.get('year'), month: currentDate.get('month') + 1 > 11 ? 0 : currentDate.get('month') + 1, day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute')}).format("MMM DD, yyyy")
              : moment({ year: currentDate.get('month') + 1 > 11 ? currentDate.get('year') + 1 : currentDate.get('year'), month: currentDate.get('month') + 1 > 11 ? 0 : currentDate.get('month') + 1, day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute')}).format("MMM DD, yyyy")
            : moment({ year: currentDate.get('year'), month: currentDate.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute')}).format("MMM DD, yyyy")
          : (createdAt.get('year') <= currentDate.get('year') && createdAt.get('month') <= currentDate.get('month') && createdAt.get('date') <= currentDate.get('date')) 
            ? createdAt.get('date') === currentDate.get('date') && createdAt.format('HH:mm') < currentDate.format('HH:mm')
              ? moment({ year: currentDate.get('year') + 1, month: createdAt.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute')}).format("MMM DD, yyyy")
              : moment({ year: currentDate.get('year') + 1, month: createdAt.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute')}).format("MMM DD, yyyy")
            : moment({ year: currentDate.get('year'), month: createdAt.get('month'), day: createdAt.get('date'), hour: createdAt.get('hour'), minute: createdAt.get('minute')}).format("MMM DD, yyyy")
        : '<span className="ondisableText">None</span>'
  },
  {
      field: "Next Payment Amount",
      value: plan?.amount ? `$${plan.amount / 100}` : '<span className="ondisableText">None</span>'
  },
  {
      field: "Payment Method",
      value: plan?.payment_method_details?.card?.brand?.toUpperCase() ?? '<span className="ondisableText">None</span>' 
  },
  {
      field: "Total Billed",
      value: plan?.amount ? `$${plan.amount / 100}` : "$0"
  },
];

  let footerLinkName = "";
  let footerDeleteButton = "";

  // HANDLE FOOTER LINK
  const onClick = () => {
    console.log('edit')
  }

  const onDeleteClick = () => {
    console.log('delete')
  }

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
  });

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick=() => {
    console.log('invite')
  }

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const receiptClicked = (value) => {
    window.open(value[1].url)
    console.log("Value is: ", value);
  }


  return (
    <div>

      <MasterLayoutMiddle

        header={
          <div className="backBtnFrom">
            <BaseButton
              onClick={() => props.history.goBack()}
              text="back"
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              startIcon={<ChevronLeftIcon />}
            />
          </div>
        }
        content={

          <div className="OrgDetailsContainer">

            <Grid container spacing={4}>

              {/* <Grid item xs={12}>
                <div className="OrgLogoHeaderContainer">

                  <div className="DetailsTitleContainer">
                    <div className="OrgLogocol"><img src={plan?.image ?? ""} /></div>
                    <div className="OrgDetailsTitle" style={{textTransform: "uppercase"}}>
                      <h3>{plan?.subscription_plan ?? ""} <p>{plan?.subscription_plan ?? ""}</p></h3>
                    </div>
                  </div>

                </div>
              </Grid> */}

              <Grid item xs={12}>
                <CardDetailsTable
                  data={cardData1} 
                  data2={cardData2}
                  twoColumns
                  header={header}
                  footerLinkName={footerLinkName}
                  footerDeleteButton={footerDeleteButton}
                  onClick={() => onClick()}
                  onDeleteClick={() => onDeleteClick()}

                  Status={true}
                    StatusLabel="Status"
                    StatusText={details?.status}
                    statuscolor={details?.status === "Active" ? '#3CC480' : '#C44058'}

                    OrgName={details?.displayName ?? details?.name}
                    OrgSubText={details?.appType}
                    OrgLogo={details?.appType?.toLowerCase() === "integration" ? details?.imageMetaData?.imageUrl : details?.iconMetaData?.iconUrl}
                    HeaderStatus={true}
                />
              </Grid>
              <Grid item xs={12}>
                <SimpleTable
                  showHeader={true}
                  title="Payment history"
                  rows={rows}
                  columns={columns1(columns)}
                  colSpan={6}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  onClick={(message, index) => click(message)}
                  showFilter={false}
                  showAdd={false}
                  donationsCount={10}
                  noPara="Add donation categories so that people can donate for a specific cause."
                  noBtnText="Invite Donors"
                  onInviteClick={() => btnClick()}
                  showIconCol={false}
                  showFooter={false}
                  actionLink={(name) => receiptClicked(name)}

                  onInviteClick={() => btnClick()}
                  iconTextSize={14}
                  sortColumnIndex={[5]}
                  rowsPerPage={10}
                  showFooter={rows?.length > 10 ? true : false}


                />
              </Grid>

            </Grid>

          </div>
        }


        isFooter={true}
        footer={
          <FooterInner style={style} />
        }

        isWhiteBackground={false}


      />




    </div>
  )
}




export default OrgPlanDetails;


