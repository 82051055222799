import React, { Component } from 'react';
import { BaseButton, BaseTextField, BaseSelect, EventLogs, TextEditor, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import './varifyEin.module.css';
import { Link } from 'react-router-dom';
import Accordian from './expension';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Check from "../../assets/img/check_circle-24px.svg";
import Add from "../../assets/img/add_circle2-24px.svg";
import Cancel from "../../assets/img/cancel-24px.svg";
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import moment from 'moment';


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccordian: true,
      orgData: {},
      id: '',
      taxId: "",
      urlLink: '',
      idstatus: "",
      exemptStatus: "",
      reason: [{ type: 'paragraph', children: [{ text: "" }] }],
      reasonTextCount: 0,
      showReasonField: false,
      taxNum: this.props?.organization?.taxIdStatus?.taxIdEin ?? "",
      taxExempt: false,
      details: [{ type: 'paragraph', children: [{ text: "" }] }],
      object: [
        {
          value: "Pending ",
          label: "Pending ",
        },
        {
          value: "Approved",
          label: "Approved",
        },
        {
          value: "Rejected",
          label: "Rejected",
        },
      ]

    };
  }

  componentDidMount() {
    if(this.props?.location?.state) {
      const temp = this.props.location.state;
      this.setState({
        orgData: temp,
        idstatus: temp?.taxIdEinDetail?.status ?? "",
        exemptStatus: temp?.taxExemptDetail?.status ?? "",
        taxNum: temp?.taxIdEin ?? "",
        taxExempt: temp?.taxExempt ?? false
      })
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const id = this.props.match.params.id;
    this.setState({
      id: id
    });
    // this.props.onGetSingleOrganiztion(id);
  }

  click = () => {
    console.log("button clicked");
  };

  callback = (selectedFiles) => {
    console.log(selectedFiles);
  }

  handleChange = (prop) => (event) => {
    console.log("event.target.value: ", event.target.value);
    let tempReason = false;
    if(prop === 'exemptStatus' && event.target.value === 'Rejected') {
      tempReason = true
    } else {
      tempReason = false;
    }
    this.setState({
      [prop]: event.target.value,
      showReasonField: tempReason
    });
  };


  onDescriptionStateChange(editorState) {
    let sum = 0;
    editorState.map((st) => {
      if (st.type === "bulleted-list" || st.type === "numbered-list") {
        st.children.map((e) => {
          e.children.map((ch) => {
            sum += ch.text.length;
          });
        });
      } else {
        st.children.map((e) => {
          sum += e.text.length;
        });
      }
    });

    this.setState({
      reason: editorState,
      reasonTextCount: sum
    });
  };
  save = data => {
    console.log(data);
  };

  onChange = (e) => {
    this.setState({
      details: e
    })
  };


  styles = {
    txt: {
      color: '#4D4D4D',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '15px'

    },
    backBtn: {
      backgroundColor: 'white',
      borderRadius: '18px',
      color: '#4D4D4D !important',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '14px !important',
      width: '86px !important',
      height: '36px !important'
    },
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
      display: 'flex'
    },
    input: {
      height: '48px',
      marginTop: '20px',
      width: '100%',
      border: "#707070",
    },
    inviteBtn: {
      textAlign: 'center',
      color: 'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9 0% 0% no-repeat padding-box',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    cancelBtn: {
      textAlign: 'center',
      color: 'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'white',
      padding: '14px 45px'
    },
  };

  submit = async () => {
    const { taxNum, orgData, taxExempt, idstatus,  exemptStatus, reason, id} = this.state;
    this.setState({ loading: true });
   
    const EventsArray = orgData?.events ? orgData.events : [];
    const oldTaxIdEinDetail = orgData?.taxIdEinDetail ?? {status: '', submittedAt: 0};
    const oldTaxExemptDetail = orgData?.taxExemptDetail ?? {status: '', submittedAt: 0};
    const oldTaxIdEin = orgData?.taxIdEin ?? '';
    const oldTaxExempt = orgData?.taxExempt ?? false;

    let taxIdEvents = [];


    if (taxNum.length <= 9) {
      this.setState({ error: true, loading: false });
    } else {
      const time = new Date();
      const timeStamp = time.getTime();
      
      if(oldTaxIdEinDetail.status?.toLowerCase() !== idstatus?.toLowerCase()) {
        taxIdEvents.push({
          eventType: 'EIN/Tax ID',
          status: idstatus,
          submittedAt: timeStamp,
          messages: []
        })
      }

      if(oldTaxExemptDetail?.status?.toLowerCase() !== exemptStatus?.toLowerCase()) {
        const user = localStorage.getItem("name");
        let msgs = [];
        if(exemptStatus.toLowerCase() == 'rejected') {
          msgs.push({
            title: 'Message',
            message: reason[0].children[0].text
          });
          if(user) {
            msgs.push({
              title: 'Reviewed by',
              message: user
            });
          }
        }
        taxIdEvents.push({
          eventType: 'Tax exemptions',
          status: exemptStatus,
          submittedAt: timeStamp,
          messages: msgs
        })
      }

      
      const taxIdStatus = {
        taxIdEin: taxNum,
        taxIdEinDetail: {
          ...oldTaxIdEinDetail,
          status: idstatus,
          reviewedAt: timeStamp
        },
        events: [
          ...EventsArray,
          ...taxIdEvents
        ],
        taxExempt,
          taxExemptDetail: {
            ...oldTaxExemptDetail,
            status: exemptStatus,
            reviewedAt: timeStamp
          }
      };

      this.props.onSubmit(id, {taxIdStatus}, this.props.history);
      
     
    }
  };

  getLogImage = (status) => {
    const temp = status?.toLowerCase();
    
    switch(temp) {
      case 'submitted':
      case 'resubmitted':
        return Add;

      case 'approved':
        return Check;

      case 'rejected':
        return Cancel;

      default: 
        return Add
    }
  }
  




  render() {
    const styles = this.styles;
    const { showAccordian, taxId, urlLink, status, object, taxNum, exemptStatus, idstatus, showReasonField, reason, orgData} = this.state;
    const numberRegex = /^[0-9-]*$/
    const style = {
      width:'100%',
      background: "#fff",
    };

    const data = orgData?.events && orgData?.events.length > 0 ? orgData.events?.sort((a, b) => b.submittedAt - a.submittedAt)?.map((data, index) => {
      return {
        id: index + 1, 
        icon: this.getLogImage(data.status), 
        status: data.status,  
        date: moment(data.submittedAt).format("MMM DD, YYYY, hh:mm A"), 
        details: [
          { title: 'Type', message: data.eventType },
            ...data.messages
        ]

      }
    }) : [];
    // [
    //   {
    //     id: 1, icon: Check, status: 'Approved', editIcon: Add, date: 'June 03, 2020, 12:00 PM', deleteIcon: Cancel, details: [
    //       { title: 'Reviewd by', message: 'Hassan khan' },
    //       {
    //         title: 'Message', message: ' We just want to send you a quick thank you note for your awesome contribution',
    //         link: "www.google.com"
    //       }]
    //   },

    //   {
    //     id: 2, icon: Add, status: 'Resubmitted', date: 'June 03, 2020, 12:00 PM', details: [

    //       {
    //         title: 'Message', message: ' We just want to send you a quick thank you note for your awesome contribution',
    //         link: "www.google.com"
    //       }]
    //   },

    //   {
    //     id: 3, icon: Cancel, status: 'Rejected', date: 'June 03, 2020, 12:00 PM', details: [{
    //       title: 'Message', message: '<p> We just want to send you a quick thank you note for your awesome contribution </p>'
    //     }]
    //   },

    //   {
    //     id: 4, icon: Add, status: 'Submitted', date: 'June 03, 2020, 12:00 PM', details: [{
    //       title: 'Message', message: ' We just want to send you a quick thank you note for your awesome contribution',
    //       link: "www.google.com"
    //     }]
    //   },
    // ];


    return (
      <React.Fragment>


        <MasterLayoutMiddle


          header={

            <div>
              <Link to={`/organization-detail-${this.state.id}`} style={{ textDecoration: 'none' }} >

                <div className="backBtnFrom">
                  <BaseButton
                    onClick={this.click}
                    text="back"
                    textStyle={styles.txt}
                    disableElevation={false}
                    disableFocusRipple={false}
                    disableRipple={false}
                    style={styles.backBtn}
                    startIcon={<ChevronLeftIcon />}
                  />
                </div>
              </Link>
            </div>
          }


          isForm={true}

          formData={

            <div>
              <Grid container spacing={3}>


                <Grid item xs={12}>
                  <h1 className="title_top">EIN/Tax ID</h1>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseTextField
                      value={taxNum}
                      onChange = {(e) => {
                        if (numberRegex.test(e.target.value) && e.target.value.length < 11) {
                          if (e.target.value !== "" && e.target.value.indexOf('-') === -1 && e.target.value.length === 2) {
                            this.setState({ taxNum: e.target.value + '-' })
                          }
                          else {
                            this.setState({ taxNum: e.target.value })
                          }
                        }
                      }}
                      error={taxNum.length <= 9}
                      label='EIN Number'
                      placeholder="EIN Number"
                      InputProps={false}
                      phone={false}
                      search={false}
                      count={100}
                      style={styles.input}
                      color="green"
                    //type="search"
                    />
                  </div>
                </Grid>


                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseSelect
                      value={idstatus}
                      onChange={this.handleChange("idstatus")}
                      label="EIN Status"
                      placeholder="EIN Status"
                      object={object}
                      autoWidth={true}
                      style={styles.input}
                    //helperText="helper text"
                    />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="field_box">
                    <BaseSelect
                      value={exemptStatus}
                      onChange={this.handleChange("exemptStatus")}
                      label="Tax Exemption status"
                      placeholder="Tax Exemption status"
                      object={object}
                      autoWidth={true}
                      style={styles.input}
                    //helperText="helper text"
                    />
                  </div>
                </Grid>

                {showReasonField && 
                  <Grid item xs={12}>
                    <div className="TextArea">
                      <TextEditor
                          onChange={(e) => this.onDescriptionStateChange(e)}
                          disabled={false}
                          value={reason}
                          // style={textEditorStyle}
                          label={"Reason for Rejection"}
                          maxCharCount={100}
                          // helperText={
                          //   error &&
                          //   !descriptionCount &&
                          //   "description is required"
                          // }
                          //error={error && !descriptionCount}
                      />
                    </div>
                  
                  </Grid>
                }

                <Grid item xs={12}>
                  <div className="form_button">
                    <BaseButton
                      onClick={() => this.props.history.push(`/organization-detail-${this.state.id}`)}
                      text="cancel"
                      textStyle={styles.txt}
                      disableElevation={false}
                      disableFocusRipple={false}
                      disableRipple={false}
                      variant='text'
                    />
                    <BaseButton
                      onClick={this.submit}
                      text={'Save'}
                      disableElevation={false}
                      disableFocusRipple={false}
                      disableRipple={false}
                      style={styles.inviteBtn}

                    />
                  </div>
                </Grid>

                <Grid item xs={12} className="accordionContainerD">
                  <EventLogs data={data}
                    headTitle="Events or Timeline"
                    schedule={true}
                    editSchedule={(e) => console.log('editSchedule', e)}
                    deleteSchedule={(e) => console.log('deleteSchedule', e)}
                  />

                </Grid>


              </Grid>

            </div>

          }



          isFooter={true}
          footer={
            <FooterInner style={style} />
          }

          isWhiteBackground={true}


        />


      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    organization: state.organizations.organization,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetSingleOrganiztion: (id) => dispatch(actions.getOrganizationDetail(id)),
    onSubmit: (id, formData, history) => dispatch(actions.editOrganization(id, formData, history)),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);


