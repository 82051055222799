import React from 'react';
import { SimpleTable, DrawerMain, BaseTextField, BaseSelect } from "@dclab/dc-react-ui-kit";
import CreateIcon from "../../../assets/img/create-24px.svg";
import DeleteIcon from "../../../assets/img/delete_forever-24px.svg";
import { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import Handle from '../../../assets/img/more_vert-24px.svg';

const Features = forwardRef((props, ref) => {
  const {integrationInfo, setstepDisabled} = props;
  
  const [open, setOpen] = React.useState(false);
  const [editFeature, seteditFeature] = useState(false)
  const [features, setfeatures] = useState({
    title: "", description: "", status: "Available",
  })

  const [selectedFeatures, setselectedFeatures] = useState([])

  useEffect(() => {
    let featureData = integrationInfo?.features?.length ? integrationInfo.features.map((el, i) => {
      return { ...el, order: el.order || el.order === 0 ? el.order : i + 100 }
    }) : []
    setselectedFeatures(featureData)
 }, [integrationInfo])

  useImperativeHandle(ref, () => ({
    onSubmit() {    
        if (selectedFeatures?.length) {
            let formData = { 
                features: selectedFeatures
            };
            console.log("submit2", formData)
            props.onAddIntegrationInfo(formData)
        }
    }
  }));


  let columns = [
    { id: 'drag', label: ' ', width: 'auto' },
    { type: "number", name: "serial_number", label: "", width:"auto" },
    { type: "string", name: "name", label: "FEATURE", width:"84%" },
    { type: "status", name: "status", label: "STATUS", width:"15%"  },
    { type: "action", name: "actionIcons", label: "ACTION", width:"15%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

    const checkStatus = (status) => {
      status = status ? status.toLowerCase() : "";
      if (status === 'available') {
        return "success"
      } else if (status === 'coming soon'){
        return "discontinued"
      } else if (status === 'not available'){
        return "failed"
      } else{
        return "discontinued"
      }
   }

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const actionLinkHandler = (data) => {
  }

  const editAction = (data) => {
    console.log("edit", data[0].id)
    let id = data[0].id;
    let feature = selectedFeatures.find((el, i) => i === id);
    setfeatures({id: id, ...feature});
    setOpen(true)
    seteditFeature(true)
  }

  const deleteAction = (data) => {
    let id = data[0].id;
    let prevFeatures = [...selectedFeatures];
    prevFeatures.splice(id, 1);
    setselectedFeatures([...prevFeatures]);
  }

  const closeDrawer = () => {
    setOpen(!open);
  };
  
  const onCancel = () => {
    setOpen(false);
    seteditFeature(false)
  };
  

  const onSave = () => {
    const {title, status, description } = features;
    if(editFeature && features.id !== undefined) {
      let prevFeatures = [...selectedFeatures];
      prevFeatures[features.id] = { id: undefined, ...features};
      setselectedFeatures([...prevFeatures]);
      seteditFeature(false)
      setOpen(false);
      setfeatures({
        title: "", description: "", status: "Available",
      });
    } else {
      if(title && status) {
        let prevFeatures = [...selectedFeatures];
        prevFeatures.push(features);
        setselectedFeatures([...prevFeatures]);
  
        setOpen(false);
        setfeatures({
          title: "", description: "", status: "Available",
        });
      }
    }
  };

  let styles = {
    input: {
      // height: '48px',
      // marginTop: '20px',
      width: '100%',
      border: "#707070",
      marginLeft: 0
  },
  }

  let handleChange = (prop) => (event) => {
      setfeatures({ ...features, [prop]: event.target.value });
  }
  const {title, status, description } = features;

  let rows = [];
  if (selectedFeatures && selectedFeatures.length) {
    rows = selectedFeatures.map((app, index) => {
      return (
        [
          { type: "", id: index, name: '', value: " "},
          // { type: "id", id: index, name: 'serial_number', value: index + 1},
          { type: "string", name: "category", value: app.title },
          { type: "status", status: checkStatus(app.status), value: app.status },
        ]
      )
    });
  }

  const sortData = (data) => {
    const selected_features = data.sort((a, b) => a.order - b.order);
    console.log("sorted", selected_features)
    setselectedFeatures([...selected_features])
  }
  

  setstepDisabled(selectedFeatures?.length ? false : true)

  return (<Fragment> 
      <DrawerMain
            open={open}
            openHandler={closeDrawer}
            title="Add Feature"
            onCancel={onCancel}
            onSave={onSave}
            loading={false}
            saveDisabled={title && status ? false : true}
        >
          <div className="field_box">
            <BaseTextField
                value={title}
                onChange={handleChange("title")}
                label='Feature Title'
                placeholder="Feature Title"
                InputProps={false}
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                count={50}
            />
          </div>

          <div className="field_box">
            <BaseTextField
                value={description}
                onChange={handleChange("description")}
                label='Description'
                placeholder="Description"
                InputProps={false}
                phone={false}
                search={false}
                color="green"
                style={styles.input}
                multiline={true}
                rowsMax={4}
                count={175}
            />
          </div>

          <div className="field_box">
            <BaseSelect
                value={status}
                onChange={handleChange("status")}
                object={[
                    {
                        value: "Available",
                        label: "Available",
                    },
                    {
                        value: "Coming Soon",
                        label: "Coming Soon",
                    },
                    {
                      value: "Not Available",
                      label: "Not Available",
                    },
                ]}
                helperText=""
                label='Status'
                placeholder="Status"
                style={styles.input}
            />
          </div>
       
      </DrawerMain>
    <SimpleTable
    title="Features"
    showHeader={true}
    rows={rows}
    data={selectedFeatures}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={true}
    actionLink={(data) => actionLinkHandler(data)}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    showFilter={false}
    showAdd={true}
    addText="ADD NEW"
    iconTextSize={14}
    editIcon={CreateIcon}
    deleteIcon={DeleteIcon}
    showIconCol={true}
    editAction={(data) => editAction(data)}
    deleteAction = { (data) => deleteAction(data)}
    donationsCount={rows?.length ? 1 : 0}
    onAddClick={() => setOpen(true)}
    noTitle="No Features"
    noPara="Add new features to the application."
    noBtnText="Add Feature"
    onInviteClick={() => setOpen(true)}
    sortColumnIndex={[3]}
    showFooter={rows?.length > 10 ? true : false}
    rowsPerPage={10}
    sortData={(e) => sortData(e)}
    parentTableDrag={true}
    DragIcon={Handle}
  />
  </Fragment>
  )
})

export default Features;
