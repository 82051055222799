import React from 'react';
import { SimpleTable, DrawerMain, BaseTextField, BaseSelect } from "@dclab/dc-react-ui-kit";
import CreateIcon from "../../../assets/img/create-24px.svg";
import DeleteIcon from "../../../assets/img/delete_forever-24px.svg";
import { Fragment, useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import Handle from '../../../assets/img/more_vert-24px.svg';
import Grid from '@material-ui/core/Grid';


const Features = forwardRef((props, ref) => {
  const { applicationInfo, setstepDisabled } = props;

  const [open, setOpen] = React.useState(false);
  const [editFeature, seteditFeature] = useState(false)
  const [features, setfeatures] = useState({
    title: "", description: "", featureType: "Free", price: 0, status: "Available",
  })

  const [selectedFeatures, setselectedFeatures] = useState([])

  useEffect(() => {
    let featureData = applicationInfo?.features?.length ? applicationInfo.features.map((el, i) => {
      return { ...el, order: el.order || el.order === 0 ? el.order : i + 100 }
    }) : []
    setselectedFeatures(featureData)
  }, [applicationInfo])

  const formDataFunc = (prevFeatures) => ({
    features: prevFeatures
  })
  useImperativeHandle(ref, () => ({
    onSubmit() {
      if (selectedFeatures?.length) {
        let formData = {
          features: selectedFeatures
        };
        console.log("submit2", formData)
        props.onAddAppInfo(formData)
      }
    }
  }));

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'available') {
      return "success"
    } else if (status === 'coming soon') {
      return "discontinued"
    } else if (status === 'not available') {
      return "failed"
    } else {
      return "discontinued"
    }
  }

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const actionLinkHandler = (data) => {
  }

  const editAction = (data) => {
    // console.log("edit", data)
    let id = data[0].id;
    let feature = selectedFeatures.find((el, i) => i === id);
    setfeatures({ id: id, ...feature });
    setOpen(true)
    seteditFeature(true)
  }

  const deleteAction = (data) => {
    let id = data[0].id;
    let prevFeatures = [...selectedFeatures];
    prevFeatures.splice(id, 1);
    setselectedFeatures([...prevFeatures]);
  }

  const closeDrawer = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
    seteditFeature(false)
  };


  const onSave = () => {
    const { title, featureType, status, description } = features;
    if (editFeature && features.id !== undefined) {
      let prevFeatures = [...selectedFeatures];
      prevFeatures[features.id] = { id: undefined, ...features };
      setselectedFeatures([...prevFeatures]);
      // props.setChangeData(formDataFunc(prevFeatures));
      props.setChanges(true);
      seteditFeature(false)
      setOpen(false);
      setfeatures({
        title: "", description: "", featureType: "Free", price: 0, status: "Available",
      });
    } else {
      if (title && featureType && status) {
        let prevFeatures = [...selectedFeatures];
        features.price = parseInt(features.price)
        prevFeatures.push(features);
        setselectedFeatures([...prevFeatures]);
        // props.setChangeData(formDataFunc(prevFeatures));
        props.setChanges(true);
        setOpen(false);
        setfeatures({
          title: "", description: "", featureType: "Free", price: 0, status: "Available",
        });
      }

    }

  };

  let styles = {
    input: {
      // height: '48px',
      // marginTop: '20px',
      width: '100%',
      border: "#707070",
      marginLeft: 0
    },
  }

  let handleChange = (prop) => (event) => {
    if ((prop === "price") && event.target.value && event.target.value <= 0) {
      return
    }
    if (prop === "featureType" && event.target.value === "Free") {
      setfeatures({ ...features, [prop]: event.target.value, price: 0 });
    } else {
      setfeatures({ ...features, [prop]: event.target.value });
    }
  }

  let columns = [
    { id: 'drag', label: ' ', width: 'auto' },
    { type: "number", name: "serial_number", label: "", width: "auto" },
    { type: "string", name: "name", label: "FEATURE", width: "40%" },
    { type: "string", name: "price", label: "TYPE", width: "20%" },
    { type: "string", name: "price", label: "MONTHLY PRICE", width: "20%" },
    { type: "string", name: "price", label: "ANNUAL PRICE ", width: "20%" },
    { type: "status", name: "status", label: "STATUS", width: "20%" },
    { type: "action", name: "actionIcons", label: "ACTION", width: "20%" },
  ];

  const { title, featureType, status, description, price } = features;

  let rows = [];
  if (selectedFeatures && selectedFeatures?.length) {
    rows = selectedFeatures.map((app, index) => {
      return (
        [
          { type: "", id: index, name: '', value: " " },
          { type: "string", name: "category", value: app.title, id: index },
          { type: "string", name: "category", value: "Free" },
          { type: "string", name: "category", value: `$${app.price}` },
          { type: "string", name: "category", value: `$${app.price}` },
          { type: "status", status: checkStatus(app.status), value: app.status },
        ]
      )
    });
  }

  const sortData = (data) => {
    const selected_features = data.sort((a, b) => a.order - b.order);
    // console.log("sorted", selected_features)
    setselectedFeatures([...selected_features])
  }


  setstepDisabled(selectedFeatures?.length ? false : true)
  let priceCheck = featureType === "Paid" && parseInt(price) > 0 ? true : featureType === "Free" ? true : false;
  console.log("priceCheck", priceCheck, featureType, price)

  return (<Fragment>
    <DrawerMain
      open={open}
      openHandler={closeDrawer}
      title="Add Feature"
      onCancel={onCancel}
      onSave={onSave}
      loading={false}
      saveDisabled={title && featureType && priceCheck && status ? false : true}
    >


    <Grid container spacing={3}>
    <Grid item xs={12}>
      <div className="field_box">
        <BaseTextField
          value={title}
          onChange={handleChange("title")}
          label='Feature Title'
          placeholder="Feature Title"
          InputProps={false}
          phone={false}
          search={false}
          style={styles.input}
          color="green"
          count={50}
        />
      </div>
      </Grid>

      <Grid item xs={12}>
      <div className="field_box">
        <BaseSelect
          value={featureType}
          onChange={handleChange("featureType")}
          object={[
            {
              value: "Free",
              label: "Free",
            },
            {
              value: "Paid",
              label: "Paid",
            },
          ]}
          style={{ width: "100%", marginLeft: 0 }}
          helperText=""
          label='Pricing'
          placeholder="Pricing"
        // style={styles.input}
        />
      </div>
      </Grid>

      {featureType === "Paid" && <Fragment>

        

          <Grid item xs={12}>
            <div className="field_box">
              <TextField
                value={price}
                onChange={handleChange("price")}
                label='Monthly Price'
                placeholder="Monthly Price"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </div>
          </Grid>
         
          <Grid item xs={12}>
            <div className="field_box">
              <TextField
                value=""
                onChange={handleChange("price")}
                label='Monthly Price ID'
                placeholder="Monthly Price ID"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="field_box">
              <TextField
                value={price}
                onChange={handleChange("price")}
                label='Annual Price'
                placeholder="Annual Price"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="field_box">
              <TextField
                value=""
                onChange={handleChange("price")}
                label='Annual Price ID'
                placeholder="Annual Price ID"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                type="number"
                
              />
            </div>
          </Grid>

          

       

      </Fragment>}
      
      <Grid item xs={12}>
      <div className="field_box">
        <BaseTextField
          value={description}
          onChange={handleChange("description")}
          label='Description'
          placeholder="Description"
          InputProps={false}
          phone={false}
          search={false}
          color="green"
          style={styles.input}
          multiline={true}
          rowsMax={4}
          count={175}
        />
      </div>

      </Grid>

      <Grid item xs={12}>         
      <div className="field_box">
        <BaseSelect
          value={status}
          onChange={handleChange("status")}
          object={[
            {
              value: "Available",
              label: "Available",
            },
            {
              value: "Not Available",
              label: "Not Available",
            },
            {
              value: "Coming Soon",
              label: "Coming Soon",
            }
          ]}
          helperText=""
          label='Status'
          placeholder="Status"
          style={styles.input}
        />
      </div>
      </Grid>

      </Grid>

    </DrawerMain>
    <SimpleTable
      title="Features"
      showHeader={true}
      rows={rows}
      data={selectedFeatures}
      columns={columns1(columns)}
      colSpan={6}
      actionSendButton={true}
      actionLink={(data) => actionLinkHandler(data)}
      classProps={changeClassProps()}
      onClick={(message, index) => click(message)}
      showAdd={true}
      addText="ADD NEW"
      donationsCount={rows?.length ? 1 : 0}
      onAddClick={() => setOpen(true)}
      noTitle="No Features"
      noPara="Add new features to the application."
      noBtnText="Add Feature"
      onInviteClick={() => setOpen(true)}
      iconTextSize={14}
      //sortColumnIndex={[0]}
      editIcon={CreateIcon}
      deleteIcon={DeleteIcon}
      showIconCol={true}
      editAction={(data) => editAction(data)}
      deleteAction={(data) => deleteAction(data)}
      showFooter={rows?.length > 5 ? true : false}
      rowsPerPage={5}
      //sortData={(e) => sortData(e)}
      parentTableDrag={true}
      DragIcon={Handle}
    />
  </Fragment>
  )
})

export default Features;
