import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, AvannceInfoChange, DrawerListRadioButton, BaseImgChip, AlertAction, MasterLayoutMiddle, FooterInner, FormSwitch } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import './teamMember.css';
import Tooltip from '@material-ui/core/Tooltip';


function EditFinanceTeamMember(props) {
    console.log("member", props.getFinanceTeamMember)
    const { getFinanceTeamMember } = props;
    const [open, setOpen] = React.useState(false);
    const [member, setmember] = useState({ password: "", access: false, fullName: "", email: "", role: "" })
    const [errorFlag, setErrorFlag] = useState(true)
    const [memberError, setmemberError] = useState({ passwordError: false, fullNameError: false, imageError: false, emailError: false })
    const [chipData, setchipData] = useState([])
    const [selectedData, setselectedData] = useState("")
    const [opentooltip, setopentooltip] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false);

    const roleData = [
        {
            id: 1,
            primary: "Admin",
            secondary: "The Administrator has the highest access of the console after Owner.",
        },
        {
            id: 2,
            primary: "Manager",
            secondary: "Best for employees who regularly create/manage/publish contents.",
        },
    ];

    useEffect(() => {
        const role = localStorage.getItem("role");
        if(role && role.length > 0 && role === 'hikmah_owner') {
            setShowResetPassword(true);
        }
        props.onGetMember(props.match.params.id)
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        let selectedRole = getFinanceTeamMember?.role?.[0]?.roles === "hikmah_owner" ? "Owner" : getFinanceTeamMember?.role?.[0]?.roles === "hikmah_admin" ? "Admin" : getFinanceTeamMember?.role?.[0]?.roles === "hikmah_manager" ? "Manager" : "";
        setmember({
            access: getFinanceTeamMember?.access?.toLowerCase() === "active" ? true : false,
            fullName: getFinanceTeamMember?.name ? getFinanceTeamMember.name.firstName + " " + getFinanceTeamMember.name.lastName : "",
            email: getFinanceTeamMember.userName,
            role: selectedRole
        })
        setchipData([{ key: 0, label: selectedRole, src: "https://www.w3schools.com/howto/img_avatar.png", alt: "avatar" }])
        setselectedData({
            id: 3,
            primary: selectedRole,
            secondary: selectedRole === "Admin" ? "The Administrator has the highest access of the console after Owner." : "Best for employees who regularly create/manage/publish contents.",
        })
    }, [getFinanceTeamMember])

    let handleChange = (prop) => (event) => {
        setmember({ ...member, [prop]: event.target.value });
    }

    const handleSwitchChange = (event) => {
        const { getFinanceTeamMember } = props;
        let ownerMember = getFinanceTeamMember?.role?.find((role) => role?.roles?.toLowerCase() === "hikmah_owner");
        console.log("ownerMember", ownerMember)
        if (!ownerMember) {
            setmember({ ...member, access: event });
        } else {
            setopentooltip(true);
            setTimeout(() => {
                setopentooltip(false);
            }, 5000);
        }
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    let onSubmit = () => {
        const { access } = member;
        let formData = {
            role: [{ application: 'deenconnect', roles: role === "Admin" ? "hikmah_admin" : "hikmah_manager" }],
            access: access ? "active" : "revoked",
        };

        console.log("formData", formData)
        if (role && formData.access) {
            props.onEditMember(props.match.params.id, formData)
        }
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const style = {
        width:'100%',
        background: "#fff",
      };

    const closeDrawer = () => {
        setOpen(!open);
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onSave = () => {
        if (member.role) {
            setchipData([{ key: 0, label: member.role, src: "https://www.w3schools.com/howto/img_avatar.png", alt: "avatar" }])
            // setmember({ ...member, role: member.role})
            setOpen(!open);
        }
    };

    const onInputClick = () => {
        console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
        setOpen(!open);
    };

    const deleteChip = () => {
        setmember({ ...member, role: "" })
        setchipData([])
        setselectedData("")
    }

    const getData = (value, secondary) => {
        setmember({ ...member, role: value })
    };

    const resetPassword = () => {
        const {email} = member;
        if(email.length > 0) {
            props.onSendResetCode({
                userName: email
            });
        }
    }

    const closeAlert = () => {
        setErrorFlag(false);
    }

    const { fullName, access, role, email } = member;
    const { fullNameError, emailError } = memberError;
    let disableBtn = fullName && role && validateEmail(email) ? false : true;


    const onclick = () => {
        console.log("");
    }

    // console.log("props", props.match.params.id)
    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Assign Role"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                // img={Logo}
                saveDisabled={!member.role}
            >

                <DrawerListRadioButton objects={roleData} getData={getData} selectedData={selectedData} />
            </DrawerMain>

            <MasterLayoutMiddle
                header={
                    <div>
                        <div className="backBtnFrom">
                            <BaseButton
                                onClick={() => props.history.push("/team-members")}
                                text="back"
                                textStyle={styles.txt}
                                disableElevation={false}
                                disableFocusRipple={false}
                                disableRipple={false}
                                style={styles.backBtn}
                                startIcon={<ChevronLeftIcon />}
                            />
                        </div>
                        {/* </Link> */}
                        {props.page && props.page.loading && <Spinner />}

                        <div className="header_alert">
                        {
                            props.errors && props.errors.error && errorFlag && <AlertAction
                                alert={props.errors.message}
                                alertType={"error"}
                                onClick={closeAlert}
                            />
                        }
                        </div>



                    </div>
                }
                isForm={true}
                formData={
                    <div>
                        
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <h1 className="title_top">Edit Team Member</h1>
                            </Grid>


                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseTextField
                                        value={fullName}
                                        onChange={handleChange("fullName")}
                                        label='Full Name'
                                        disabled={true}
                                        placeholder="Full Name"
                                        InputProps={false}
                                        phone={false}
                                        search={false}
                                        style={styles.input}
                                        onBlur={() => fullName ? setmemberError({ ...memberError, fullNameError: false }) : setmemberError({ ...memberError, fullNameError: true })}
                                        error={!fullName && fullNameError ? true : false}
                                        helperText={!fullName && fullNameError ? "Required" : "Helper Text"}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseTextField
                                        value={email}
                                        onChange={handleChange("email")}
                                        label='Email'
                                        placeholder="Email"
                                        disabled={true}
                                        InputProps={false}
                                        phone={false}
                                        search={false}
                                        style={styles.input}
                                        color="green"
                                        type="string"
                                        onBlur={() => email && validateEmail(email) ? setmemberError({ ...memberError, emailError: false }) : setmemberError({ ...memberError, emailError: true })}
                                        error={!email && emailError ? "Required" : email && !validateEmail(email) && emailError ? true : false}
                                        helperText={!email && emailError ? "Enter valid email address" : email && !validateEmail(email) && emailError ? "Enter valid email address" : "Helper Text"}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box" style={{ marginTop: '20px' }} onClick={onChipInputClick}>
                                    <BaseImgChip
                                        chipData={chipData}
                                        onInputClick={onInputClick}
                                        onChipInputClick={onChipInputClick}
                                        deleteChip={deleteChip}
                                        //helperText='Helper Text'
                                        label="Assign Role"
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="border_top_bottm form-switch-tooltip">
                                    <Tooltip title="You cannot change owner access" arrow placement="top"
                                        PopperProps={{
                                            disablePortal: true,
                                        }}
                                        // onClose={() => setopentooltip(false)}
                                        open={opentooltip}
                                        disableFocusListener
                                        disableHoverListener
                                        disableTouchListener
                                    >
                                        <span><FormSwitch
                                            headline='Hikmah Access '
                                            body="Allow member to login to DEENCONNECT Hikmah or revoke access"
                                            values={access}
                                            handleSwitchChange={(e) => handleSwitchChange(e)}
                                        /></span>
                                    </Tooltip>
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="form_button">
                                    <BaseButton
                                        onClick={() => props.history.push("team-members")}
                                        text="cancel"
                                        textStyle={styles.txt}
                                        disableElevation={false}
                                        disableFocusRipple={false}
                                        disableRipple={false}
                                        variant='text'
                                    />

                                    <BaseButton
                                        onClick={onSubmit}
                                        text={'UPDATE'}
                                        disabled={disableBtn}
                                        disableElevation={false}
                                        disableFocusRipple={false}
                                        disableRipple={false}
                                        style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                                    />
                                </div>
                            </Grid>
                            {showResetPassword &&
                                <Grid item xs={12}>
                                    <AvannceInfoChange
                                        showMainTitle={true}
                                        mainTitle="Advance"
                                        Title="Reset password"
                                        description="Resetting password is easy click RESET NOW and follow the steps."
                                        changeNow={resetPassword}
                                    />

                                </Grid>
                            }

                        </Grid>
                    </div>
                }

                isFooter={true}
                footer={
                    <FooterInner style={style} />
                }
                isWhiteBackground={true}
            />
        </Fragment>
    )

}

const mapStateToProps = state => {
    return {
        getFinanceTeamMember: state.teamMembers.financeTeamMember,
        page: state.page,
        errors: state.errors
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onGetMember: (id, ownProps) => dispatch(actions.getFinanceTeamMember(id, ownProps)),
        onEditMember: (id, member) => dispatch(actions.editFinanceTeamMember(id, member, ownProps)),
        onSendResetCode: (userName)=> dispatch(actions.sendResetCode(userName, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditFinanceTeamMember);
