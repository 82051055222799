import axios from 'axios';

import {
    SET_ERRORS,
    SET_ALL_CONTACTS,
    SET_CONTACT,
    SET_ALL_REQUESTS,
    SET_REQUEST, 
    SET_QUOTEREQUEST
} from './actionTypes';

import {
    setPageLoading,
    clearPageLoading,
    clearErrors,
    showMessage
} from './pageActions';

const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export const getAllContacts = () => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/dc-contact-us`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_ALL_CONTACTS, payload:data})
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const getAllQuotations = () => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/quotations/getAllQuotations`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_ALL_CONTACTS, payload:data})
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const getContact = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/dc-contact-us/${id}`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_CONTACT, payload: data })
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const getQuotation = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/quotations/getSingleQuotation/${id}`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_QUOTEREQUEST, payload: data })
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const deleteContact = (id, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .delete( backendServerURL+`/dc-contact-us/${id}`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors()); 
        ownProps.history.push({pathname:"/communication/contact-us", state: {contactDeleted: true}});       
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
}

export const editContact = (id, data, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .put( backendServerURL+`/dc-contact-us/${id}`, {...data}, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        ownProps.history.push({pathname: "/communication/contact-us-details", state:  {contact: data, assigneeUpdated: true}});
        dispatch(clearErrors());        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const getAllRequests = () => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/dc-website/demoRequest`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_ALL_REQUESTS, payload:data })
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const getRequest = (id) => dispatch => {
    dispatch(setPageLoading());

    axios
    .get( backendServerURL+`/dc-website/demoRequest/${id}`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());
        dispatch({type: SET_REQUEST, payload: data })
        
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const editRequest = (id, data, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .put( backendServerURL+`/dc-website/demoRequest/${id}`, {...data}, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors());   
        dispatch(showMessage("Saved successfully!"));
        ownProps.history.goBack();
            
    })
    .catch(err => {
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
};

export const deleteRequest = (id, ownProps) => dispatch => {
    dispatch(setPageLoading());

    axios
    .delete( backendServerURL+`/dc-website/demoRequest/${id}`, {
        headers: { 
            'token': localStorage.authToken
        }
    } )
    .then(res => {
        console.log("Delete Success: ". res);
        const data = res && res.data && res.data.body && res.data.body.data && res.data.body.data;
        dispatch(clearErrors()); 
        ownProps.history.push({pathname:"/communication", state: {contactDeleted: true}}); 
        dispatch(showMessage("Demo request deleted successfully!"));

    })
    .catch(err => {
        console.log("Error Occured: ", err)
        console.log("error: ", err && err.response && err.response.data && err.response.data);
        dispatch({type: SET_ERRORS, payload: err && err.response && err.response.data ? err.response.data : { message: "Something is wrong please try again! "} })
    })      
    .finally(() => dispatch(clearPageLoading()))
}