import React, { useEffect, useState } from "react";
import { CheckOrderSummary } from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";

import { CheckoutPayment } from "master-react-lib";
import MasterCardImg from "./img/masterCardIcon.png";
import VisaCardImg from "./img/visa-icon.png";
import NewCardImg from "./img/new-card.png";
import DiscoverCardImg from "./img/discovercard.png";
import AmericanExpressImg from "./img/american-express.png";
import DinersCardImg from "./img/dinerscard.png";
import JcbCardImg from "./img/jcb-card.png";
import UnionPayImg from "./img/unionpay.png";
import HelpImg from "./img/help-24px.svg";

import paycheck from "./img/paycheck.svg";
import creditCard from "./img/credit_card.svg";

import "./onboarding.css";

function Payment(props) {
  useEffect(() => {
    console.log("props data is ", props.planData);
  }, []);
  const [cardDetails, setcardDetails] = React.useState({
    // cardNumber: "•••• •••• 	•••• 4242",
    cardNumber: "",
    expiration: "",
    code: "",
  });

  const [addressDetails, setaddressDetails] = React.useState({
    firstName: "",
    lastName: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    postalCode: "",
    country: "",
  });

  const [saveCardDetails, setsaveCardDetails] = React.useState(false);

  let selectedCardDetails = {
    firstName: "Sheikh",
    lastName: "Rehman",
    line1: "Street Address",
    line2: "",
    city: "City",
    state: "State",
    postalCode: "Zip Code",
    country: "United States",
  };
  React.useEffect(() => {
    console.log(addressDetails, cardDetails);
  }, [addressDetails, cardDetails]);
  const handlePaymentSelectChange = (selectedPaymentCard) => {
    console.log("selectedPaymentCard: ", selectedPaymentCard);
  };

  const onPlaceOrderSubmit = (cardDetails) => {
    console.log(cardDetails);
  };

  // const onPlaceOrderSubmit = (cardDetails) => {
  //     console.log(cardDetails)
  // };

  const onEditBillingDetailClick = () => {
    console.log("onEditBillingDetailClick");
  };

  const creditCardsList = [
    { cardName: "Visa-4242", cardNumber: "4242", cardImage: VisaCardImg },
    {
      cardName: "Master Card-9213",
      cardNumber: "9213",
      cardImage: MasterCardImg,
    },
  ];

  const onclickremove = () => {
    console.log("button clicked");
  };

  const onSubmit = () => {
    console.log("button clicked");
  };

  // defaultCheckBox = (flag) => {
  //     console.log("Flag is: ", flag)
  //     setsaveCardDetails(flag)
  // }

  const [discountCode, setDiscountCode] = React.useState("");
  const [discount, setDiscount] = React.useState(0);

  React.useEffect(() => {
    if (discount < 100 && discountCode !== "") {
      setDiscount(discount + 1);
    } //Set Your Discount Logic Here.
    if (discountCode === "") {
      setDiscount(0);
    }
  }, [discountCode]);

  const callback = (code, total) => {
    setDiscountCode(code);
  };

  const dataset = [
    {
      title: "Sub Total",
      value: "dynamicSubtotal",
    },
    {
      title: (
        <p style={{ margin: 0 }}>
          {" "}
          Estimated tax for: <span style={{ color: "#4b91fa" }}></span>{" "}
        </p>
      ),
      value: "$0",
      info: true,
      help_text: "Lorem Ipsum",
    },
    {
      title: "Discounts",
      value: "$0",
    },
  ];

  const onContinueBtnClick = () => {
    console.log("onContinueBtnClick");
  };

  const onEditCartClick = () => {
    console.log("onEditCartClick");
  };

  const applyCode = () => {
    console.log("Apply is clicked");
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={8} xs={12}>
          <CheckoutPayment
            paywithdropdown={false}
            //editMode = {true}
            //cardImage = {MasterCardImg}
            creditCardsList={creditCardsList}
            handlePaymentSelectChange={handlePaymentSelectChange}
            onPlaceOrderSubmit={onPlaceOrderSubmit}
            cardDetails={cardDetails}
            setcardDetails={setcardDetails}
            addressDetails={addressDetails}
            setaddressDetails={setaddressDetails}
            saveCardDetails={saveCardDetails}
            //setsaveCardDetails={defaultCheckBox}
            selectedCardDetails={selectedCardDetails}
            privacyPolicyLink={"/privacy-policy"}
            termsOfUseLink={"/terms-of-use"}
            onEditBillingDetailClick={onEditBillingDetailClick}
            visaCardImage={VisaCardImg}
            masterCardImage={MasterCardImg}
            newCardImage={NewCardImg}
            discoverCardImage={DiscoverCardImg}
            americanExpressImage={AmericanExpressImg}
            dinersCardImage={DinersCardImg}
            jcbCardImg={JcbCardImg}
            unionPayImg={UnionPayImg}
            helpImage={HelpImg}
            // ButtonLabel="Place Order"
            creditCard={creditCard}
            paycheck={paycheck}
            cardIconStatus={true}
            //RemoveButtonLabel="REMOVE CARD"
            ScuredLabel="Secured"
            checkboxLabel="Save my card details for future transaction"
            Tooltipcheckboxtext="Unsure about something? You can always update your campaign later."
            BottomHelperText="We don't save your credit card details, your credit card <a href = 'http://www.google.com'>information</a> is safe with our trusted partner Stripe"
            TooltipQuestionIcontext="CVV is the last 3 digits on the back. In American Express, it is a 4 digit code in the front of the credit card"
            onclickremove={onclickremove}
            //onSubmit={onSubmit}

            // buttoncolor="#0053A5"
            // buttonwidth="100%"
            // buttonheight="50px"
            // buttonborder="#ccc 0px solid"
            // buttonRadiusr="100px"
            // buttonTextcolor="#fff"
            // buttonfontWeight="600"
            chequePayableTo="AFNAN INNOVATION LAB, INC"
            mailToName="Abdullah Al Razi"
            mailToStreet="280 Glenmore Ave"
            mailToCity="Brooklyn"
            mailToState="NY"
            mailToPostalCode="11208"
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <CheckOrderSummary
            dataset={dataset}
            callback={callback}
            discountCode=""
            discount={5}
            summary="Order Summary"
            dropDownSummary="Discounts"
            dropDownDetails="Enter Code Here"
            bottomText="Total"
            buttomSubText="$0"
            text="Continue to payment"
            apply="APPLY"
            codeApplied={false}
            //error={'the promotion code you entered is invalid'}
            errorTop={"Invalid code"}
            total={"dynamic GrandTotal"}
            onClick={onContinueBtnClick}
            cartItemCount={5}
            editCartSection={true}
            onEditCartClick={onEditCartClick}
            showCheckoutButton={false}
            applyCode={applyCode}
            CodeField={true}
            BottomBorder={true}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default Payment;
