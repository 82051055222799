import React, {useEffect} from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {BaseTabs, SimpleTable, FormSwitch, MasterLayoutMiddle, CardDetailsTable, Initials, CardStatus,
  FooterInner,BaseButton
} from "@dclab/dc-react-ui-kit";
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Crown } from '../../assets/img/crown.svg'
import './style.css'

const useStyles = makeStyles((theme) => ({}));

function AdminProfile(props) {
  const {userDetails} = props;
  // ============================== Base Tab Start ===============================//
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.onGetCurrentUser();
  }, [])

  const object = [
    {
      label: "Profile",
      item: "",
    },
    {
      label: "preferences",
      item: "",
    },

  ];

  const onClick = (x, y) => {
    console.log(x);
    console.log(y);
  };

  // ============================== Base Tab End ===============================//


  // ============================== Details Table Start ===============================//

  const firstName = userDetails && userDetails.name && userDetails.name.firstName ? userDetails.name.firstName : "";
  const lastName = userDetails && userDetails.name && userDetails.name.lastName ? userDetails.name.lastName : "";
  const userImage = userDetails && userDetails.imageDetails && userDetails.imageDetails.image ? userDetails.imageDetails.image : "";

  let header = {
    avatar: userImage ? <img src={userImage} alt="avatar" style={{width: '40px', height: '40px', borderRadius: "50%"}}/> : <Initials name={firstName || lastName ? firstName + " " + lastName : ""} />,
    tableName: firstName || lastName ? firstName + " " + lastName : "",
    icon: userDetails && userDetails.role && userDetails.role.length && userDetails.role[0].roles === "hikmah_owner" ? <Crown /> : "",
    status: []
  };

  let data = [
    {
      field: "Phone",
      value: userDetails && userDetails.phoneNo ? userDetails.phoneNo : ""
    },
    {
      field: "Address",
      value: userDetails && userDetails.address ? userDetails.address.line1 + " " + userDetails.address.city + " " + userDetails.address.state + " " + userDetails.address.postalCode : ""
    },
    {
      field: "",
      value: "<span></span>"
    },
    {
      field: "Email",
      value:  userDetails && userDetails.userName ? userDetails.userName : ""
    },
    {
      field: "",
      value: "<span></span>"
    },    {
      field: "",
      value: "<span></span>"
    },
    
    {
      field: "Password",
      value: "12345678"
    },
  ];

  let footerLinkName = "EDIT PROFILE";


  // ============================== Details Table End =================================//


  // ============================== Table Start ======================================//


  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  let col = [
    { type: "number", name: "serial_number", label: "NO.", width: "auto" },
    { type: "link", name: "invoice_number", label: "Org name", width: "30%" },
    { type: "string", name: "name", label: "Role", width: "15%" },
    { type: "string", name: "name", label: "ORG id", width: "44%" },
    { type: "action", name: "action", label: "ACTION", width: "20%" },
  ];

  let rows = [
    [
      { type: "id", value: "01" },
      {
        name: "textImg",
        type: "string",
        primaryText: "Islamic Institute of Brooklyn",
        // secondaryText: "Brooklyn, NY",
        initials: "HK",
        // src:
        //   "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
      },
      { type: "string", name: "frequency", value: "Admin", },
      { type: "string", name: "date/time", value: "acct_1Ah5arCVVsjUQDTv" },
      { type: "action", status: "success", value: "see details" },
    ],

    [
      { type: "id", value: "02" },
      {
        name: "textImg",
        type: "string",
        primaryText: "Islamic Institute of Brooklyn",
        // secondaryText: "Brooklyn, NY",
        initials: "HK",
        // src:
        //   "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
      },
      { type: "string", name: "frequency", value: "Admin", },
      { type: "string", name: "date/time", value: "acct_1Ah5arCVVsjUQDTv" },
      { type: "action", status: "success", value: "see details" },
    ],

    [
      { type: "id", value: "03" },
      {
        name: "textImg",
        type: "string",
        primaryText: "Islamic Institute of Brooklyn",
        // secondaryText: "Brooklyn, NY",
        initials: "HK",
        // src:
        //   "https://media.gettyimages.com/photos/jama-masjid-mosque-in-delhi-picture-id505757382?s=612x612",
      },
      { type: "string", name: "frequency", value: "Admin", },
      { type: "string", name: "date/time", value: "acct_1Ah5arCVVsjUQDTv" },
      { type: "action", status: "success", value: "see details" },
    ],




  ];

  // ============================== Table End ======================================//

  // ============================== Style Start ===============================//
  const styles = {
    first: {
      marginBottom: 31,
    },
    second: {
      marginBottom: 111,
    },
    third: {
      marginBottom: 1,
    },
  };

  // ============================== Style END ===============================//

  const classes = useStyles();

  const style = {
    width:'100%',
    background: "#fff",
  };

  return (
    <MasterLayoutMiddle
      content={
        <div>
          <BaseTabs
            title=""
            object={object}
            indicatorColor="primary"
            onClick={(x, y) => onClick(x, y)}
            onChange={(event, newValue) => handleChange(event, newValue)}
            value={value}
            indicatorColor="#7266FB"
          />

          {value === 0 && (
            <Grid container spacing={3} >

            <Grid item xs={12} >
              <div className="table_box">
                <CardDetailsTable data={data} header={header} footerLinkName={footerLinkName} onClick={() => props.history.push("/edit-profile")} />
              </div>
              </Grid>

              <Grid item xs={12} >
              <div className="table_box">
                <SimpleTable
                  showHeader={true}
                  title="Accounts"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={1}
                  noTitle="No Service found"
                  noPara="Add new AnnouncementsAnnouncements."
                  noBtnText="Add Announcements"
                  onInviteClick={() =>
                    this.props.history.push(
                      "/organization/services/add-new-service"
                    )
                  }
                  actionLink={(name) => this.details(name)}
                  showIconCol={false}
                  actionSendButton={true}
                  //editIcon={Edit}
                  //deleteIcon={Delete}
                  //editAction = {(e) => this.editService(e)}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={true}
                  showAdd={false}
                  addText="ADD NEW"
                  onAddClick={() =>
                    this.props.history.push(
                      "/Website/Add-Announcements"
                    )
                  }
                  iconTextSize={16}
                />
              </div>
              </Grid>

            </Grid>
          )}

          {value === 1 && (
            <>


              <div className="page_body">

                <Typography className="page_form_title">Email Preferences</Typography>

                <Grid className="form_body">

                  <Grid container item xs={6} spacing={1} >

                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Login from a new device" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Payments reviews" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Successful payments" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Login from a new device" body="Get an email notification everytime you login from a new device" />
                    </Grid>
                    <Grid item xs={12}><div className="border_line"></div></Grid>
                    <Grid item xs={12}>
                      <FormSwitch style={styles.BaseTextField} headline="Payments reviews" body="Get an email notification everytime you login from a new device" />
                    </Grid>

                  </Grid>

                </Grid>

                <div className="page_footer footer_btn">
                    <span className="btnCencel">
                      <BaseButton text="CANCEL" />
                    </span>
                    <BaseButton text="Save changes"/>
                </div>

              </div>

            </>
          )}


        </div>
      }

      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={true}
    />
  );
}

const mapStateToProps = state => {
  return {
     userDetails: state.auth.getCurrentUserDetails,
     auth: state.auth,
     page: state.page,
     errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
      onGetCurrentUser: () => dispatch(actions.getCurrentUserDetail()),
      onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminProfile);