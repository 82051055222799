import React, { useEffect } from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import {
  BaseTabs, SimpleTable, FormSwitch, MasterLayoutMiddle, Initials, CardStatus,
  FooterInner, BaseButton, CardData, EmptyTable
} from "@dclab/dc-react-ui-kit";
import Spinner from '../../components/common/Spinner';
import Typography from "@material-ui/core/Typography";
import { ReactComponent as Crown } from '../../assets/img/crown.svg'
import Moment from 'moment'
import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";


import './style.css'



const useStyles = makeStyles((theme) => ({}));

function Campaign(props) {
  const { allCampaign } = props;
  // ============================== Base Tab Start ===============================//
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    props.onGetAllCampaign();
  }, [])

  const object = [
    {
      label: "Global",
      item: "",
    },
    {
      label: "per Org",
      item: "",
    },

  ];

  const onClick = (x, y) => {
    console.log(x);
    console.log(y);
  };

  // ============================== Base Tab End ===============================//



  // ============================== Table Start ======================================//


  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const statusIs = (expires_at, activeBool) => {

    if (expires_at && new Date(expires_at * 1000) < new Date()) {
      return {
        color: 'discontinued',
        status: 'Expired'
      }
    } else if (activeBool) {
      return {
        color: 'success',
        status: 'Active'
      }
    } else {
      return {
        color: 'failed',
        status: 'Inactive'
      }
    }
  }

  let type = 'success';

  let col = [

    { type: "string", name: "name", label: "Code", width: "15%" },
    // { type: "string", name: "name", label: "ORG/GLOBAL", width: "17%" },
    { type: "string", name: "name", label: "Terms", width: "15%" },
    { type: "string", name: "name", label: "Redemptions", width: "15%" },
    { type: "string", name: "name", label: "Expires", width: "25%" },
    { type: "status", name: "status", label: "STATUS", width: "10%" },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
  ];

  let rows = allCampaign.map((campaign, index) => {
    console.log("Each campaign: ", campaign);
    const statusObj = campaign.times_redeemed === campaign.max_redemptions ?
      {
        color: 'pause',
        status: 'Maxed Out'
      } : statusIs(campaign.expires_at, campaign.active);
    const tempUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? `https://dashboard.stripe.com/promotion_codes/${campaign.id}` : `https://dashboard.stripe.com/test/promotion_codes/${campaign.id}`;

    return ([
      {
        type: 'link',
        name: 'invoice_number',
        value: campaign.code,
        url: tempUrl,
        linkType: "external",
        stripe: "Stripe",
        display: "inline-block",
      },
      // { type: "string", name: "frequency", value: "Global", },
      { type: "string", name: "frequency", value: `${campaign?.coupon.percent_off ? campaign.coupon.percent_off + '%' : campaign?.coupon?.amount_off ? (campaign.coupon.amount_off / 100).toFixed(2) + ' ' + campaign.coupon?.currency?.toUpperCase() : ''} off ${campaign.coupon.duration_in_months ? "for " + campaign.coupon.duration_in_months + ' months' : campaign.coupon.duration}` },
      { type: "string", name: "frequency", value: `${campaign.times_redeemed}/${campaign.max_redemptions ? campaign.max_redemptions : 'unlimited'}`, },
      { type: "string", name: "frequency", value: campaign.expires_at ? Moment(new Date(campaign.expires_at * 1000)).format("MMM DD, YYYY, hh:mm A") : 'Never' },
      { type: "status", status: statusObj.color, value: statusObj.status },
      { type: "action", status: "details", value: "See details" },
    ])
  });


  // ============================== Table End ======================================//

  // ============================== Style Start ===============================//
  const styles = {
    first: {
      marginBottom: 31,
    },
    second: {
      marginBottom: 111,
    },
    third: {
      marginBottom: 1,
    },
  };

  // ============================== Style END ===============================//

  const classes = useStyles();

  const style = {
    width: '100%',
    background: "#fff",
  };

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log('invite');
  }

  const onSeeDetailedClick = (data) => {

    const tempUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? `https://dashboard.stripe.com/promotion_codes/${data[0].couponId}` : `https://dashboard.stripe.com/test/promotion_codes/${data[0].couponId}`;

    window.open(tempUrl)
  }
  // const actionLinkHandler = (data) => {
  //   // console.log("checking Link data: ", data);
  //   let id = data[0].id;
  //   // console.log("id is: ", id);
  //   props.history.push(`/campaign-details-${id}`)
  // }

  return (
    <MasterLayoutMiddle
      content={
        <div>
          <Grid container spacing={3}>

            <Grid item xs={12} >
              <div className="table_box">
                {props.page && props.page.loading && <Spinner />}
                {!props?.page?.loading ? props.allCampaign && props.allCampaign.length > 0 ?
                  <SimpleTable
                    stripe={true}
                    showHeader={true}
                    title="Campaign"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={1}
                    noTitle="No Service found"
                    noPara=""
                    noBtnText=""
                    onInviteClick={() =>
                      this.props.history.push(
                        "/add-new-campaign"
                      )
                    }
                    actionLink={(name) => onSeeDetailedClick(name)}
                    showIconCol={false}
                    actionSendButton={true}
                    //editIcon={Edit}
                    //deleteIcon={Delete}
                    // editIcon={CreateIcon}
                    // deleteIcon={DeleteIcon}
                    // //editAction = {(e) => this.editService(e)}
                    // deleteAction={(e) => this.deleteServiceAction(e)}
                    showFooter={true}
                    showAdd={true}
                    addText="ADD NEW"
                    onAddClick={() => props.history.push('/add-new-campaign')}
                    iconTextSize={16}
                  /> : <EmptyTable
                    title="Campaign"
                    noTitle="No Campaign Created"
                    addLabel="Add New"
                    noBtnText="Add New"
                    noPara=""
                    onAddClick={() => props.history.push('/add-new-campaign')}
                    onLinkClick={(val) => console.log(val)}
                  /> : null}
              </div>
            </Grid>

          </Grid>




        </div>
      }

      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={true}
    />
  );
}

const mapStateToProps = state => {
  return {
    allCampaign: state.campaigns.allCampaign,
    auth: state.auth,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllCampaign: () => dispatch(actions.getAllCampaign()),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Campaign);



