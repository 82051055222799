import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  SimpleAccordian,
  FormSwitch,
  SimpleTable,
} from "@dclab/dc-react-ui-kit";
import { SelectFieldOne } from "master-react-lib";
import Grid from "@material-ui/core/Grid";
import "./onboarding.css";
import CheckCircleRoundedIcon from "@material-ui/icons/CheckCircleRounded";
import RemoveCircleRoundedIcon from "@material-ui/icons/RemoveCircleRounded";

import dfIcon from "../../assets/img/DEENCONNECT-deenfund-masjid-org-online-donation-icon.png";
import DkIcon from "../../assets/img/DEENCONNECT-deenkiosk-masjid-organization-kiosk-icon.png";
import DwIcon from "../../assets/img/DEENCONNECT-deenweb-masjid-organization-website-icon.png";
import DsIcon from "../../assets/img/DEENCONNECT-deenscreen-masjid-organization-signage-tv-screen-icon.png";
let backendServerURL =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

function Plans(props) {
  const [integrationsData, setIntegrationData] = useState([]);
  const [checked, setChecked] = useState(false);
  const [checkedVal, setCheckedVal] = useState();
  const [AllIntegrations, setAllIntegrations] = useState([]);
  const [AllApplications, setAllApplications] = useState([]);
  const [AllSelectData, setAllSelectData] = useState([]);
  const [deenkiosk, setDeenKiosk] = useState("");
  const [deenfund, setDeenFund] = useState("");
  const [deenweb, setDeenWeb] = useState("");
  const [dfprice, setDfPrice] = useState({ price: 0, planPeriod: "" });
  const [dwprice, setDwPrice] = useState({ price: 0, planPeriod: "" });
  const [dkprice, setDkPrice] = useState({ price: 0, planPeriod: "" });
  const [integrationsName, setIntegrationsName] = useState([]);
  useEffect(() => {
    props.callback(
      integrationsName,
      integrationsData,
      dfprice,
      dwprice,
      dkprice
    );
  }, [dfprice, dwprice, dkprice]);
  useEffect(async () => {
    const allApplications = [];
    const allIntegrations = [];
    const applications = await axios.get(
      backendServerURL + "/hikmah/getAllDcApplications",
      { headers: { token: localStorage.getItem("authToken") } }
    );
    const integrations = await axios.get(
      backendServerURL + "/hikmah/getAllDcIntegrations",
      { headers: { token: localStorage.getItem("authToken") } }
    );

    const Applications = applications?.data?.body?.data;
    const Integrations = integrations?.data?.body?.data;
    Applications.filter((element) => element.status === "Available").map(
      (element, index) => {
        const obj = {
          icon: element?.iconMetaData?.iconUrl,
          name: element?.displayName,
          plans: element?.plans.filter((elem) => elem.status === "Available"),
        };
        allApplications.push(obj);
      }
    );
    Integrations.filter((element) => element.status === "Available").map(
      (element, index) => {
        const obj = {
          name: element?.name,
          price:
            element?.pricing?.pricingType === "Free"
              ? "Free"
              : [
                  `$${element?.pricing?.monthlyBilling.price}/month`,
                  `$${element?.pricing?.annuallyBilling.price}/year`,
                ],
        };
        allIntegrations.push(obj);
      }
    );
    const array = [];
    var count = 0;
    const dK = allApplications.filter((e) => e.name === "DEENKIOSK");
    const dW = allApplications.filter((e) => e.name === "DEENWEB");
    const dF = allApplications.filter((e) => e.name === "DEENFUND");
    setDfPrice({
      price:
        dF[0]?.plans[0]?.name !== "Free"
          ? dF[0]?.plans[0]?.monthlyBilling?.price
          : 0,
      planPeriod: "month",
    });
    setDwPrice({
      price:
        dW[0]?.plans[0]?.name !== "Free"
          ? dW[0]?.plans[0]?.monthlyBilling?.price
          : 0,
      planPeriod: "month",
    });
    setDkPrice({
      price:
        dK[0]?.plans[0]?.name !== "Free"
          ? dK[0]?.plans[0]?.monthlyBilling?.price
          : 0,
      planPeriod: "month",
    });
    setDeenFund(
      `Enabled ${dF[0]?.plans[0]?.name} ${
        dF[0]?.plans[0]?.name === "Free"
          ? ""
          : `$${dF[0]?.plans[0]?.monthlyBilling?.price}/month`
      }`
    );
    setDeenKiosk(
      `Enabled ${dK[0]?.plans[0]?.name} ${
        dK[0]?.plans[0]?.name === "Free"
          ? ""
          : `$${dK[0]?.plans[0]?.monthlyBilling?.price}/month`
      }`
    );
    setDeenWeb(
      `Enabled ${dW[0]?.plans[0]?.name} ${
        dW[0]?.plans[0]?.name === "Free"
          ? ""
          : `$${dW[0]?.plans[0]?.monthlyBilling?.price}/month`
      }`
    );
    setAllApplications(allApplications);
    setAllIntegrations(allIntegrations);
    for (let i = 0; i < allApplications.length; i++) {
      for (let j = 0; j < allApplications[i].plans.length; j++) {
        if (allApplications[i].plans[j].name === "Free")
          array.push({ value: ++count, select: "Free", number: i });
        if (allApplications[i].plans[j].annuallyBilling.price !== 0)
          array.push({
            value: ++count,
            select: `${allApplications[i].plans[j].name} ($${allApplications[i].plans[j].monthlyBilling.price}/month)`,
            number: i,
          });
        if (allApplications[i].plans[j].monthlyBilling.price !== 0)
          array.push({
            value: ++count,
            select: `${allApplications[i].plans[j].name} ($${allApplications[i].plans[j].annuallyBilling.price}/year)`,
            number: i,
          });
      }
    }
    setAllSelectData(array);
  }, []);
  //`${e.name} ($${e?.monthlyBilling?.price}/month)`
  const [selectedZone, setselectedZone] = React.useState(0);

  const saveChanges = (accordian) => {
    console.log("data", accordian);
    setselectedZone(0);
  };
  const onAccordianClick = (panel) => {
    console.log("onAccordianClick", panel);
    if (panel === selectedZone) {
      setselectedZone(0);
    } else {
      setselectedZone(panel);
    }
  };

  const AccordionDetails = {
    top: "0px",
  };

  const [values, setValues] = React.useState(false);

  const handleSwitchChange = (event) => {
    setValues(event);
    console.log(event, "swuitch");
    // setValues();
  };

  const handleChange = (event, value) => {
    console.log("total price $", event.target.value, value);
  };

  // ============================== Table Start ======================================//
  let col = [
    { type: "link", name: "invoice_number", label: "Name", width: "50%" },
    { type: "string", name: "name", label: "", width: "20%" },
    { type: "string", name: "date", label: "PRICE/PLAN", width: "50%" },
    { type: "string", name: "name", label: "", width: "20%" },
  ];

  //   const AllIntegrations = col.sort((a, b) => a.order - b.order);
  let rows = AllIntegrations.map((elem, index) => {
    let i = index + 1;
    return [
      {
        type: "string",
        name: "checkBox",
        value: elem.name,
        onchange: () => checkboxHandler(elem),
        CheckboxId: i,
      },
      { type: "string", name: "time", value: "" },
      elem.price === "Free"
        ? { type: "string", name: "frequency", value: elem.price }
        : {
            type: "string",
            name: "dropdown",
            value: checked ? checkedVal : elem.price,
            onchange: (e) => dropdownHandler(e, elem),
          },
      { type: "string", name: "category", value: "" },
    ];
  });

  // ============================== Table End ======================================//

  function dropdownHandler(e, elem) {
    const A1 = e.target.value.split("/");
    const integrationPeriod = A1[1];
    const price = A1[0].substring(1);
    const obj = {
      intgName: elem.name,
      price,
      integrationPeriod,
    };
    integrationsData.push(obj);
    props.callback(
      integrationsName,
      integrationsData,
      dfprice,
      dwprice,
      dkprice
    );
  }
  function checkboxHandler(elem) {
    const isExist = integrationsName.find((e) => e === elem.name);
    if (isExist)
      setIntegrationsName(integrationsName.filter((e) => e !== isExist));
    else integrationsName.push(elem.name);
    props.callback(
      integrationsName,
      integrationsData,
      dfprice,
      dwprice,
      dkprice
    );
  }
  function selectHandler(event, value, elem) {
    if (elem.name === "DEENKIOSK") {
      setDeenKiosk(`Enabled ${value.props.children}`);
      const integrationArray = value.props.children.split(" ");
      const a1 = integrationArray[1].split("/");
      const a2 = a1[0].split("$");
      const price = a2[1];
      var planPeriod;
      if (a1[1].includes("month")) {
        planPeriod = "month";
      } else {
        planPeriod = "year";
      }
      setDkPrice({ price, planPeriod });
      props.callback(
        integrationsName,
        integrationsData,
        dfprice,
        dwprice,
        dkprice
      );
    }
    if (elem.name === "DEENFUND") {
      setDeenFund(`Enabled ${value.props.children}`);
      const integrationArray = value.props.children.split(" ");
      const a1 = integrationArray[1].split("/");
      const a2 = a1[0].split("$");
      const price = a2[1];
      var planPeriod;
      if (a1[1].includes("month")) {
        planPeriod = "month";
      } else {
        planPeriod = "year";
      }

      setDfPrice({ price, planPeriod });
    }
    if (elem.name === "DEENWEB") {
      setDeenWeb(`Enabled ${value.props.children}`);
      const integrationArray = value.props.children.split(" ");
      const a1 = integrationArray[1].split("/");
      const a2 = a1[0].split("$");
      const price = a2[1];
      var planPeriod;
      if (a1[1].includes("month")) {
        planPeriod = "month";
      } else {
        planPeriod = "year";
      }
      setDwPrice({ price, planPeriod });
    }
  }
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          {AllApplications &&
            AllApplications.map((elem, index) => {
              return (
                <SimpleAccordian
                  Title={
                    <div className="org_logo">
                      <img src={elem.icon} />
                      {elem.name}
                    </div>
                  }
                  text={
                    <div className="enablePlan">
                      <CheckCircleRoundedIcon />
                      {elem.name === "DEENWEB"
                        ? deenweb
                        : elem.name === "DEENKIOSK"
                        ? deenkiosk
                        : elem.name === "DEENFUND"
                        ? deenfund
                        : ""}
                    </div>
                  }
                  TitleButtonLabel="Edit"
                  AccordionDetails={AccordionDetails}
                  panel={index + 1}
                  id={index + 1}
                  areacontrol={index + 1}
                  open={selectedZone === index + 1 ? true : false}
                  onAccordianClick={onAccordianClick}
                  accordianName="headerCard"
                  saveChanges={saveChanges}
                  Cencel="CANCEL"
                  Save="SAVE CHANGES "
                  content={
                    <div className="Accr_Body">
                      <FormSwitch
                        headline={`Enable ${elem.name}`}
                        values={values}
                        handleSwitchChange={(e) => handleSwitchChange(e)}
                      />

                      <SelectFieldOne
                        label="Select Plan"
                        selectdata={AllSelectData.filter(
                          (e) => e.number === index
                        )}
                        defaultValue={index + 2}
                        handleChange={(event, value) =>
                          selectHandler(event, value, elem)
                        }
                      />
                    </div>
                  }
                />
              );
            })}
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="table_box">
            <SimpleTable
              showHeader={true}
              title="Integrations"
              width="100%"
              rows={rows}
              columns={col}
              colSpan={16}
              actionSendButton={true}
              donationsCount={rows && rows.length > 0 ? 1 : 0}
              noTitle="No inquiry submitted yet"
              noPara="Inquiries submitted by visitors will be visible and managed here."
              noBtnText=""
              onInviteClick={() =>
                props.history.push("/organization/contact-us-details")
              }
              actionLink={(name) =>
                props.history.push({
                  pathname: "/organization/contact-us-details",
                  state: { contact: name[0].data },
                })
              }
              showIconCol={false}
              deleteAction={(e) => console.log(e)}
              showFooter={true}
              showAdd={false}
              addText="ADD NEW"
              onAddClick={() =>
                props.history.push("/organization/contact-us-details")
              }
              iconTextSize={16}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Plans;
