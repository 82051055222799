import React, {useEffect} from 'react';
import {
  BaseButton, MasterLayoutMiddle, FooterInner, BaseSelect, BaseImgChip, TextEditor, DrawerMain, DrawerListCheckbox
} from "@dclab/dc-react-ui-kit";
import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import Grid from '@material-ui/core/Grid';
import { useState } from 'react';
import "./style.css"
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import {
  getTeamMembers,
} from '../ContactUs/TeamMember/api';

function RequestRespond(props) {
  const {requestDetails} = props;
  console.log("requestDetails", requestDetails);
  const [open, setOpen] = React.useState(false);
  const [teamMembers, setteamMembers] = useState([]);
  const [values, setvalues] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [contact, setcontact] = useState({
    status: "", assignee: [], id: ""
  });
  const [chipData, setchipData] = useState([])
const [status, setstatus] = useState();


  // ============================== Status Start ======================================//
  const [response, setresponse] = React.useState({ status: "", assignee: "" });
  const [note, setnote] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);

  const onChange = (e) => {
    setnote(e)
  };

  useEffect(() => {
    setstatus(requestDetails?.status ? requestDetails.status : "");
    // setresponse({
    //   ...response,
    //   status: requestDetails?.status ? requestDetails.status : "", 
    // })
    // if(requestDetails && requestDetails.note) {
    //   setnote(JSON.parse(requestDetails.note))
    // }
  }, [requestDetails]);

  useEffect(() => {
    if(props.match.params.id) {
      props.onGetRequest(props.match.params.id);
    }

    try {
      const token = localStorage.getItem("authToken")
      const getData = async () => {

        const res2 = await getTeamMembers(token);
        setteamMembers(res2?.data?.body?.data)
        const member = res2?.data?.body?.data ?? [];
        if(member[0]) {
          
          let chipMember;
          let i;
          for(i = 0; i < member.length; i++) {
            const memberRole = member[i]?.role.filter(el => el.roles?.toLowerCase() === 'hikmah_owner');
            if(memberRole.length > 0) {
              chipMember =  member[i];
              break;
            }
          }

          let nameArr = chipMember ? [chipMember?.name?.firstName, chipMember?.name?.lastName] : [member[0]?.name?.firstName, member[0]?.name?.lastName]
          let initials;
          if (nameArr?.length > 1) {
            initials = nameArr[0][0] + nameArr[1][0];
          } else {
            initials = nameArr[0][0];
          }

          if(chipMember) {
            setchipData([{ key: chipMember._id, label: nameArr.join(' '), src: chipMember?.imageDetails?.image ?? "", alt: nameArr.join(' ')}])
            // setselectedData([{
            //    id: i + 1, primary: nameArr.join(' '), src: chipMember.imageUrl, secondary: chipMember.role,
            // }])
          } else {
            member?.length && setchipData([{ key: member[0]._id, label: nameArr.join(' '), src: member[0]?.imageDetails?.image ?? "", alt: nameArr.join(' ')}])
          }
          setresponse({...response, assignee: nameArr?.join(' ')});
          // setcontact({ ...contact, id: contactData?._id, status: contactData?.status ?? "New Order", assignee: [{name: nameArr.join(' '), imageUrl: member[0]?.imageDetails?.image ?? "", role: member[0]?.role?.[0]?.roles}] });
        }
      }
      getData();
    } catch (error) {
      console.log(error)
    }

    
  }, [])
  
  

  const closeDrawer = () => {
    setOpen(!open);
  };

  const onCancel = () => {
    setOpen(false);
  };
  console.log("Response is: ", response);

  const onSave = async () => {
    setOpen(false);
    const tempChipData = values?.map((el, i) => {
      return { key: i, label: el.primary, src: el.src, alt: "",
    }
  });
    setresponse({...response, assignee: tempChipData[0].label});
    setchipData(tempChipData);

    // setselectedData(values?.map((el, i) => {
    //   return {id: i , primary: nameArr.join(' '), src: chipMember.imageUrl, secondary: chipMember.role,}
    // }));

  }

  const data3 = teamMembers.map((text, index) => {
    let nameArr = text?.name?.lastName ?  [text.name.firstName, text.name.lastName] : [text.name.firstName];
    let initials;
    if (nameArr.length > 1) {
      initials = nameArr[0][0] + nameArr[1][0];
    } else {
      initials = nameArr[0][0];
    }

    return ({
      id: index + 1,
      primary: nameArr.join(' '),
      fullName: nameArr.join(' '),
      secondary: text.role.map((r, i) => {
        return (
          r.roles[0].toUpperCase() + r.roles.slice(1) + `${text.role.length === i + 1 ? '' : ', '}`
        );
      }),
      initials: !text?.imageDetails?.image ? initials : '',
      src: text?.imageDetails?.image ? text.imageDetails.image : '' /* initials.toUpperCase() */
    })
  });

  const getData = (values) => {
    if(!values?.length) return
    setvalues(values)
  }

  const submitHandler = (e) => {
      e.preventDefault();
      const formData = {
        status: status, 
        responseTimeline: {
          status: status, 
          assignee: response.assignee ? response.assignee : "DC Admin",
          note: note?.[0]?.children?.[0]?.text,
          createdAt: Date.now()
        }
      };

      console.log("Form Data is: ", formData);

      // if (requestDetails.note && requestDetails.status) {
          props.onEditRequest(props.match.params.id, formData)
      // }
  }

  const handleChange = (prop) => (event) => {
    if(props === 'status') {
      setstatus(event.target.value);
    } else {
      setresponse({ ...response, [prop]: event.target.value });
    }
  };

  const object = [
    {
      value: "New",
      label: "New",
    },
    {
      value: "Scheduled",
      label: "Scheduled",
    },
    {
      value: "Done",
      label: "Done",
    },
  ];
  
  const styles = {
    select: {
      margin: 8,
      minWidth: 120,
      height: 66,
      width: 120,
      margin: 8,
      border: "#D3D8DA",
      width: "100%",
    }
  };
  // ============================== Status End ======================================//

  // ============================== Assignee Start ======================================//
  // let chipData = [
  //   {
  //     key: 0,
  //     label: "Image Chip",
  //     src: "https://audioengineusa.com/wp-content/uploads/2019/01/a2.jpg",
  //     alt: "",
  //   },

  // ];

  const onInputClick = () => {
    console.log("input clicked");
  };

  const onChipInputClick = () => {
    console.log("input chip clicked");
    setOpen(true);
  };

  const deleteChip = () => {
    console.log('chip deleted')
  }

  // ============================== Assignee End ======================================//

  // const click = (msg) => {
  //   console.log(msg);
  // };

  // const btnClick = () => {
  //   console.log('invite');
  // }

  // const actionLinkHandler = (data) => {
  //   let id = data[0].id;
  //   props.history.push(`/organization-detail-${id}`)
  // }


  const style = {
    width:'100%',
    background: "#fff",
  };

  let disableBtn = true;

  return (
    <MasterLayoutMiddle

      header={
        <div>
          <div className="backBtn">
            <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
            <Link
              to={{ pathname: "/request-details-"+props.match.params.id, state: 1, }}
              style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
          </div>
        </div>
      }

      isForm={true}
      formData={
        <div>
          {props.page && props.page.loading && <Spinner />}
          <Grid container spacing={3}>

            <Grid item xs={12}>
              <h1 className="title_top">Assign</h1>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseSelect
                  value={status}
                  // onChange={handleChange("status")}
                  onChange = {e => setstatus(e.target.value)}
                  object={object}
                  style={styles.select}
                  //helperText="helper text"
                  label="Status"
                />
              </div>
            </Grid>

            <Grid item xs={12}>
              <div className="field_box">
                <BaseImgChip
                  chipData={chipData}
                  onInputClick={onInputClick}
                  onChipInputClick={onChipInputClick}
                  deleteChip={deleteChip}
                  helperText=''
                  label="Assignee"
                // textStyle={styles}
                // arrowClick={arrowClick}
                />
              </div>
            </Grid>


            <Grid item xs={12}>
              <div className="TextArea">
                <TextEditor
                  onChange={(e) => onChange(e)}
                  disabled={false}
                  value={note}
                  style={styles}
                  label={"Note"}
                  //helperText={"Helper Text"}
                  maxCharCount={100}
                />
              </div>
            </Grid>

            <Grid item xs={12}> 
              <div className="form_button">
                <div className="cencelButton">
                <BaseButton
                  onClick={() => props.history.goBack()}
                  text="cancel"
                  textStyle={styles.txt}
                  disableElevation={false}
                  disableFocusRipple={false}
                  disableRipple={false}
                  variant='text'
                />
                </div>
                <BaseButton
                  onClick={submitHandler}
                  type="submit"
                  text="Save"
                  disableElevation={false}
                  disableFocusRipple={false}
                  disableRipple={false}
                  style={disableBtn ? styles.inviteBtn : styles.disableBtn}
                  disabled={disableBtn ? false : true}
                />
              </div>
              </Grid>



          </Grid>

          <DrawerMain
            open={open}
            openHandler={closeDrawer}
            title="Select Assignee"
            onCancel={onCancel}
            loading={false}
            saveDisabled={false}
            onSave={onSave}
          >
            <div className="cardDrawer">
                <DrawerListCheckbox objects={data3}  getData={getData} selectedItems={selectedData}/>
            </div>
          </DrawerMain>


        </div>
      }



      isFooter={true}
      footer={
        <FooterInner style={style} />
      }

      isWhiteBackground={true}

    />
  )
}

const mapStateToProps = state => {
  return {
    requestDetails: state.contacts.requestDetails,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onGetRequest: (id) => dispatch(actions.getRequest(id)),
    onEditRequest: (id, data) => dispatch(actions.editRequest(id, data, ownProps)),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestRespond);