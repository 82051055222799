import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseSelect, TextEditor, BaseImgChip, BaseFileSelect, AlertAction, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classes from './addHardware.module.css';
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import MediaUpload from '../../components/common/MediaUpload/MediaUpload';



function AddHardware(props) {
    const [open, setOpen] = React.useState(false);
    const [uploadedImage, setuploadedImage] = useState(null)
    const [device, setdevice] = useState({ appStream: "", deviceId: "", macAddress: "", status: "Available", image: "", price: "" })

    const [deviceError, setdeviceError] = useState({ appStreamError: false, macAddressError: false, imageError: false, priceError: false })
    const [details, setdetails] = useState([{ type: 'paragraph', children: [{ text: '' }] }]);
    const [chipData, setchipData] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    let handleChange = (prop) => (event) => {
        if ((prop === "price") && event.target.value && event.target.value <= 0) {
            return
        }
        setdevice({
            ...device, [prop]: event.target.value
        });

        let streamInitials = '';
        let value = event.target.value;

        if (prop === "appStream") {
            if (event.target.value === 'DEENKIOSK') {
                streamInitials = 'DK'
            } else if (event.target.value === 'DEENSCREEN') {
                streamInitials = 'DS'
            } else {
                streamInitials = ''
            }
            const orgBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

            axios.get(`${orgBaseUrl}/digital-device/getUniqueDeviceId?appStream=${streamInitials}`).then((result) => {
                console.log(result.data)
                setdevice({ ...device, appStream: value, deviceId: result.data })
            }).catch((err) => {
                console.log(err);
            });
        }
    }

    const onChange = (e) => {
        setdetails(e)
    };

    let onSubmit = (e) => {
        e.preventDefault();
        console.log("submit", details)
        let formData = {
            ...device,
            macAddress: device.macAddress.toUpperCase(),
            price: device.price.match(/\d/g).join(""),
            details: JSON.stringify(details),
            imageMetaData: {
                imageUrl: uploadedImage.src,
                imageName: uploadedImage.title,
                imageSize: 0
            },
        };

        if (appStream && deviceId && macAddress && status && image && price && details) {
            props.onAddDevice(formData)
        }
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const style = {
        width:'100%',
        background: "#fff",
      };

    const callback = (selectedFiles) => {
        console.log(selectedFiles)
        if (selectedFiles.src) {
            setuploadedImage(selectedFiles);
            setdevice({
                ...device, "image": selectedFiles
            });
        }
    };

    const closeDrawer = () => {
        setOpen(!open);
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onSave = () => {
        if (uploadedImage) {
            setchipData([{ key: 0, label: uploadedImage.title, src: uploadedImage.src, alt: uploadedImage.title }])
            setdevice({ ...device, image: uploadedImage.src })
            setOpen(!open);
        }
    };


    const onInputClick = () => {
        console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
        setOpen(!open);
    };

    const deleteChip = () => {
        setdevice({ ...device, image: "" })
        setchipData([])
    }

    const { appStream, deviceId, macAddress, status, image, price } = device;
    const { appStreamError, macAddressError, priceError } = deviceError;
    let disableBtn = appStream && deviceId && macAddress && status && image && price && details[0].children[0].text ? false : true;

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Image"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                // img={Logo}
                saveDisabled={false}
            >

                <BaseFileSelect
                    selectedFiles={callback}
                />
            </DrawerMain>

            <MasterLayoutMiddle
                header={
                    <div>
                        {/* {
                    props.errors && props.errors.error && <AlertAction
                    alert={props.errors.message}
                    alertType={"error"}
                    //   onClick={this.closeAlert}
                    />
                    } */}
                        {/* <Link to="/admin-organization" style={{textDecoration: 'none'}} > */}
                        <div className="backBtnFrom">
                            <BaseButton
                                onClick={() => props.history.push("/hardware")}
                                text="back"
                                textStyle={styles.txt}
                                disableElevation={false}
                                disableFocusRipple={false}
                                disableRipple={false}
                                style={styles.backBtn}
                                startIcon={<ChevronLeftIcon />}
                            />
                        </div>
                        {/* </Link> */}
                        {props.page && props.page.loading && <Spinner />}
                    </div>
                }
                isForm={true}
                formData={
                    <form onSubmit={onSubmit}>
                        <Grid container spacing={3}>

                            <Grid item xs={12}>
                                <h1 className="title_top">Add Hardware/Device</h1>
                            </Grid>
                            <Grid item xs={12}>
                                <h1 className="sub_title">Device Information</h1>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseSelect
                                        value={appStream}
                                        onChange={handleChange("appStream")}
                                        object={[
                                            {
                                                value: "DEENKIOSK",
                                                label: "DEENKIOSK",
                                            },
                                            {
                                                value: "DEENSCREEN",
                                                label: "DEENSCREEN",
                                            }
                                        ]}
                                        style={styles.input}
                                        helperText=""
                                        label='App Stream'
                                        placeholder="App Stream"
                                    // onBlur={() => appStream ? setdeviceError({ appStreamError: false }) : setdeviceError({ appStreamError: true })}
                                    // error={!appStream && appStreamError ? true : false}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseTextField
                                        value={deviceId}
                                        // onChange={handleChange("deviceId")}
                                        label='Device Id'
                                        placeholder="Device Id"
                                        InputProps={false}
                                        phone={false}
                                        search={false}
                                        style={styles.input}
                                        color="green"
                                        disabled
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseTextField
                                        value={macAddress.toUpperCase()}
                                        onChange={handleChange("macAddress")}
                                        label='Mac Address'
                                        placeholder="Mac Address"
                                        InputProps={false}
                                        phone={false}
                                        search={false}
                                        //count={100}
                                        style={styles.input}
                                        onBlur={() => macAddress ? setdeviceError({ macAddressError: false }) : setdeviceError({ macAddressError: true })}
                                        error={!macAddress && macAddressError ? true : false}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">
                                    {/* <BaseTextField
                                value={price}
                                onChange={handleChange("price")}
                                label='Device Cost'
                                placeholder="Device Cost"
                                InputProps={false}
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="string"
                                onBlur={() => price ? setdeviceError({priceError: false }) : setdeviceError({ priceError: true })}
                                error={!price && priceError ? true : false}
                            /> */}
                                    <TextField
                                        value={price}
                                        onChange={handleChange("price")}
                                        label='Device Cost'
                                        placeholder="Device Cost"
                                        style={styles.input}
                                        type="number"
                                        onBlur={() => price ? setdeviceError({ priceError: false }) : setdeviceError({ priceError: true })}
                                        error={!price && priceError ? true : false}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                        }}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">
                                    <BaseSelect
                                        value={status}
                                        onChange={handleChange("status")}
                                        label='Device Status'
                                        placeholder="Device Status"
                                        object={[
                                            { value: "Available", label: "Available" },
                                            { value: "Unavailable", label: "Unavailable" },
                                            { value: "Defective", label: "Defective" },
                                            { value: "Lost", label: "Lost" },
                                            { value: "Locked", label: "Locked" },
                                            { value: "Active", label: "Active" },
                                            { value: "Shipped", label: "Shipped" },
                                            { value: "Pending", label: "Pending" },
                                        ]}
                                        style={styles.input}

                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <MediaUpload
                                    addMediaItem={callback}
                                    label="Device Image"
                                    buttonName="ADD Image"
                                    typeOfMedia='/images'
                                />
                                {/* <div className="field_box" onClick={onChipInputClick}>
                                <BaseImgChip
                                    chipData={chipData}
                                    onInputClick={onInputClick}
                                    onChipInputClick={onChipInputClick}
                                    deleteChip={deleteChip}
                                    label="Image"
                                />
                            </div> */}
                            </Grid>

                            <Grid item xs={12}>
                                <TextEditor label={"Details"} maxCharCount={100} onChange={(e) => onChange(e)} value={details} />
                            </Grid>

                            <Grid item xs={12}>
                                <div className="form_button">
                                    <BaseButton
                                        onClick={() => props.history.push("/hardware")}
                                        text="cancel"
                                        textStyle={styles.txt}
                                        disableElevation={false}
                                        disableFocusRipple={false}
                                        disableRipple={false}
                                        variant='text'
                                    />
                                    <BaseButton
                                        onClick={onSubmit}
                                        type="submit"
                                        text={'Add Now'}
                                        disabled={disableBtn}
                                        disableElevation={false}
                                        disableFocusRipple={false}
                                        disableRipple={false}
                                        style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                                    />
                                </div>
                            </Grid>

                            <Grid item xs={12}>
                                <div className="field_box">

                                </div>
                            </Grid>

                        </Grid>
                    </form>

                }



                isFooter={true}
                footer={
                    <FooterInner style={style} />
                }

                isWhiteBackground={true}


            />


        </Fragment>
    )

}

const mapStateToProps = state => {
    return {
        page: state.page,
        errors: state.errors
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddDevice: (device) => dispatch(actions.addDevice(device, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddHardware);