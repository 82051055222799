import React from 'react';
import { Route, withRouter, Switch } from 'react-router-dom';

import AdminOrganization from './AdminOrganization';
import InviteOrganization from './InviteOrganization';
import OrganizationDetail from './OrganizationDetail';
import EditOrganizationDetial from './EditOrganizationDetial';
import AddTeamMember from './AddTeamMember';
import VarifyEin from './VarifyEIN';
import VarifyTaxExemption from './VarifyTexExemption';
import HardwareDevices from './HardwareDevices/Index';
import AddHardware from './AddHardware/Index';
import EditHardware from './EditHardware/Index';
import HardwareDetails from './HardwareDetails/Index';
import AssignHardware from './AssignHardware/Index';
import SelectHardware from './SelectHardware/Index';
import FinanceTeamMembers from './FinanceTeamMember/Index';
import AddFinanceTeamMember from './AddFinanceTeamMember/AddFinanceTeamMember';
import VerifyFinanceMemberEmail from './VerifyFinanceMemberEmail/VerifyFinanceMemberEmail';

import AdminProfile from './AdminProfile/Index';
import EditPofile from './AdminProfile/EditPofile';
import ChangePassword from './AdminProfile/ChangePassword';

import Comunication from './DemoRequest';
import RequestDetails from './DemoRequest/RequestDetails';
import RequestRespond from './DemoRequest/RequestRespond';

import ContactUsComp from './ContactUs/ContactUs';
import ContactUsDetails from './ContactUs/ContactUsDetails';
import ContactUsRespond from './ContactUs/ContactUsRespond';

import QuoteRequests from './QuoteRequests/QuoteRequests';
import QuoteRequestsDetails from './QuoteRequests/QuoteRequestsDetails';
import QuoteRequestsRespond from './QuoteRequests/QuoteRequestsRespond';




import EditFinanceTeamMember from './AddFinanceTeamMember/EditFinanceTeamMember';

import AdminProducts from './AdminProducts/index.js';
import AddProduct from './AdminProducts/addApplication.js';
import EditProduct from './AdminProducts/editApplication.js';
import AddIntegration from './AdminProducts/addIntegration.js';
import addPlans from './AdminProducts/ApplicationSteps/addPlans';
import editPlans from './AdminProducts/ApplicationSteps/editPlans';
import editIntegration from './AdminProducts/editIntegration';

import Campaign from './Campaign/Campaign';
import AddNewCampaign from './Campaign/AddNewCampaign';
import EditCampaign from './Campaign/EditCampaign';
import CampaignDetails from './Campaign/CampaignDetails';

import Order from './Order/Order';
import OrderDetails from './Order/OrderDetails';
import OrderFrom from './Order/OrderFrom';
import ManageOrder from './Order/ManageOrder';

import dashboardStats from './dashboardStats';

import OrgPlan from './OrganizationDetail/OrgPlan/OrgPlan';
import OrgPlanDetails from './OrganizationDetail/OrgPlan/Details';

import OrganizationOrder from './OrganizationDetail/OrganizationOrder/';

import PaymentReceipt from './OrganizationDetail/OrganizationOrder/PaymentReceipt';

import TeamMemberResetPasswordEmailCode from './AddFinanceTeamMember/TeamMemberResetPasswordEmailCode';
import TeamMemberResetPassword from './AddFinanceTeamMember/TeamMemberResetPassword';

import AdminOrganizationNew from './AdminOrganizationNew';
import OrganizationOnboard from './OrganizationOnboard';



class Routes extends React.Component {

	render() {
		return (
			<Switch>
				<Route exact path="/dashboard" component={AdminOrganization} />
				<Route exact path="/admin-organization" component={AdminOrganization} />
				<Route exact path="/invite-organization" component={InviteOrganization} />
				<Route exact path="/invite-organization-:id" component={InviteOrganization} />
				<Route exact path="/organization-detail-:id" component={OrganizationDetail} />
				<Route exact path="/edit-organization-detail-:id" component={EditOrganizationDetial} />
				<Route exact path="/add-team-member-:id" component={AddTeamMember} />
				<Route exact path="/varify-ein/:id" component={VarifyEin} />
				<Route exact path="/varify-tax-exemption" component={VarifyTaxExemption} />
				<Route exact path="/hardware" component={HardwareDevices} />
				<Route exact path="/add-hardware" component={AddHardware} />
				<Route exact path="/edit-hardware/:id" component={EditHardware} />
				<Route exact path="/hardware-details/:id" component={HardwareDetails} />
				<Route exact path="/assign-hardware/:id" component={AssignHardware} />
				<Route exact path="/select-hardware/:id" component={SelectHardware} />
				<Route exact path="/team-members" component={FinanceTeamMembers} />
				<Route exact path="/add-finance-team-member" component={AddFinanceTeamMember} />
				<Route exact path="/edit-finance-team-member/:id" component={EditFinanceTeamMember} />
				<Route exact path="/verify-team-member-code" component={VerifyFinanceMemberEmail} />

				<Route exact path="/admin-profile" component={AdminProfile} />
				
				<Route exact path="/edit-profile" component={EditPofile} />
				<Route exact path="/change-password" component={ChangePassword} />

				<Route exact path="/communication" component={Comunication} />
				<Route exact path="/request-details-:id" component={RequestDetails} />
				<Route exact path="/request-respond-:id" component={RequestRespond} />

				<Route exact path="/communication/contact-us" component={ContactUsComp} />
				<Route exact path="/communication/contact-us-details" component={ContactUsDetails} />
				<Route exact path="/communication/contact-us-respond" component={ContactUsRespond} />

				<Route exact path="/communication/quote-requests" component={QuoteRequests} />
				<Route exact path="/communication/quote-requests-details/:id" component={QuoteRequestsDetails} />
				<Route exact path="/communication/quote-requests-respond" component={QuoteRequestsRespond} />
				



				<Route exact path="/admin-products" component={AdminProducts} />
				<Route exact path="/add-product" component={AddProduct} />
				<Route exact path="/edit-product/:id" component={EditProduct} />
				<Route exact path="/add-plan" component={addPlans} />
				<Route exact path="/edit-plan" component={editPlans} />

				<Route exact path="/add-integration" component={AddIntegration} />
				<Route exact path="/edit-integration/:id" component={editIntegration} />


				<Route exact path="/campaign" component={Campaign} />
				<Route exact path="/add-new-campaign" component={AddNewCampaign} />
				<Route exact path="/edit-campaign" component={EditCampaign} />
				<Route exact path="/campaign-details" component={CampaignDetails} />  

				<Route exact path="/order" component={Order} />
				<Route exact path="/order/order-details" component={OrderDetails} />
				<Route exact path="/order/order-form" component={OrderFrom} />
				<Route exact path="/order/manage-order" component={ManageOrder} />
				
				<Route exact path="/dashboard-stats" component={dashboardStats} />  
				<Route exact path="/plans/details/:id" component={OrgPlanDetails} /> 
				<Route exact path="/plans/:id" component={OrgPlan} /> 

				<Route exact path="/orgorders/:id" component={OrganizationOrder} /> 

				<Route exact path="/organization/orders/payment-receipt" component={PaymentReceipt} /> 

				<Route exact path="/team-members/reset-password-cod" component={TeamMemberResetPasswordEmailCode} /> 
				<Route exact path="/team-members/reset-password" component={TeamMemberResetPassword} /> 

				<Route exact path="/admin-organization-new" component={AdminOrganizationNew} />
				<Route exact path="/organization-onboard" component={OrganizationOnboard} /> 

				

				


				

			</Switch>
		);
	}
}

export default withRouter(Routes);