// ERRORS ACTIONS
export const SET_ERRORS = 'SET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';


//AUTH ACTIONS
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const GET_CURRENT_USER_DETAIL = 'GET_CURRENT_USER_DETAIL';
export const CLEAR_CURRENT_USER = 'CLEAR_CURRENT_USER';


//PAGE ACTIONS
export const PAGE_LOADING = 'PAGE_LOADING';
export const PAGE_LOADED = 'PAGE_LOADED';

//ORGANIZATION SECTION START
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_SINGLE_ORGANIZATION = 'SET_SINGLE_ORGANIZATION';
export const SET_ORGANIZATIONS_ORDERS = 'SET_ORGANIZATIONS_ORDERS';
export const SET_ORGANIZATIONS_ORDERS_RECEIPTS = 'SET_ORGANIZATIONS_ORDERS_RECEIPTS';


//INVITED ORGANIZATION SECTION START
export const SET_INVITED_ORGANIZATIONS = 'SET_INVITED_ORGANIZATIONS';
export const SET_SINGLE_INVITE_ORGANIZATION = 'SET_SINGLE_INVITE_ORGANIZATION';
export const ORGS_AND_INVITED_ORGS  = 'ORGS_AND_INVITED_ORGS';
export const SET_STRIPE_ORGANIZATION  = 'SET_STRIPE_ORGANIZATION';

//SETTING TEMP USER
export const SET_TEMP_USER = 'SET_TEMP_USER';
export const SET_CURRENT_USER_DETAILS = 'SET_CURRENT_USER_DETAILS';

//TEAM MEMBERS ACTION START
export const SET_TEAM_MEMBERS = 'SET_TEAM_MEMBERS';
export const SET_FINANCE_TEAM_MEMBERS = 'SET_FINANCE_TEAM_MEMBERS';
export const SET_FINANCE_TEAM_MEMBER = 'SET_FINANCE_TEAM_MEMBER';
export const SET_VERIFY_TEAM_MEMBER_EMAIL = 'SET_VERIFY_TEAM_MEMBER_EMAIL';

//CONATCT ACTIONS
export const SET_ALL_CONTACTS = 'SET_ALL_CONTACTS';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_ALL_REQUESTS = 'SET_ALL_REQUESTS';
export const SET_REQUEST = 'SET_REQUEST';
export const SET_QUOTEREQUEST = 'SET_QUOTEREQUEST' ; 

//ORDERS ACTIONS
export const SET_ALL_ORDERS = 'SET_ALL_ORDERS';
export const SET_ORDER = 'SET_ORDER';

//HARDWARE DEVICES SECTION START
export const GET_ALL_DEVICES = 'GET_ALL_DEVICES';
export const GET_EDIT_DEVICE = 'GET_EDIT_DEVICE';
export const DELETE_DEVICE = 'DELETE_DEVICE';
export const GET_NOT_ASSIGNED_DEVICES = 'GET_NOT_ASSIGNED_DEVICES';
export const GET_ORG_ASSIGNED_DEVICES = 'GET_ORG_ASSIGNED_DEVICES';

//PRODUCT SECTION START
export const GET_ALL_INTEGRATIONS = 'GET_ALL_INTEGRATIONS';
export const GET_ALL_APPLICATIONS = 'GET_ALL_APPLICATIONS';
export const ADD_APP_INFO = 'ADD_APP_INFO';
export const ADD_APPLICATION = 'ADD_APPLICATION';
export const DELETE_APPLICATION = 'DELETE_APPLICATION';
export const GET_APPLICATION = 'GET_APPLICATION';
export const ADD_INTEGRATION_INFO = 'ADD_INTEGRATION_INFO';
export const ADD_INTEGRATION = 'ADD_INTEGRATION';
export const GET_INTEGRATION = 'GET_INTEGRATION';
export const DELETE_INTEGRATION = 'DELETE_INTEGRATION';
export const SET_CARD_DATA = 'SET_CARD_DATA';

//CAMPAIGN SECTION
export const SET_ALL_CAMPAIGN = 'SET_ALL_CAMPAIGN'