import React, { useState, Fragment, useEffect, forwardRef, useImperativeHandle  } from 'react'
import { DrawerMain, BaseTextField, TextEditor, BaseImgChip, BaseFileSelect,  AlertAction, BaseSelect } from "@dclab/dc-react-ui-kit";
import "../products.css"
import {Grid, TextField} from '@material-ui/core';  
import MediaUpload from '../../../components/common/MediaUpload/MediaUpload';


const BasicInfo = forwardRef((props, ref) => {
    const {applicationInfo, setstepDisabled} = props;
    const [open, setOpen] = React.useState(false);
    const [uploadedImage, setuploadedImage] = useState(null)
    const [application, setapplication] = useState({ name: "", stripeProductId: "", shortDescription: "", image: "", imageName: '', icon: '', iconName: '', displayName: ''})
    const [logoError, setlogoError] = useState(false);

    // const [applicationError, setapplicationError] = useState({ nameError: false, shortDescriptionError: false, imageError: false})

    const [longDescription, setlongDescription] = useState('');
    const [chipData, setchipData] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
       setapplication({ 
           name: applicationInfo?.name ?? "", 
           stripeProductId: applicationInfo?.stripeProductId ?? "", 
           shortDescription: applicationInfo?.shortDescription ?? "", 
           image: applicationInfo?.imageMetaData?.imageUrl ?? "",
           imageName: applicationInfo?.imageMetaData?.imageName ?? "",
           icon: applicationInfo?.iconMetaData?.iconUrl ?? "",
           iconName: applicationInfo?.iconMetaData?.iconName ?? "",
           displayName: applicationInfo?.displayName ?? "",
           
        })
        applicationInfo?.imageMetaData?.imageUrl && setchipData([{ key: 0, label: applicationInfo?.imageMetaData?.imageName, src: applicationInfo?.imageMetaData?.imageUrl, alt: applicationInfo?.imageMetaData?.imageName }])
        applicationInfo?.longDescription && setlongDescription(applicationInfo.longDescription)
    }, [applicationInfo])

    let handleChange = (prop) => (event) => {
        console.log("prop: ", prop);
        console.log("event: ", event.target.value);
        if(prop === "shortDescription" && event.target?.value?.length > 100) {
            return
        }
            setapplication({ ...application, [prop]: event.target.value });
        // props.setChangeData(formDataFunc());
            props.setChanges(true);
    }

    const onChange =  (e) => {
        if(e.target.value.length > 500) {
            return
        }
        setlongDescription(e.target.value);
        props.setChanges(true);
    };

    const formDataFunc = () => ({
            name: application.name?.trim(), 
            displayName: application?.displayName,
            stripeProductId: application.stripeProductId,
            shortDescription: application.shortDescription,
            longDescription: longDescription,
            imageMetaData: {
                imageUrl: image,
                imageName: uploadedImage?.title ?? application.imageName,
                imageSize: 0
            },
            iconMetaData: {
                iconUrl: application.icon,
                iconName:  application.iconName,
                imageSize: 0
            },
    })

    useImperativeHandle(ref, () => ({
        onSubmit() {    
            if (name && shortDescription && image && longDescription, icon) {
                let formData = formDataFunc();
                console.log("submit", formData)
                props.onAddAppInfo(formData)
            }
        }
      }));

    const callback = (selectedFiles) => {
        // console.log(selectedFiles[0])
        if(selectedFiles.src) {
            setuploadedImage(selectedFiles);
            setapplication({ ...application, image: selectedFiles.src, imageName:  selectedFiles.title}); 
        }
    };
    
    const iconCallback = (selectedFiles) => {
        // console.log(selectedFiles[0])
        if(selectedFiles.src) {
            setapplication({ ...application, icon: selectedFiles.src , iconName: selectedFiles.title}); 
        }
    };


    const closeDrawer = () => {
        setOpen(!open);
    };
    
    const onCancel = () => {
        setOpen(false);
    };
      
    const onSave = () => {
    console.log("size", uploadedImage?.size)
    if(uploadedImage?.src?.length && uploadedImage?.size > 1048576) {
        setlogoError(true)
        setTimeout(() => {
            setlogoError(false)
        }, 8000);
    } else if(uploadedImage) {
        setchipData([{ key: 0, label: uploadedImage.title, src: uploadedImage.src, alt: uploadedImage.title }])
        setapplication({ ...application, image: uploadedImage.src })
        setOpen(!open);
        setlogoError(false)
    }
    };

    const onInputClick = () => {
    console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
        setOpen(!open);
    };

    const deleteChip = () => {
        setapplication({ ...application, image: "" })
        setchipData([])
    }

        let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const appApiList = [
        {
            value: "deenweb",
            label: "DeenWeb",
        },
        {
            value: "deenkiosk",
            label: "DeenKiosk",
        },
        {
            value: "deenfund",
            label: "DeenFund",
        },
        {
            value: "deenscreen",
            label: "DeenScreen",
        },
    ];

    
    const { name, shortDescription, image, imageName, stripeProductId,icon, iconName, displayName } = application;
    // const { nameError, shortDescriptionError } = applicationError;
    let disableBtn = name && shortDescription && image && longDescription&& icon && displayName ? false : true; 
    setstepDisabled(disableBtn)

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Image"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                saveDisabled={false}
            >
                <BaseFileSelect
                    selectedFiles={callback}
                />
            </DrawerMain>

        <form>
        {
            logoError && (
                <div className="header_alert">
                <AlertAction
                alert={"Logo size should be less than 1MB"}
                alertType={"error"}
                onClick={() => setlogoError(false)}
                />
            </div>
            )
        }

        <div className="formContainerHikmah bottomSpacing">
        <Grid container spacing={3}>
            <Grid item xs={12}> 
                <h1 className="title_top noBorder" style={{textAlign: "left"}}>Basic Information</h1>	 
            </Grid>	      

            <Grid item xs={12}> 
                <div className="field_box">
                <BaseTextField   
                    value={displayName}
                    onChange={handleChange("displayName")}
                    count={50}
                    label='Product Name'
                    placeholder="Product Name"
                    InputProps={false}
                    phone={false}
                    search={false}
                    style={styles.input}
                    color="green"
                />
                </div>
            </Grid>

            

            <Grid item xs={12}> 
                <div className="field_box">
                <BaseTextField
                    value={stripeProductId}
                    onChange={handleChange("stripeProductId")}
                    label='Stripe Product ID'
                    placeholder="Stripe Product ID"
                    InputProps={false}
                    phone={false}
                    search={false}
                    style={styles.input}
                    color="green"
                />
                </div>
            </Grid>

            <Grid item xs={12}> 
            {/* {chipData?.[0]?.src &&  */}
            <MediaUpload
                addMediaItem = {callback}
                label = "Product Logo"
                buttonName = "ADD LOGO"
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: image, logoName: imageName }} 
                    
            />
            {/* {!chipData?.[0]?.src && <MediaUpload
                addMediaItem = {callback}
                label = "Product Logo"
                buttonName = "ADD LOGO"
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }} 
                    
            />} */}
                {/* <div className="field_box" style={{marginTop: 15, cursor: "pointer"}} onClick={() => onChipInputClick()}>
                <BaseImgChip
                    chipData={chipData}
                    onInputClick={onInputClick}
                    onChipInputClick={onChipInputClick}
                    deleteChip={deleteChip}
                    label="Logo"
                />
                </div> */}
            </Grid>


            <Grid item xs={12}> 
            <MediaUpload
                addMediaItem = {(obj) => iconCallback(obj)}
                label = "Logo Icon"
                buttonName = "ADD LOGO ICON"
                typeOfMedia='/images'  
                selectedMedia = {{logoUrl: application?.icon, logoName: application?.iconName}} 
                    
            />
            
            </Grid>

            <Grid item xs={12} > 
                <div className="field_box fieldFullwidth">
                <BaseSelect
                    value={name}
                    onChange={handleChange("name")}
                    object={appApiList}
                    style={styles.select}
                    helperText=""
                    label="Select API"
                />
              </div>
            </Grid>

            <Grid item xs={12}> 
                <div className="field_box short-description">
                    <div className="description-label">Short Description</div>
                <TextField 
                    variant={"outlined"}
                    value={shortDescription}
                    onChange={handleChange("shortDescription")}
                    placeholder="Short description here"
                    rows={2}
                    multiline
                    style={styles.input}
                    inputProps={{
                        maxLength: 100
                    }}
                />
                </div>
                <div className="description-count">CHAR {shortDescription?.length}/100</div>
            </Grid>
                {console.log("longDescription: ", longDescription)}
            <Grid item xs={12}> 
                <div className="field_box short-description">
                    <div className="description-label">Long Description</div>
                <TextField 
                   
                    variant={"outlined"}
                    value={longDescription}
                    onChange={(e) => onChange(e)}
                    placeholder="Enter long description"
                    rows={2}
                    multiline
                    style={styles.input}
                    inputProps={{
                        maxLength: 500
                    }}
                />
                </div>
                <div className="description-count">CHAR {longDescription?.length}/500</div>
            </Grid>

            {/* <Grid item xs={12}> 
                <TextEditor placeholder="Enter long description" label={"Long Description"} maxCharCount={500} onChange={(e) => onChange(e)} value={longDescription}  />
            </Grid> */}

            </Grid>
            </div>
        </form>
        </Fragment>
    )
})

// const mapStateToProps = state => {
//     return {
//           page: state.page,
//           errors: state.errors,
//           applicationInfo: state.products.applicationInfo
//     }
// };

// const mapDispatchToProps = (dispatch, ownProps) => {
//     return {
//         onAddAppInfo: (application) => dispatch(actions.addAppInfo(application, ownProps)),
//     }
// };

// export default connect(mapStateToProps, mapDispatchToProps)(BasicInfo);

export default BasicInfo;
