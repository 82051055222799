import React, { useState, useEffect } from 'react'
import moment from 'moment';
import { BaseTabs, MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";

import TabContent1 from './tabContent1';
import TabContent2 from './tabContent2';
import CircularProgress from "@material-ui/core/CircularProgress";
import { SimpleTable, } from "@dclab/dc-react-ui-kit";


import Alert from '../../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../../store/Actions/index';
import Spinner from '../../../components/common/Spinner';
import TopCardsData from '../../../components/common/TopCardsData';
import './style.css';
import Grid from '@material-ui/core/Grid';
import { getApps, getIntegrations, getPlans,getPlanSubscriptions } from "./api";


function OrgPlan(props) {
  const [orgsAndInvitedOrgs, setorgsAndInvitedOrgs] = useState({});
  const [value, setvalue] = useState(0)
  const [show, setshow] = useState(true)
  const [loading, setloading] = useState(false)
  const [organizations, setorganizations] = useState([])
  const [apps, setapps] = useState([])
  const [integrations, setintegrations] = useState([])
  const [planSubscription, setplanSubscription] = useState({})
  const [planSubscriptionData, setplanSubscriptionData] = useState([])
  const [plans, setplans] = useState({})



  useEffect(() => {
    const getDetails = async () => {
      // const token = localStorage.getItem("authToken");
      // const orgId = localStorage.getItem("orgID");
      try {
        setloading(true)
          const appsRes = await getApps();
          const integrationsRes = await getIntegrations();
          let id = props.match?.params?.id;
          const plansRes = await getPlans(id);

          console.log("plansRes", plansRes)
          if(plansRes?.data?.body?.data?.planSubscriptionId) {
            const res = await getPlanSubscriptions(plansRes?.data?.body?.data?.planSubscriptionId);
            setplanSubscription(res?.data?.body?.data)
          }

          setplans(plansRes?.data?.body?.data)
          // console.log("plansRes", plansRes)
          // console.log("appsRes", appsRes)
          // console.log("integrationsRes", integrationsRes)
          setapps(appsRes?.data?.body?.data)
          setintegrations(integrationsRes?.data?.body?.data)
          setloading(false)
        } catch (error) {
            // seterror(error.response && error.response.data.message)
            console.log("errs", error)            
            setloading(false)
        }
    }
    getDetails();
}, [])

useEffect(() => {
  if(planSubscription?._id) {
    const arr = Object.keys(planSubscription)
    .filter(v => {
      return planSubscription[v] != null && typeof planSubscription[v] === 'object'
    }).map(key => ({ ...planSubscription[key], planName: [key][0] }))
    setplanSubscriptionData(arr)
    console.log("arrs", arr)
  }
}, [planSubscription])

  const onClick = (x, y) => {
    console.log('checking onClick function: ', x)
    console.log('checking onClick function Y:', y)
    if (y === 1) {
      // this.props.onGetInvitedOrganizations();
      setshow(true);
    }
    else {
      // this.props.onGetOrganizations();
      setshow(false);
    }
  }

  const handleChange = (event, newValue) => {
    setvalue(newValue);
  };

  const style = {
    width:'100%',
    background: "#f7f8fa",
  };


  let pageContent = ''
  if (loading) {
    pageContent = <Spinner />
  }

  const capitalizeFLetter = (val) => {  
    return val ? `${val[0].toUpperCase() + val.slice(1)}` : ""; 
  } 

  
  let appRows = [];
  let intRows = [];
  let rows = [];
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  let col = [
    // { id: "NO", label: "NO.", width: "auto" },
    { type: "url", name: "single_pic", label: "", width: '22%' },
    { type: "string", name: "name", label: "PLAN", width: '15%' },
    { type: "string", name: "category", label: "PRICE", width: '15%' },
    { type: "string", name: "category", label: "BILLING CYCLE", width: '20%' },
    { type: "string", name: "category", label: "STARTED", width: '25%' },
    { id: "STATUS", label: "STATUS", width: '15%' },
    { type: "action", name: "action", label: "", width:"10%" },
];

  console.log("planSubscriptionData: ", planSubscriptionData)
  planSubscriptionData?.length && planSubscriptionData.forEach((plan, i) => {
    if(plan.planName?.toLowerCase() === "extraintegrations") {
      plan.selectedIntegrations?.length && plan.selectedIntegrations.forEach(int => {

        let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === int?.name?.toLowerCase()) : {};
        let integration = integrations?.length ? integrations?.find((el) => el?.name?.toLowerCase() === int?.name?.toLowerCase()) : {};
        rows.push([
          { type: "string", name: "textImg", src: integration?.imageMetaData?.imageUrl ? integration.imageMetaData.imageUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: integration?.name, initials: "", plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: integration?.name, planType: "integration"} },
          { type: "string", name: "name", value: capitalizeFLetter(integration?.pricing?.pricingType) },
          { type: "string", name: "category", value: int.paymentFrequency === "monthly" ? `$${int.price}/mo` : int.price ? `$${(parseFloat(int.price) / 12).toFixed(2)}/mo` : "$0/mo"  },
          { type: "string", name: "category", value: capitalizeFLetter(int.paymentFrequency) ?? "N/A"},
          { type: "string", name: "category", value: int?.createdAt ? moment(int?.createdAt).format("MMM DD, YYYY, HH:mm A") : planPayment?.created ? moment(planPayment.created * 1000).format("MMM DD, YYYY, HH:mm A") : "N/A" },
          {
            type: "status",
            status: "success",
            value: "Active",
          },
          { type: "action", status: "details", value: "Manage Plan" }
        ])
      });
    } else if(plan.plan) {
      let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === plan?.planName?.toLowerCase()) : {};

      let app = apps?.length ? apps?.find((el) => el?.name?.toLowerCase() === plan?.planName?.toLowerCase()) : {};
      rows.push([
        { type: "string", name: "textImg", src:  app?.iconMetaData?.iconUrl ? app.iconMetaData.iconUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png", primaryText: app?.displayName ?? app?.name, initials: "", plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: plan?.planName, planType: "application"} },
        { type: "string", name: "name", value: plan.plan ? capitalizeFLetter(plan.plan) : "" },
        { type: "string", name: "category", value: plan.paymentFrequency === "monthly" ? `$${plan.total}/mo` : plan.total ? `$${(parseFloat(plan.total) / 12).toFixed(2)}/mo` : "$0/mo" },
        { type: "string", name: "category", value: capitalizeFLetter(plan.paymentFrequency) ?? "N/A" },
        { type: "string", name: "category", value: plan?.createdAt ? moment(plan?.createdAt).format("MMM DD, YYYY, HH:mm A") : planPayment?.created ? moment(planPayment.created * 1000).format("MMM DD, YYYY, HH:mm A") : "N/A" },
        {
          type: "status",
          status: "success",
          value: "Active",
        },
        { type: "action", status: "details", value: "Manage Plan" }
      ])
    }
  });

  // planSubscriptionData?.length && planSubscriptionData.forEach((plan, i) => {
  //   if(plan.planName?.toLowerCase() === "extraintegrations") {
  //     plan.selectedIntegrations?.length && plan.selectedIntegrations.forEach(int => {

  //       let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === int?.name?.toLowerCase()) : {};

  //       let integration = integrations?.length ? integrations?.find((el) => el?.name?.toLowerCase() === int?.name?.toLowerCase()) : {};

  //       rows.push([
  //         { type: "id",  name: 'serial_number', value: i + 1, plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: int?.name?.toLowerCase(), planType: "integration", image: integration?.imageMetaData?.imageUrl ? integration.imageMetaData.imageUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png"}},
  //         { type: "string", name: "name", value: int?.name?.toUpperCase(), initials: "",  },
  //         // { type: "string", name: "name", value: "INTEGRATION" },
  //         { type: "string", name: "category", value: int.paymentFrequency === "monthly" ? `$${int.price}/mo` : int.price ? `$${(parseFloat(int.price) / 12).toFixed(2)}/mo` : ""  },
  //         { type: "string", name: "category", value: capitalizeFLetter(int.paymentFrequency) ?? "" },
  //         { type: "string", name: "category", value: capitalizeFLetter(planPayment?.payment_method_details?.card?.brand ?? "") },
  //         {
  //           type: "status",
  //           status: "success",
  //           value: "Active",
  //         },
  //         { type: "action", status: "details", value: "see details" }
  //       ])
  //     });
  //   } else {
  //     let planPayment = plans?.stripeData?.length ? plans?.stripeData?.find((el) => el?.subscription_plan?.toLowerCase() === plan?.planName?.toLowerCase()) : {};

  //     let app = apps?.length ? apps?.find((el) => el?.name?.toLowerCase() === plan?.planName?.toLowerCase()) : {};

  //     rows.push([
  //       { type: "id",  name: 'serial_number', value: i + 1, plan: {...planPayment, orgId: plans?.orgId, planSubscriptionId: plans?.planSubscriptionId, planName: plan?.planName?.toLowerCase(), planType: "application", image: app?.imageMetaData?.imageUrl ? app.imageMetaData.imageUrl : "https://i.ibb.co/mhTNNDX/DEENCONNECT-Integration-Icon.png"}},
  //       { type: "string", name: "name", value: plan?.planName?.toUpperCase(), initials: "" },
  //       { type: "string", name: "name", value: plan.plan ? plan.plan.toUpperCase() : "" },
  //       { type: "string", name: "category", value: plan.paymentFrequency === "monthly" ? `$${plan.total}/mo` : plan.total ? `$${(parseFloat(plan.total) / 12).toFixed(2)}/mo` : "" },
  //       { type: "string", name: "category", value: capitalizeFLetter(plan.paymentFrequency) ?? "" },
  //       { type: "string", name: "category", value: capitalizeFLetter(planPayment?.payment_method_details?.card?.brand ?? "") },
  //       {
  //         type: "status",
  //         status: "success",
  //         value: "Active",
  //       },
  //       { type: "action", status: "details", value: "see details" }
  //     ])
  //   }
  // });

  
  let object = [
    {
      label: "Applications",
      item: <TabContent1 appRows={appRows} />
    },
    {
      label: "Integrations",
      item: <TabContent2 intRows={intRows} history={props.history} />,
    }
  ];

  const actionLinkHandler = (data) => {
    let details;
      if(data[0]?.plan?.planType === "application") {
        details = apps?.find((el) => el?.name?.toLowerCase() === data[0]?.plan?.planName)
        details = {...details, appType: "application"}
      } else {
        details = integrations?.find((el) => el?.name?.toLowerCase() === data[0]?.plan?.planName.toLowerCase());
        details = {...details, appType: "integration"}
      }

      console.log("id is: ", data[0]?.plan, details);
      props.history.push({pathname: `/plans/details/${props.match.params.id}`, state: { plan: data[0].plan, details: details }});
}


if (loading) {
        pageContent = <Spinner />
  
      }
  return (
    <div>

    <MasterLayoutMiddle
      content={

        <div>
        { loading ? (
          <Spinner />
        ) : (
        <SimpleTable
        showHeader={true}
        showCol={false}
        title="Manage Plans"
        rows={rows}
        columns={col}
        colSpan={16}
        actionSendButton={true}
        classProps={changeClassProps()}
        donationsCount={rows && rows.length > 0 ? 1 : 0}
        noTitle="No Plans added"
        noPara="Added Plans will be displayed and managed here"
        //noBtnText="Invite Organization"
        actionLink={(data) => actionLinkHandler(data)}
        showIconCol={false}
        showFooter={true}
        showAdd={false}
        iconTextSize={16}
        />
        )}
    </div>


        // <div>
        //     <Grid container spacing={4}>	      
        //       <Grid item xs={12}> 
        //           <TopCardsData orgsAndInvitedOrgs={orgsAndInvitedOrgs} />
        //       </Grid>

        //       <Grid item xs={12}> 
        //         <BaseTabs
        //             title=""
        //             object={object}
        //             indicatorColor="#7266fb"
        //             onClick={(x, y) => onClick(x, y)}
        //             onChange={(event, newValue) => handleChange(event, newValue)}
        //             value={value}
        //           />
        //         {pageContent}
        //       </Grid>

        //     </Grid>

        // </div>
      }

    isFooter={true}
    footer={
    <FooterInner style={style}/>
    }
    isWhiteBackground={false}
    />
</div>
  )
}


// class OrgPlan extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       show: true,
//       errors: {},
//       value: 0,
//       loading: false,
//       organizations: [],
//       invitedOrganizations: [],
//       orgsAndInvitedOrgs: {}
//     };
//   }

//   static getDerivedStateFromProps(props, state) {

//     let errors = props.errors;
//     let page = props.page;
//     let organizations = props.organizations ? props.organizations : [];
//     let invitedOrganizations = props.invitedOrganizations ? props.invitedOrganizations : [];
//     let orgsAndInvitedOrgs = props.orgsAndInvitedOrgs ? props.orgsAndInvitedOrgs : [];


//     let stateChanged = false;
//     let changedState = {};

//     if (organizations && JSON.stringify(state.organizations) !== JSON.stringify(organizations)) {
//       changedState.organizations = organizations;
//       stateChanged = true;
//     }

//     if (invitedOrganizations && JSON.stringify(state.invitedOrganizations) !== JSON.stringify(invitedOrganizations)) {
//       changedState.invitedOrganizations = invitedOrganizations;
//       stateChanged = true;
//     }

//     if (orgsAndInvitedOrgs && JSON.stringify(state.orgsAndInvitedOrgs) !== JSON.stringify(orgsAndInvitedOrgs)) {
//       changedState.orgsAndInvitedOrgs = orgsAndInvitedOrgs;
//       stateChanged = true;
//     }

//     if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
//       changedState.errors = errors;
//       stateChanged = true;
//     }

//     if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
//       changedState.loading = page.loading;
//       stateChanged = true;
//     }

//     if (stateChanged) {
//       return changedState;
//     }


//     return null;
//   }
  
//   styles = {};

//   showAlertHandler = () => {
//     console.log("into showAlertHandler");
//     console.log(this.state.show);
//     if (this.state.errors && this.state.errors.message) {
//       this.props.onClearError();
//     }
//     else {
//       this.setState({
//         show: !this.state.show
//       });
//     }

//   }

//   onClick = (x, y) => {
//     console.log('checking onClick function: ', x)
//     console.log('checking onClick function Y:', y)
//     if (y === 1) {
//       this.props.onGetInvitedOrganizations();
//       this.setState({ show: true });
//     }
//     else {
//       this.props.onGetOrganizations();
//       this.setState({ show: false });
//     }
//   }

//   handleChange = (event, newValue) => {
//     this.setState({ value: newValue });
//   };


//   componentDidMount() {
//     const {location, match } = this.props;
//     if(match && match.url === "/dashboard" && location && location.search) {
//       var escapedString = location.search.replace("?", "").split("=")[1];
//       if(escapedString === "1" || escapedString === "0" ) {
//         this.props.onGetInvitedOrganizations();
//         this.setState({value: parseInt(escapedString)})
//       }
//     }
    
//     this.props.onClearError();
//     this.props.onGetOrganizations();
//     this.props.onGetOrganizationsCardData();
//   }



//   render() {

//     let pageContent = '';
//     let message = 'Invite sent successfully! ';
//     let type = 'success';
//     let styles = this.styles;

//     let { errors, loading, organizations, invitedOrganizations, orgsAndInvitedOrgs, show } = this.state;


//     if (loading) {
//       pageContent = <Spinner />

//     }
//     if (errors && errors.message) {
//       message = errors.message;
//       type = 'error';
//       show = true;
//     }

//     let object = [
//       {
//         label: "Applications",
//         item: <TabContent1 organizations={organizations} history={this.props.history} />
//       },
//       {
//         label: "Integrations",
//         item: <TabContent2 invitedOrganizations={invitedOrganizations} history={this.props.history} />,
//       }
//     ];

//     const style = {
//       width:'100%',
//     };

//     return (
//       <div>

//           <MasterLayoutMiddle

//             content={

//               <div>

//                   <Grid container spacing={4}>	      

//                     <Grid item xs={12}> 
//                         <TopCardsData orgsAndInvitedOrgs={orgsAndInvitedOrgs} />
//                     </Grid>

//                     <Grid item xs={12}> 
//                       <BaseTabs
//                           title=""
//                           object={object}
//                           indicatorColor="#7266fb"
//                           onClick={(x, y) => this.onClick(x, y)}
//                           onChange={(event, newValue) => this.handleChange(event, newValue)}
//                           value={this.state.value}
//                         />
//                       {pageContent}
//                     </Grid>

//                   </Grid>

//               </div>
//             }

//           isFooter={true}
//           footer={
//           <FooterInner style={style}/>
//           }
//           isWhiteBackground={false}
//           />
//       </div>
//     )
//   }
// }

const mapStateToProps = state => {
  return {
    organizations: state.organizations.organizations,
    invitedOrganizations: state.organizations.invitedOrganizations,
    orgsAndInvitedOrgs: state.organizations.orgsAndInvitedOrgs,
    page: state.page,
    errors: state.errors,
    showMessage: state.errors.showMessage
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOrganizations: () => dispatch(actions.getAllOrganization()),
    onGetInvitedOrganizations: () => dispatch(actions.getAllInvitedOrganizations()),
    onGetOrganizationsCardData: () => dispatch(actions.getOrganizationsCardData()),
    onClearError: () => dispatch(actions.clearErrors())
  }
};


export default connect(mapStateToProps, mapDispatchToProps)(OrgPlan);


