import React, { useEffect, useState } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SimpleTable, MasterLayoutMiddle, CardDetailsTable, FooterInner, BaseButton, } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";
import { stateAbbreviations } from "../../utils/statesAbbreviations";



import './style.css'
import Moment from 'moment';



const useStyles = makeStyles((theme) => ({}));

function OrderDetails(props) {
  const { userDetails } = props;
  const [orderData, setOrderData] = useState({});
  const [shippingCost, setShippingCost] = useState(0);
  const [rows, setRows] = useState([]);
  const [returnUrl, setReturnUrl] = useState('/order');

  const getstatus = (status) => {
    const temp = status?.toLowerCase();
    
    switch(temp) {
      case 'completed':
      case 'paid':
      case 'succeeded':
        return 'success';

      case 'cancelled':
      case 'canceled':
      
        return 'failed';

      case 'in-progress':
      case 'pending':
      case 'shipped':

        return 'pending';
      
      case 'new':
        return 'invite'

      case 'on-hold':
        return 'pause'

      default: 
        return 'invite'
    }
  }


  useEffect(() => {
    props?.location?.state?.returnUrl && setReturnUrl(props.location.state.returnUrl);
    if(props?.location?.state?.orderData) {
      setOrderData(props.location.state.orderData);
      console.log("props.location.state.orderData: ", props.location.state.orderData);
      const tempArray = props.location.state.orderData.orderItems.map((text, index) => {
        console.log("Each item is: ", text);
        return [
          { type: "string", name: "name", value: `${text.name.toUpperCase()}` + (text.name?.toLowerCase()?.indexOf('hardware') === -1 ? '-' + capitalizeFLetter(text.planType): '' ), id: text.id},
          {
            type: 'link',
            name: 'invoice_number',
            value: text?.receipt_number ? text?.receipt_number?.replace('#', '') :  'Check Receipt' ,
            url: text.receipt_url,
            linkType: "external"
          },          
          { type: "string", name: "frequency", value: text.qty, },
          { type: "string", name: "frequency", value: `$${parseInt(text.amount/ 100)}${text.frequency === "monthly" ? '/mo' : text.frequency === 'annually' ? '/yr' :'' }` },
          { type: 'string', name: 'frequency', value: text?.payment_method },
          { type: 'status', status: getstatus(text.payment_status === 'succeeded' ? 'Paid' : 'Pending'), value: text.payment_status === 'succeeded' ? 'Paid' : 'Pending' },
          { type: 'status', status: getstatus(text.status), value: text.status },
          { type: "action", status: "success", value: "Manage" },
          { type: "action", status: "success", value: "ASSIGN" },
          ]
      });
      setRows(tempArray);

    };

  }, [])

  // ============================== Table Start ======================================//


  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const capitalizeFLetter = (data) => {
    return data[0].toUpperCase() + data.slice(1);
  };

  const onClickManageOrder = () => {
    props.history.push({pathname: '/order/manage-order', state: {orderData: orderData, returnUrl: returnUrl}})
  }

  let col = [
    { type: "string", name: "name", label: "Name", width: "25%" },
    { type: "string", name: "category", label: "INVOICE# ", width: '15%' },
    { type: "string", name: "name", label: "QTY", width: "10%" },
    { type: "string", name: "name", label: "Price", width: "15%" },
    { type: "string", name: "name", label: "PAYMENT METHOD", width: "20%" },
    { type: "string", name: "name", label: "PAYMENT", width: "20%" },
    { type: "status", name: "status", label: "FULFILLMENT", width: "10%" },
    { type: "action", name: "action", label: "", width: "10%" },
    { type: "action", name: "action", label: "", width: "10%" },
  ];


  // ============================== Table End ======================================//


  
  // ============================== Card Details Start ===============================//
  let header = { tableName: `Order Details`, };

  let data = [

    {
      field: "Order #",
      value: `<a >${orderData.orderNo}</a>`
    },

    {
      field: "Subtotal",
      value: `$${(orderData.totalAmount / 100).toFixed(2)}`
    },

    {
      field: "Email",
      value: orderData?.email ??  "<span style = 'color: growsray'>No Data</span>"
    },    

    {
      field: "Date",
      value: orderData?.createdAt ? Moment(orderData?.createdAt).format("MMM DD, YYYY") : "<span style = 'color: gray'>No Data</span>"
    },

    {
      field: "Shipping Cost",
      value: `$${shippingCost.toFixed(2)}`
    },


    {
      field: "Phone",
      value: orderData?.orgId?.phoneFax?.primaryPhone ? orderData.orgId.phoneFax.primaryPhone : "<span style = 'color: gray'>No Data</span>"
    },

    {
      field: "Organization",
      value: orderData?.orgId?.orgName ? `<a>${orderData.orgId.orgName}</a>` : "<span style = 'color: gray'>No Data</span>"
    },

    {
      field: "Discount",
      value: '$0'
    },

    {
      field: "Shipping",
      value: orderData.shippingAddress?.line1 + " " + orderData.shippingAddress?.city + ", " + (stateAbbreviations[orderData.orgId?.orgAddress?.state] ? stateAbbreviations[orderData.orgId?.orgAddress?.state] : orderData.orgId?.orgAddress?.state) + " " + orderData.shippingAddress?.postalCode
    },

    {
      field: "Address",
      value: orderData.orgId?.orgAddress?.city + ', ' + (stateAbbreviations[orderData.orgId?.orgAddress?.state] ? stateAbbreviations[orderData.orgId?.orgAddress?.state] : orderData.orgId?.orgAddress?.state)
    },

    {
      field: "Total",
      value: `$${(orderData.totalAmount / 100).toFixed(2)}`
    },


   
    {
      field: "",
      value: " "
    },
   
    {
      field: "Payment",
      value: orderData?.status ?? '<span className="ondisableText">None</span>'
    },

    {
      field: "Promo Applied",
      // value: "<a href='#'>EID2021</a>"
      value: '<span className="ondisableText">None</span>'
    },

    {
      field: "",
      value: " "
    },
    {
      field: "Method",
      value: orderData?.paymentMethod ?? '<span className="ondisableText">None</span>'
    },

  ];

  // let footerLinkName = "Manage order";
  // let footerDeleteButton = "invoice";



  const assignClicked = (data) => {
    if(data?.[0]?.id) {
      const tempObj = {
        orderId: orderData._id,
        itemName: data[0].value,
        itemId: data[0].id
      };
  
      props.history.push ({
        pathname: '/order/order-form',
        state: tempObj
      });
    }
  }
  // ============================== Card Details END ===============================//

  const classes = useStyles();

  const style = {
    width:'100%',
    background: "#f7f8fa",
  };

  return (
    <MasterLayoutMiddle

      header={
        <div className="backBtnFrom">
          <BaseButton
            onClick={() => props.history.push(returnUrl)}
            text="back"
            textStyle={styles.txt}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            style={styles.backBtn}
            startIcon={<ChevronLeftIcon />}
          />
        </div>
      }
      content={
        <div style={styles.Container}>

          <Grid container spacing={3}>


            <Grid item xs={12} >
              <CardDetailsTable
                data={data}
                header={header}
                // footerLinkName={footerLinkName}
                // footerDeleteButton={footerDeleteButton}
                onClick={() => onClickManageOrder()}
                //onDeleteClick={() => onDeleteClick()}

                Status={true}
                StatusLabel="Status"
                StatusText={orderData.status}
                statuscolor={orderData.status?.toLowerCase() === 'completed' ?"#3CC47C" : "#7266FB"}
              />

            </Grid>

            <Grid item xs={12} >
              <div className="table_box">
                <SimpleTable
                  showHeader={true}
                  title="Items"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={1}
                  noTitle=""
                  noPara=""
                  noBtnText=""
                  onInviteClick={() =>
                    this.props.history.push(
                      "/add-new-campaign"
                    )
                  }
                  actionLink={(name) => assignClicked(name)}
                  showIconCol={false}
                  actionSendButton={true}
                  //editIcon={Edit}
                  //deleteIcon={Delete}
                  editIcon={CreateIcon}
                  deleteIcon={DeleteIcon}
                  //editAction = {(e) => this.editService(e)}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={true}
                  showAdd={false}
                  addText="ADD NEW"
                  onAddClick={() =>
                    this.props.history.push(
                      "/add-new-campaign"
                    )
                  }
                  iconTextSize={16}
                />
              </div>
            </Grid>

          </Grid>

        </div>
      }

      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}

let styles = {
  txt: {
    color: '#4D4D4D ',
    letterSpacing: '1.26px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '15px'
  },

  backBtn: {
    backgroundColor: 'transparent',
    borderRadius: '18px',
    color: '#4D4D4D',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    width: '86px',
    height: '36px',
  },

  Container: {
    marginTop: "60px",
  }

}

export default OrderDetails