import React from "react";
import { SimpleOtp } from "@dclab/dc-react-ui-kit";

import {
  MasterLayoutMiddle,
  FooterInner,
  BaseStepper,
  BaseButton,
} from "@dclab/dc-react-ui-kit";
import axios from "axios";
import "./onboarding.css";
import { ButtonOne } from "master-react-lib";
import OwnerDetails from "./OwnerDetails";
import OrgDetails from "./OrgDetails";
import Team from "./Team";
import Plans from "./Plans";
import Payment from "./Payment";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
let backendServerURL =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;
let routesPrefix = "/";

function Index(props) {
  const [teams, setTeams] = React.useState([]);
  const [value, setValue] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [ownerDetails, setOwnerDetails] = React.useState({});
  const [orgData, setOrgData] = React.useState({});
  const [paymentData, setPaymentData] = React.useState({
    integrationsName: [],
    integrationsData: [],
    dfprice: {},
    dwprice: {},
    dkprice: {},
  });
  const getOwnerData = (data) => {
    setOwnerDetails(data);
  };
  const getOrgData = (data) => {
    console.log("inside index", data);
    setOrgData(data);
  };

  const getPrice = (
    integrationsName,
    integrationsData,
    dfprice,
    dwprice,
    dkprice
  ) => {
    setPaymentData({
      integrationsName,
      integrationsData,
      dfprice,
      dwprice,
      dkprice,
    });
    console.log(
      "inside index",
      integrationsName,
      integrationsData,
      dfprice,
      dwprice,
      dkprice
    );
  };
  const [activeStep, setActiveStep] = React.useState(0);
  const getSteps = () => {
    return [
      {
        number: 1,
        label: "Owner Details",
        value: (
          <div className="StepperFormContainer">
            <div className="StepperFormBdy">
              <OwnerDetails callback={getOwnerData} />
            </div>
          </div>
        ),
      },

      {
        number: 2,
        label: "Org Details",
        value: (
          <div className="StepperFormContainer">
            <div className="StepperFormBdy">
              <OrgDetails callback={getOrgData} />
            </div>
          </div>
        ),
      },

      {
        number: 3,
        label: "Team",
        value: (
          <div className="StepperFormContainer">
            <div className="StepperTableBdy">
              <Team teamMembers={teams} callback={getTeams} />
            </div>
          </div>
        ),
      },

      {
        number: 4,
        label: "Plans",
        value: (
          <div className="StepperFormContainer">
            <div className="StepperTableBdy">
              <Plans callback={getPrice} />
            </div>
          </div>
        ),
      },

      {
        number: 5,
        label: "Payment",
        value: (
          <div className="StepperFormContainer">
            <div className="StepperTableBdy">
              <Payment planData={paymentData} />
            </div>
          </div>
        ),
      },
    ];
  };
  const continueBtnClick = () => {
    console.log("Back Button Clicked");
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const confirmHandler = () => {
    setOpen(false);
    ownerDetail(value);
    console.log(value);
  };
  const click = (e) => {
    e.preventDefault();
    console.log(`verify`);
  };
  const resend = () => {
    console.log(`resend`);
  };
  const onBlurAction = () => {
    console.log("onBlurAction");
  };
  const getTeams = async () => {
    try {
      const response = await axios.get(backendServerURL + "/team-members", {
        headers: { token: localStorage.getItem("authToken2") },
      });
      if (response) {
        setTeams(response?.data?.body?.data);
        console.log("TEAMS", response);
        return response;
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  async function ownerDetail(otp) {
    // const otp = value;
    if (otp) {
      const resp = await axios.post(backendServerURL + "/auth/verify", {
        userName: ownerDetails.userName,
        code: otp,
      });
      if (resp) {
        const authnticate = await axios.post(
          backendServerURL + "/auth/authenticate",
          {
            userName: ownerDetails.userName,
            password: ownerDetails.password,
          }
        );
        if (authnticate) {
          console.log(
            "YOUR ORG ID IS ",
            authnticate?.data?.body?.data?.defaultOrg
          );
          localStorage.setItem(
            "OrgId",
            authnticate?.data?.body?.data?.defaultOrg
          );
          localStorage.setItem(
            "authToken2",
            authnticate?.data?.body?.data?.token
          );

          const planSubscription = await axios.get(
            backendServerURL +
              "/planSubscription/getPlanSubscriptionsByOrgId/" +
              localStorage.getItem("OrgId"),
            { headers: { token: localStorage.getItem("authToken2") } }
          );
          if (planSubscription) {
            console.log("plansubsciption", planSubscription);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      }
    }
  }
  const handleNext = async () => {
    if (activeStep === 0) {
      try {
        const response = await axios.post(
          backendServerURL + "/auth/register",
          {
            name: ownerDetails.firstName + " " + ownerDetails.lastName,
            phoneNo: ownerDetails.phoneNo,
            userName: ownerDetails.userName,
            password: ownerDetails.password,
          },
          { headers: { token: 196549 } }
        );
        console.log(response);
        if (response) {
          setOpen(true);
        }
      } catch (error) {
        console.log("ERROR MESSAGE", error.message);
      }
    } else if (activeStep === 1) {
      try {
        const response = await axios.put(
          backendServerURL + `/organizations/${localStorage.getItem("OrgId")}`,
          {
            orgAddress: orgData.orgAddress,
            orgName: orgData.orgName,
            orgType: orgData.orgType,
            taxIdStatus: { taxExampt: false, taxIdEin: "" },
          },
          { headers: { token: localStorage.getItem("authToken2") } }
        );
        if (response) {
          const orgnization = await axios.get(
            backendServerURL + `/organizations/${localStorage.getItem("OrgId")}`
          );
          if (orgnization) {
            const resp = getTeams();
            if (resp) setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    } else if (activeStep === 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const handleBackPreviousPage = () => {
    console.log("BackButton");
  };
  const styles = {
    stepper: {
      marginTop: 120,
      padding: "8px 20px",
      borderRadius: "30px",
    },
  };

  const handlerPayment = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handlePayLater = () => {};
  const handleCheckout = () => {};
  return (
    <div className="onboardFormContainer">
      <React.Fragment>
        <MasterLayoutMiddle
          header={
            <div className="HikmahSteperContainer">
              <BaseStepper
                title="Onboard Organization"
                // subTitle="Add details of your organization to start connecting with Muslims all over the world."
                getSteps={getSteps}
                alternativeLabel={true}
                handleBack={() => handleBack()}
                handleNext={() => handleNext()}
                handleBackPreviousPage={() => handleBackPreviousPage()}
                nonLinear={true}
                bottomLine={false}
                backButton={true}
                backgroundColor={"transparent"}
                orientation={"horizontal"}
                btnStyles={styles.stepper}
                activeStep={activeStep}
              />
            </div>
          }
          isWhiteBackground={true}
        />
        {/* modal          */}
        <div className="OtpModalContainer">
          {/* <button type="button" onClick={handleOpen}>
            OTP MODAL BUTTON APPLY WITH CONTINUE BUTTON
          </button> */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
            className="OtpModalInner"
          >
            <Fade in={open}>
              <div className="OtpModalBody">
                <div className="OtpModalTitle">
                  <h2 className="">Verify your email address</h2>
                  <CloseRoundedIcon
                    className="closeIcon"
                    onClick={() => handleClose()}
                  />
                </div>
                <div className="ModalOtpForm">
                  <SimpleOtp
                    name="verifyCode"
                    //title="Verification"
                    metaTitle="We just sent an email to with a code. Enter it to verify your email address."
                    email="someone@email.com"
                    btnText="Verify"
                    forVerify={true}
                    onBlur={() => onBlurAction()}
                    value={value}
                    onChangeCode={(char) => setValue(char)}
                    onChangeText={(e) => setValue(e.target.value)}
                    onClickVerify={(e) => click(e)}
                    onClickResend={resend}
                    hasErrored={false}
                    errorStyle={{
                      border: "1px solid #B00020",
                    }}
                    textfieldvalue={value}
                    required={true}
                    id={"useremail"}
                    label={"Email Address"}
                    placeholder={"email"}
                    type={"email"}
                    error={false}
                    helperText={""}
                    ResendHelperText="Didn't receive code yet?"
                    ResendCode="Send again"
                    ButtonStatus={false}
                  />
                </div>

                <div className="OtpModalFooter">
                  <Button
                    variant="contained"
                    color="primary"
                    className="btLight"
                  >
                    CLOSE
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className="btnBlue"
                    onClick={confirmHandler}
                  >
                    CONFIRM
                  </Button>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
        {/*              modal                    */}
        <div className="OnboardFormButton">
          <div className="OnboardFormButtonCtn">
            <ButtonOne
              label="BACK"
              onClick={handleBack}
              buttoncolor="#fff"
              width="auto"
              height="40px"
              border="#ccc 0px solid"
              borderRadius="100px"
              color="#4d4d4d"
              fontWeight="600"
            />
            {activeStep === 3 ? (
              <>
                <ButtonOne
                  label="PAY LATER"
                  onClick={handlePayLater}
                  buttoncolor="#FFF"
                  width="auto"
                  height="40px"
                  border="#2490F9 1px solid"
                  borderRadius="100px"
                  color="#2490F9"
                  fontWeight="600"
                />
                <ButtonOne
                  label="CONTINUE PAYMENT"
                  onClick={handleNext}
                  buttoncolor="#2490F9"
                  width="auto"
                  height="40px"
                  border="#ccc 0px solid"
                  borderRadius="100px"
                  color="#fff"
                  fontWeight="600"
                  //textAlign="center"
                  //disabled="disabled"
                />
              </>
            ) : activeStep === 4 ? (
              <ButtonOne
                label="continue to checkout"
                onClick={handleCheckout}
                buttoncolor="#2490F9"
                width="auto"
                height="40px"
                border="#ccc 0px solid"
                borderRadius="100px"
                color="#fff"
                fontWeight="600"
                //textAlign="center"
                //disabled="disabled"
              />
            ) : (
              <ButtonOne
                label="CONTINUE"
                onClick={handleNext}
                buttoncolor="#2490F9"
                width="auto"
                height="40px"
                border="#ccc 0px solid"
                borderRadius="100px"
                color="#fff"
                fontWeight="600"
                //textAlign="center"
                //disabled="disabled"
              />
            )}
          </div>
        </div>
      </React.Fragment>
    </div>
  );
}

export default Index;
