import React, {useState, useEffect} from "react";
import Grid from '@material-ui/core/Grid';
import { SimpleTable,  MasterLayoutMiddle, FooterInner, AlertAction  } from "@dclab/dc-react-ui-kit";
import { GetContacts } from "./Messages/api";
import './style.css';
import Moment from 'moment';
import * as actions from '../../store/Actions/index';
import { connect } from 'react-redux';
import Spinner from '../../components/common/Spinner';




function ContactUs(props) {
  //const [rows, setRows] = React.useState([])
  const [loader, setLoader] = React.useState(true)
  const [error, seterror] = React.useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const {allContacts} = props;


  const checkStatus = (status) => {
    status = status ? status.toLowerCase() : "";
    if (status === 'new') {
      return "invite"
    } else if (status === 'in-progress'){
      return "discontinued"
    } else if (status === 'closed'){
      return "failed"
    } else if (status === 'done'){
      return "success"
    } else {
      return "discontinued"
    }
 }

 useEffect(() => {
  props.onGetAllContacts();
  if(props?.location?.state?.contactDeleted) {
    setshowAlert(true);
    setTimeout(() => {
      setshowAlert(false)
    }, 8000);
  }
}, [])
  
  // useEffect(() => {
  //   getContacts();
  //   if(props?.location?.state?.showMessageAlert) {
  //     setshowAlert(true);
  //     setTimeout(() => {
  //       setshowAlert(false)
  //     }, 8000);
  //   }
  // }, [])


  
  // const getContacts = async () => {
  //   const token = localStorage.getItem("authToken");
  //   const orgId = localStorage.getItem('orgID')
  //   const LocalName = localStorage.getItem('name')
  //   try {
  //     const response = await GetContacts(token, orgId);
  //     const contacts = response.data.body.data;
  //     let array = []
  //     array = contacts.map((text, index) => {
  //       return ([
  //         { type: 'id', value: index + 1, data: { ...text } },
  //         { type: "string", name: "frequency", value: (text?.name?.firstName ?? "") + (text?.name?.lastName ? ' ' + text.name.lastName : ''), _id: text._id },
  //         { type: "string", name: "frequency", value: text?.email ?? "" },
  //         { type: "string", name: "frequency", value: text?.stream ?? "" },
  //         { type: 'string', name: 'date', value:  Moment(text.updatedAt).format('lll') },
  //         { type: 'status', status: checkStatus(text?.status ?? "new"), value: text?.status ?? "New" },
  //         { type: "action", status: "success", value: "View Details" },
  //       ])
  //     })
  //     setLoader(false)
  //     setRows(array);
  //   } catch (error) {
  //     setLoader(false)
  //     seterror(error.response && error.response.data.message)
  //     setTimeout(() => {
  //       seterror(false)
  //     }, 8000);
  //   }
  // }
  
  // ============================== Table Start ======================================//
  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };
  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  let col = [
    { id: 'NO', label: 'NO.', width: 'auto' },
    { type: "string", name: "name", label: "Name", width: "20%" }, 
    { type: "string", name: "name", label: "Email", width: "22%" },
    { type: "string", name: "name", label: "App Stream", width: "20%" },
    { type: "string", name: "name", label: "Date", width: "20%" },
    { type: "string", name: "status", label: "Status", width: '10%' },
    { type: "action", name: "action", label: "ACTION", width: "10%" },
  ];
  let rows = [];
  if(allContacts && allContacts.length) { 
    rows = allContacts.map((text, index) => {
      return ([
        { type: 'id', value: index + 1, data: { ...text } },
        { type: "string", name: "frequency", value: (text?.name?.firstName ?? "") + (text?.name?.lastName ? ' ' + text.name.lastName : ''), _id: text._id },
        { type: "string", name: "frequency", value: text?.email ?? ""},
        { type: "string", name: "frequency", value: text.stream ? text?.stream === "DCWEBSITE" ? "DC WEBSITE" : text.stream   : ''},
        { type: 'string', name: 'date', value:  Moment(text.createdAt).format("MMM DD, yyyy, hh:mm A") },
        { type: 'status', status: checkStatus(text?.status ?? "new"), value: text?.status ?? "New" },
        { type: "action", status: "success", value: "View Details" },
      ])
    })
  }
  // ============================== Table End ======================================//
  // ============================== Style Start ===============================//
  
  // ============================== Style END ===============================//
  const style = {
    width:'100%',
    background: "#fff",
  };
  return (
    <MasterLayoutMiddle

    header={
      <>
        {showAlert && (
              <div className="header_alert">
                <AlertAction
                  alert={"Inquiry deleted successfully!"}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )}

      </>
    }
      content={
        <div>
            
            <Grid container spacing={3} >
            {props.page && props.page.loading && <Spinner />}
            <Grid item md={12} sm={12} xs={12} >
              
                <div className="table_box">
                  <SimpleTable
                    showHeader={true}
                    title="Contact Us"
                    width="100%"
                    rows={rows}
                    columns={col}
                    colSpan={16}
                    actionSendButton={true}
                    classProps={changeClassProps()}
                    donationsCount={rows && rows.length > 0 ? 1 : 0}
                    noTitle="No inquiry submitted yet"
                    noPara="Inquiries submitted by visitors will be visible and managed here."
                    noBtnText=""
                    // onInviteClick={() =>
                    //   props.history.push(
                    //     "/communication/contact-us-details"
                    //   )
                    // }
                    actionLink={(name) => props.history.push({pathname: "/communication/contact-us-details", state: {contact: name[0].data} })}
                    showIconCol={false}
                    actionSendButton={true}
                    deleteAction={(e) => console.log(e)}
                    showFooter={true}
                    showAdd={false}
                    addText="ADD NEW"
                    onAddClick={() =>
                      props.history.push(
                        "/communication/contact-us-details"
                      )
                    }
                    iconTextSize={16}
                  /> 
                </div>
              </Grid>
            </Grid>
        
         
            
     
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={true}
    />
  );
}


const mapStateToProps = state => {
  return {
    allContacts: state.contacts.allContacts,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetAllContacts: () => dispatch(actions.getAllContacts()),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);