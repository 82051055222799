import React, {useEffect, useState} from "react";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import { BaseTextField, BaseButton, BaseProfileInfo, BaseSelect, Initials, MasterLayoutMiddle, FooterInner, AvannceInfoChange } from "@dclab/dc-react-ui-kit";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { Link } from "react-router-dom";
import ChevronLeft from "@material-ui/icons/ChevronLeft";

import { GridContainer, GridItem,CardData,} from "@dclab/dc-react-ui-kit";

import './style.css'

const useStyles = makeStyles((theme) => ({

  formBody:{
    background:'#fff',
  },

  formTitle: {
    fontSize: '22px !important',
    color:'#5A5A5A',
  },

  formTitleSub:{
    fontSize: 14,
    color:'#818E94',
  },

  dottedBorder:{
    border: '#1px dashed #D3D8DA',
  },

  pageTitle:{
    color:'#5A5A5A',
    fontSize:'28px !important',
    paddingBottom:'20px',
    textAlign:'center',
  },
  advanceHeading:{
    fontSize:'16px !important',
    color:'#4D4D4D !important',
    fontWeight:'700 !important',
  },

  advanceDescription:{
    fontSize:'14px !important',
    color:'#818E94 !important',
    fontWeight:'400 !important',
  }

 }));

function EditPofile(props) {
  // console.log("currentUser: ", props.userDetails)
  const {userDetails} = props;

  const [currentUser, setcurrentUser] = useState({
    userName: "", firstName: "", lastName: "", phoneNo: "", image: "", line1: "", city: "", state: "", postalCode: ""
  })

  const [preview, setPreview] = useState()

  useEffect(() => {
    props.onGetCurrentUser();
  }, [])

  useEffect(() => {
    setcurrentUser({
      firstName: userDetails && userDetails.name && userDetails.name.firstName ? userDetails.name.firstName : "",
      lastName: userDetails && userDetails.name && userDetails.name.lastName ? userDetails.name.lastName : "",
      userName: userDetails && userDetails.userName ? userDetails.userName : "",
      phoneNo: userDetails && userDetails.phoneNo ? userDetails.phoneNo : "",
      image: userDetails && userDetails.imageDetails && userDetails.imageDetails.image ? userDetails.imageDetails.image : "",
      line1: userDetails && userDetails.address && userDetails.address.line1 ? userDetails.address.line1 : "",
      city: userDetails && userDetails.address && userDetails.address.city ? userDetails.address.city : "",
      state: userDetails && userDetails.address &&  userDetails.address.state ? userDetails.address.state : "",
      postalCode: userDetails && userDetails.address && userDetails.address.postalCode ? userDetails.address.postalCode : "",
    })
  }, [userDetails])

  let onSubmit = (e) => {
    e.preventDefault();
    const {userName, firstName, lastName, line1, city, state, postalCode} = currentUser;

      let formData = { 
        ...currentUser, 
        userName, 
        name: {firstName, lastName}, 
        address: {line1, line2: "", city, state, postalCode, country: ""},
        imageDetails: { image }
      };

      props.onEditProfile(userDetails._id, formData)
}

const [activeStep, setActiveStep] = React.useState(0);
  const getSteps = () => {
    return [
      { number: 1, label: "Organizations Details", value: "ValuesFrom Step 1" },
      { number: 2, label: "Select Apps", value: <b>Value from Step 2</b> },
    ];
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };


  // const [values, setValues] = React.useState({
  //   age: "",
  // });

  const handleChange = (prop) => (event) => {
    setcurrentUser({ ...currentUser, [prop]: event.target.value });
  };

  const object = [
    {
      value: "NY",
      label: "NY",
    }
  ];


  const [value, setValue] = useState([{type:'paragraph',children:[{text:''}]}]);
  const onChange = (e) => {
      setValue(e)
  };

  const styles = {
    stepper: {
      marginTop: 0,
      padding: "0px 0px",
      borderRadius: "30px",
    },

    select:{
      minWidth: 120,
      height: 66,
      width: 120,
      border: "#D3D8DA",
      width:"100%",
  },

  BaseTextField:{
    width: '100%',
  }
  };

  const onclick=() => { 
    console.log("edit details");
  }

  const classes = useStyles();

  const style = {
    width:'100%',
    background: "#fff",
  };

  let fileUploadButton = () => {
    document.getElementById('fileButton').click();
    document.getElementById('fileButton').onchange = (e) => {  
      onFileChange(e)
    }
  }

  function onFileChange(event) {
    var file = event.target.files[0];
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreview(objectUrl)

    var reader = new FileReader();
    let encodeImage = reader.readAsDataURL(file)
    // console.log("encoded: ", encodeImage)

    reader.onload = function(event) {
      // console.log("result: ", reader.result)
      setcurrentUser({...currentUser, image: reader.result});

    };
}

  const removeImage = () => {
      setcurrentUser({...currentUser, image: ""});
      setPreview("")
  }

  const {userName, firstName, lastName, phoneNo, line1, city, state, postalCode, image} = currentUser;
  
  return (
    <MasterLayoutMiddle
    header={
      <div className="backBtn">
        <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
        <Link
          to={{ pathname: "/admin-profile", state: 1,}}
          style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
      </div>
    }

    content ={
      <div className="adminProfile_container">
        <GridContainer spacing={3} direction="row">
          <Grid item md={3} xs={12}>
            

              <div className="edit_profile_photo">
                <div>
                  {image || preview ? <BaseProfileInfo className="base-profile-img" alt="" membership="" src={image ? image : preview} /> : firstName || lastName ? <div className="initials-wrapper"><Initials name={`${firstName} ${lastName}`} baseAvatarInitials={true} /></div> : null }
                </div>
              
                <div>
                  <input id="fileButton" type="file" onChange={(event) => onFileChange(event)}  hidden/>
                  {/* onClick={fileUploadButton} */}
                  <BaseButton text="Upload photo" onClick={fileUploadButton} />
                  {/* {image} */}
                </div>
                <p className="remove_photo" onClick={removeImage}>remove</p>
              </div>
          </Grid>


        <Grid item md={6} xs={6}>

          <Grid item md={12}>
            <h1 className='title_top'>Edit Profile Information</h1>
            <div className="dasheddBorder"></div>
          </Grid>

          <Grid container spacing={3}>

            <Grid item xs={12}>
              <h2 className="sub_title">Basic Information</h2>
            </Grid>

            
            <Grid item md={6}>
            <div className="field_box">
              <BaseTextField
                label={"First Name"}
                value={firstName}
                onChange={handleChange("firstName")}
                InputProps={true}
                phone={false}
                search={false}
                //count={100}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={false}
                ////helperText="helper text"
                //type="search"
              />
              </div>
            </Grid>

            <Grid item md={6}>
            <div className="field_box">
              <BaseTextField
                label={"Last Name"}
                value={lastName}
                onChange={handleChange("lastName")}
                InputProps={true}
                phone={false}
                search={false}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={false}
               // //helperText="helper text"
              />
              </div>
            </Grid>


            <Grid item md={12}>
            <div className="field_box">
              <BaseTextField
                label={"Mobile Number"}
                value={phoneNo}
                onChange={handleChange("phoneNo")}
                InputProps={true}
                phone={false}
                search={false}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={false}
               // //helperText="helper text"
                //type="search"
              />
              </div>
            </Grid>

            <Grid item md={12}>
            <div className="field_box">
              <BaseTextField
                label={"Email Address"}
                value={userName}
                onChange={handleChange("userName")}
                InputProps={true}
                phone={false}
                search={false}
                //count={100}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={true}
               // //helperText="helper text"
                //type="search"
              />
              </div>
            </Grid>

            <Grid item md={12}>
            <div className="field_box">
              <BaseTextField
                label={"Street Address"}
                value={line1}
                onChange={handleChange("line1")}
                InputProps={true}
                phone={false}
                search={false}
                //count={100}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={false}
                ////helperText="helper text"
                //type="search"
              />
              </div>
            </Grid>

            <Grid item md={4}>
            <div className="field_box">
              <BaseTextField
                label={"City"}
                value={city}
                onChange={handleChange("city")}
                InputProps={true}
                phone={false}
                search={false}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={false}
               // //helperText="helper text"
              />
              </div>
            </Grid>

            <Grid item md={4}>
            <div className="field_box">
              <BaseSelect
                label={"State"}
                value={state}
                onChange={handleChange("state")}
                object={object}
                ////helperText="helper text"
              />
              </div>
            </Grid>

            <Grid item md={4}>
            <div className="field_box">
              <BaseTextField
                label={"Zip/Postal Code"}
                value={postalCode}
                onChange={handleChange("postalCode")}
                InputProps={true}
                phone={true}
                search={false}
                color="primary"
                onClick={() => console.log("onCLick")}
                onBlur={() => console.log("onBlur")}
                disabled={false}
               // //helperText="helper text"
              />
              </div>
            </Grid>


          <Grid item md={12} className="buttons">
            <span className="btnCencel" onClick={() => props.history.push("/admin-profile")}><BaseButton text="CANCEL" /></span>
            <BaseButton text="UPDATE" onClick={onSubmit} />
          </Grid>

           <Grid item md={12}>
            {/* <div className="dasheddBorder"></div>
              <AvannceInfoChange 
                  showMainTitle={true}
                  mainTitle="Advance"
                  Title="Change email"
                  description="You have to confirm your password to change email."
                  changeNow={() => onclick()}
              /> */}

            <div className="dasheddBorder"></div>

              <AvannceInfoChange 
                  showMainTitle={false}
                  mainTitle="Advance"
                  Title="Change password"
                  description="Changing password is easy click Change now and follow the steps."
                  changeNow={() => props.history.push("/change-password")}
              />

          </Grid> 
          </Grid>
        </Grid>
  </GridContainer>
      </div>
      }

    isFooter={true}
    footer={
      <FooterInner style={style}/>
    }
    isWhiteBackground={true}
    />
  );
}

const mapStateToProps = state => {
  return {
     userDetails: state.auth.getCurrentUserDetails,
     auth: state.auth,
     page: state.page,
     errors: state.errors
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      onGetCurrentUser: () => dispatch(actions.getCurrentUserDetail()),
      onEditProfile: (id, user) => dispatch(actions.editProfile(id, user, ownProps)),
      onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPofile);