import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { SimpleTable, MasterLayoutMiddle, CardDetailsTable, FooterInner, BaseButton } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from "../../assets/images/create-24px.svg";
import DeleteIcon from "../../assets/images/delete_forever-24px.svg";


import './style.css'



const useStyles = makeStyles((theme) => ({}));

function CampaignDetails(props) {
  const { userDetails } = props;

  // ============================== Table Start ======================================//


  let classProps = () => {
    const tempArray = [];
    col.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    const tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  let col = [
    { type: "string", name: "name", label: "CUSTOMER NAME", width: "15%" },
    { type: "string", name: "name", label: "CUSTOMER EMAIL", width: "55%" }, 
    { type: "string", name: "name", label: "DATE", width: "23%" },
    { type: "status", name: "status", label: "STATUS", width: "20%" },
    { type: "string", name: "name", label: "", width: "15%" },
    
  ];

  let rows = [
    [

        {
            type: 'link',
            name: 'invoice_number',
            value: 'IIB',
            url: 'https://www.google.com',
            linkType: "external",
            display:"inline-block",
          },

      { type: "string", name: "name", value: "orgemail@gmail.com", },
      { type: "string", name: "frequency", value: "Oct 20, 2020, 11:59 PM", },
      { type: "status", status: "success", value: "Succeed" },
      { type: "string", name: "name", value: "", },
      
    ],



  ];

  // ============================== Table End ======================================//



  // ============================== Card Details Start ===============================//
  let header = { tableName: `EID2021`, };

  let data = [

    {
      field: "Used",
      value: `5`

    },

    {
      field: "Valid From",
      value: "Sep 12, 2020, 12:00 AM"
    },

    {
      field: "Campaign",
      value: `Discount Coupons`
    },

    {
      field: "Limit",
      value: "50"
    },

    {
      field: "Valid Till",
      value: "Oct 20, 2020, 11:59 PM"
    },

    {
      field: "Discount Type",
      value: "Amount"
    },

    {
      field: "Applicable to",
      value: "DEENCONNECT, DEENKIOSK, DEENWEB"
    },

    {
      field: "Created by",
      value: "Nafseen Al Shoaib"
    },

    {
      field: "Value",
      value: "$10"
    },

  ];

  let footerLinkName = "Waqas bhai Hide this button";
  let footerDeleteButton = "DELETE CAMPAIGN";


  // ============================== Card Details END ===============================//

  const classes = useStyles();

  const style = {
    width:'100%',
    background: "#f7f8fa",
  };

  return (
    <MasterLayoutMiddle

      header={
        <div className="backBtnFrom">
          <BaseButton
            onClick={() => props.history.push("/hardware")}
            text="back"
            textStyle={styles.txt}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            style={styles.backBtn}
            startIcon={<ChevronLeftIcon />}
          />
        </div>
      }
      content={
        <div style={styles.Container}>

          <Grid container spacing={3}>


            <Grid item xs={12} >
              <CardDetailsTable
                data={data}
                header={header}
                footerLinkName={footerLinkName}
                footerDeleteButton={footerDeleteButton}
                //onClick={() => onClick()}
                //onDeleteClick={() => onDeleteClick()}

                Status={true}
                StatusLabel="Status"
                StatusText="Active"
                statuscolor="#3CC480"
              />

            </Grid>

            <Grid item xs={12} >
              <div className="table_box">
                <SimpleTable
                  showHeader={true}
                  title="Redemption History"
                  width="100%"
                  rows={rows}
                  columns={col}
                  colSpan={16}
                  actionSendButton={true}
                  classProps={changeClassProps()}
                  donationsCount={1}
                  noTitle=""
                  noPara=""
                  noBtnText=""
                  onInviteClick={() =>
                    this.props.history.push(
                      "/add-new-campaign"
                    )
                  }
                  actionLink={(name) => this.details(name)}
                  showIconCol={false}
                  actionSendButton={true}
                  //editIcon={Edit}
                  //deleteIcon={Delete}
                  editIcon={CreateIcon}
                  deleteIcon={DeleteIcon}
                  //editAction = {(e) => this.editService(e)}
                  deleteAction={(e) => this.deleteServiceAction(e)}
                  showFooter={true}
                  showAdd={false}
                  addText="ADD NEW"
                  onAddClick={() =>
                    this.props.history.push(
                      "/add-new-campaign"
                    )
                  }
                  iconTextSize={16}
                />
              </div>
            </Grid>

          </Grid>

        </div>
      }

      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}

let styles = {
  txt: {
    color: '#4D4D4D ',
    letterSpacing: '1.26px',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 500,
    fontSize: '15px'
  },

  backBtn: {
    backgroundColor: 'transparent',
    borderRadius: '18px',
    color: '#4D4D4D',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '15px',
    width: '86px',
    height: '36px',
  },

  Container: {
    marginTop: "60px",
  }

}

export default CampaignDetails