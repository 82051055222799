import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import {MasterLayoutMiddle, FooterInner, CardDetailsTable, EventLogs,  AlertAction} from "@dclab/dc-react-ui-kit";
import './style.css';
import Moment from "moment";
import { DeleteContact } from "./Messages/api";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Check from "./check_circle-24px.svg";
import { serialize2 } from "./markdowntoreact";
import { AlertDialogue } from "@dclab/dc-react-ui-kit";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';

 

function QuoteRequestsDetails(props) {
  const [contact, setcontact] = useState({})
  const [open, setOpen] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const {singleQuotation} = props;
  const [colorObject, setcolorObject] = useState({
    'New': '#7266FB',
    'In-progress': '#EEB000',
    'Closed': '#B00020',
    'Done': '#3CC480'
  })

//   useEffect(() => {
//     if(props?.location?.state?.contact) {
//       props?.location?.state?.contact && setcontact(props.location.state.contact)
//     }
// }, [props?.location?.state?.contact])

useEffect(() => {
  if(props.match.params.id) {
    props.ongetQuotation(props.match.params.id);
  }
}, [])

// useEffect(() => {

//   let qid = ( props.match.params.id );

//   props.getQuotation( qid );

//   if(props?.location?.state?.assigneeUpdated) {
//     setshowAlert(true);
//     setTimeout(() => {
//       setshowAlert(true)
//     }, 8000);
//   }
// }, [])


// ======= Table Start ========//
let header = {
  tableName: `Details`,
  
};

console.log( singleQuotation );

let data = [
  {
    field: "Request Date",
    value: Moment(singleQuotation.createdAt).format("MMM DD, yyyy") ,
  },
  {
    field: "App Stream",
    value: "WFM Website",
  },
  {
    field: "Name",
    value: singleQuotation.firstName + (singleQuotation?.lastName? ' '+singleQuotation.lastName : '' ),
  },
  {
    field: "Phone number",
    value: singleQuotation?.phone ? singleQuotation.phone : "<span style = 'color: #818E94'>No data</span>",
  },
  {
    field: "Email",
    value: singleQuotation?.email ? singleQuotation.email : "<span style = 'color: #818E94'>No data</span>",
  },
  {
    field: "Org Name",
    value: singleQuotation?.orgName ? singleQuotation.orgName : 'NA' ,
  },

  
];

const card2 = [
  {
    field: "Project Date",
    value: singleQuotation?.projectStartDate ? Moment( singleQuotation.projectStartDate).format("MMM DD, yyyy") : 'NA' ,
  },

  {
    field: "Interested In",
    value: singleQuotation?.interestedIn ? singleQuotation.interestedIn : 'NA' 
  },

  {
    field: "Message",
    value: singleQuotation.message 
  },
]


let footerLinkName = "ASSIGN";
let footerDeleteButton = "Delete";

// HANDLE FOOTER LINK
const onClick = () => {
  console.log('edit')
  props.history.push({pathname: "/communication/quote-requests-respond", state: {contact: contact} })
}

const onDeleteClick = () => {
  setOpen(true)
}
// =====Table Start ==========//
  const handleConfirm = async () => {
    setOpen(false);
    props.onDeleteContact(contact._id);
    // console.log('delete', contact._id)
    // props.history.push({pathname: "/communication/quote-requests", state: { showMessageAlert: true }});
    // setOpen(false)
  };

  const style2 = {
    width:500,
    marginLeft:800
  };
    
  // ============================== Timeline Start ======================================//

  let details = contact?.responseTimeline?.length ? contact.responseTimeline.map((log, i) => {
    let x = log?.note && log?.note?.includes('"type":') && JSON.parse(log?.note);
    let editor;
    if(x) {
      editor = { children: [ ...x ] }
    }

    const description = log?.note && log?.note?.includes('"type":') ? serialize2(editor) : log?.note;

    return {
      id: i + 1, icon: Check, status: log.status, date: log.createdAt ? Moment(log.createdAt).format('MMM DD, YYYY, hh:mm A') : "",  details: [
        { title: 'Reviewed by', message: log?.reviewedBy ? log.reviewedBy : 'Admin' },
        { title: 'Assignee', message: log?.assignee?.[0]?.name ? log.assignee[0].name : '' },
        { title: 'Note', message: log?.note?.length > 0 && !description.includes("<p>") ? description : "No Data" }
      ]
    }
}) : [];

  details = details && details.length ? details.reverse() : [];

  // ============================== Timeline End ======================================//
  
  
  const style = {
    width:'100%',
    background: "#f7f8fa",
  };
  
  return (
    <MasterLayoutMiddle

    header={
      <>
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/communication/quote-requests", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>

        {showAlert && (
              <div className="header_alert">
                <AlertAction
                  alert={"Inquiry updated successfully"}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )}

      </>
    }

      content={
        <div className="detailsTableContainer">

            <Grid container spacing={3} >
            {props.page && props.page.loading && <Spinner />}
            <Grid item md={12} sm={12} xs={12}>
            <CardDetailsTable 
              data={data} 
              data2={card2} 
              twoColumns 
              header={header} 
              footerLinkName={footerLinkName} 
              footerDeleteButton={footerDeleteButton}
              onClick={() => onClick()}
              onDeleteClick={() => onDeleteClick()}

              Status={true}
              StatusLabel="Status"
              StatusText={contact?.status ?? "New"}  
                StatusText={contact?.status ?? "New"}  
              StatusText={contact?.status ?? "New"}  
              statuscolor={colorObject[contact?.status ?? "New"]}
            />
            </Grid>
              
            <Grid item md={12} sm={12} xs={12} >
              <div className="FullTable">
             <EventLogs data={details}
                headTitle="Timeline"
                schedule={true}
              />
              </div>
              </Grid>
            </Grid>
        
            <AlertDialogue
              title="Delete Quote Request?"
              description="Are you sure you want to delete this quote request? Deleting will permanently remove from everywhere."
              cancelText="Cancel"
              confirmText="Confirm"  
              open={open}
              handleCancel={() => setOpen(false)}
              handleClose={() => setOpen(false)}
              handleConfirm={() => handleConfirm()}
              styles={style2}
            />
            
     
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = state => {
  return {
    page: state.page,
    errors: state.errors,
    singleQuotation: state.contacts.singleQuotation, 
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onDeleteContact: (id) => dispatch(actions.deleteContact(id, ownProps)),
    ongetQuotation: (id) => dispatch(actions.getQuotation(id )),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(QuoteRequestsDetails);