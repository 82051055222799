import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseSelect, SimpleTable,MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import * as actions from '../../../store/Actions/index';
// import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import "../products.css";
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import CreateIcon from "../../../assets/img/create-24px.svg";
import axios from 'axios';

const backendServerURL = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;


function EditPlan(props) {
    const {applicationInfo} = props;

    const [open, setOpen] = React.useState(false);
    const [plan, setplan] = useState({
        id: "", name: "", status: "", 
    })
    // const [stripePlans, setstripePlans] = useState([])

    const [feature, setfeature] = useState({
        title: "", description: "", featureType: "", price: 0, billingPeriod: "", status: "Available",
    })

    const [monthlyBilling, setmonthlyBilling] = useState({
        stripePlanId: "",
        price: undefined,
        billingPeriod: "Monthly",
        discountPercentage: undefined,
    })

    const [annuallyBilling, setannuallyBilling] = useState({
        stripePlanId: "",
        price: undefined,
        billingPeriod: "Annually",
        discountPercentage: undefined,
    })

    const [features, setfeatures] = useState([])
    
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    useEffect(() => {
        // console.log("planid", props.location?.state?.planId)
        if(props.location?.state?.planId !== undefined) {
            let id = props.location.state.planId;
            const {plans, features} = applicationInfo;
            // console.log("plans", plans)

            let savePlan = plans?.length ? plans.find((el, i) => i === id) : null;
            // console.log("saveplan", savePlan)

            if(savePlan) {
                setplan({ id: savePlan.id, name: savePlan.name, status: savePlan.status })
                let f = features?.length ? features.map((el) => {

                    if(savePlan.features?.length) {
                        let prevFeature = savePlan.features?.find((plan, i) => plan.title === el.title)
                        // console.log("prevFeature", prevFeature)
                        if(prevFeature) {
                            return {...el, featureType: prevFeature?.featureType}
                        } else {
                            return el.featureType === "Paid" ? {...el, featureType: "Add-on"} : {...el, featureType: "Included" }

                        }
                    }
                    return el.featureType === "Paid" ? {...el, featureType: "Add-on"} : {...el, featureType: "Included" }
                }) : []

                setfeatures(f)
                setannuallyBilling(savePlan.annuallyBilling ?? {})
                setmonthlyBilling(savePlan.monthlyBilling ?? {})
            }
        }
    }, [applicationInfo])

    // useEffect(() => {
    //     if(stripePlans?.length) {
    //         let monthPlan = stripePlans.find((el) => el.interval === "month") ?? {}
    //         monthPlan && setmonthlyBilling({ ...monthlyBilling, stripePlanId: monthPlan.id ?? "", price: monthPlan.amount ? monthPlan.amount / 100 : 0 })

    //         let yearPlan = stripePlans.find((el) => el.interval === "year") ?? {}
    //         yearPlan && setannuallyBilling({ ...annuallyBilling, stripePlanId: yearPlan.id ?? "", price: yearPlan.amount ? yearPlan.amount / 100 : 0 })
    //     }
    // }, [stripePlans])

    let handleEditFeatureChange = (prop) => (event) => {
        setfeature({...feature, [prop]: event.target.value });
    }

    let handleChange = (prop) => (event) => {
        setplan({...plan, [prop]: event.target.value });
    }

    let handleChange2 = (prop) => (event) => {
        if(prop === 'stripePlanId') {
            let val = event.target.value ? event.target.value?.trim() : "";
            setmonthlyBilling({...monthlyBilling, [prop]: event.target.value });
            if(val) {
                axios
                .get(backendServerURL + '/stripe/getStripePlan/' + val)
                .then(res => {
                    const data = res?.data?.body?.data;
                    if(data?.id) {
                        setmonthlyBilling({...monthlyBilling, stripePlanId: data.id ?? "", price: data.amount ? data.amount / 100 : 0 });
                    }
                })
                .catch(err => {
                    console.log("error: ", err)
                })
            }
        } else if((prop === "discountPercentage" || prop === "price") && event.target.value >= 0){
            setmonthlyBilling({...monthlyBilling, [prop]: event.target.value });
        }
    }

    let handleChange3 = (prop) => (event) => {
        if(prop === 'stripePlanId') {
            let val = event.target.value ? event.target.value?.trim() : "";
            setannuallyBilling({...annuallyBilling, [prop]: event.target.value });
            if(val) {
                axios
                .get(backendServerURL + '/stripe/getStripePlan/' + val)
                .then(res => {
                    const data = res?.data?.body?.data;
                    if(data?.id) {
                        setannuallyBilling({...annuallyBilling, stripePlanId: data.id ?? "", price: data.amount ? data.amount / 100 : 0 });
                    }
                })
                .catch(err => {
                    console.log("error: ", err)
                })
            }
        } else if((prop === "discountPercentage" || prop === "price") && event.target.value >= 0){
            setannuallyBilling({...annuallyBilling, [prop]: event.target.value });
        }
    }

    const redirectToStepper = () => {
        if(props.location?.state?.appId) {
            props.history.push({pathname: `/edit-product/${props.location?.state?.appId}`, state: {step: 2}});
        } else {
            props.history.push({pathname: `/add-product`, state: {step: 2}});
        }
    }

    let onSubmit = (e) => {
        e.preventDefault();
        // console.log("id", props?.location?.state?.planId)

        let plans = applicationInfo?.plans?.length ? applicationInfo.plans : [];
        let id = props?.location?.state?.planId;

        if(id !== undefined) {
            let prevPlans = [...plans];
            prevPlans[id] = { ...plan, annuallyBilling, monthlyBilling, features};
    
            let formData = {
                plans: [...prevPlans]
            }
            // console.log("submit pricing", formData)
            props.onAddAppInfo(formData)
            redirectToStepper();
        }
    }

    
  let columns = [
    // { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "string", name: "name", label: "FEATURE", width:"80%" },
    { type: "string", name: "name", label: "TYPE", width:"20%" },
    { type: "action", name: "actionIcons", label: "ACTION", width:"auto"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

    
  let rows = [];
  if (features && features.length) {
    rows = features.map((app, index) => {
      return (
        [
          { type: "string", id: index, name: "category", value: app.title },
          { type: "string", name: "category", value: app.featureType },
        ]
      )
    });
  }

  const editAction = (data) => {
    // console.log("edit", data[0].id)
    let id = data[0].id;
    let editFeature = features.find((el, i) => i === id);
    setfeature({...editFeature, id: id, featureType: "Add-on"});
    setOpen(true)
  }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const style = {
        width:'100%',
        background: "#fff",
      };

    const closeDrawer = () => {
        setOpen(!open);
      };
      
    const onCancel = () => {
    setOpen(false);
    };
      
    const onSave = () => {
    if(feature.id !== undefined) {
        let prevFeatures = [...features];
        prevFeatures[feature.id] = { id: undefined, ...feature};
        setfeatures([...prevFeatures]);
        setOpen(false);
        setfeature({
            title: "", description: "", featureType: "", price: 0, billingPeriod: "", status: "",
        });
        }
    };

    //&& monthlyBilling.stripePlanId && annuallyBilling.stripePlanId
    const { id, name, status } = plan;
    const paidPlan = name?.toLowerCase() !== "free" && parseInt(annuallyBilling.price) > 0 && parseInt( monthlyBilling.price) > 0 ? true : false;
    let disableBtn = (status === 'Not Available') ? false :  id && name && status && (name?.toLowerCase() !== "free" ? paidPlan : true) && features?.length ? false : true;

    // console.log(monthlyBilling, annuallyBilling, "disableBtn")
    // console.log(disableBtn, paidPlan, "plan")


    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Add Feature"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                saveDisabled={false}
            >

            <div className="field_box">
            <BaseTextField
                value={feature.title}
                label='Feature Title'
                placeholder="Feature Title"
                InputProps={false}
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                disabled
            />
          </div>

       {feature.price > 0 && <Fragment><div className="field_box">
            <TextField
                value={feature.price}
                label='Price'
                placeholder="Price"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                disabled
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
          </div>

     
       </Fragment>}

       <div className="field_box">
            <BaseSelect
                value={feature.featureType}
                onChange={handleEditFeatureChange("featureType")}
                object={[
                    {
                        value: "Add-on",
                        label: "Add-on",
                    },
                    {
                    value: "Included",
                    label: "Included",
                    },
                ]}
                style={{width: "100%", marginLeft: 0}}
                helperText=""
                label='Pricing'
                placeholder="Pricing"
                style={styles.input}
            />
        </div>
       
            
            </DrawerMain>

            <MasterLayoutMiddle
                header={
                    <div>
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => redirectToStepper()}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {/* {props.page && props.page.loading && <Spinner />} */}
                </div>
                }
                isForm={true}
                formData ={
                    <form onSubmit={onSubmit}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}> 
                            <h1 className="title_top">Edit Plan</h1>	 
                        </Grid>	      

                        <Grid item xs={6}> 
                            <div className="field_box">
                            <BaseTextField
                                value={name}
                                onChange={handleChange("name")}
                                label='Plan Name'
                                placeholder="Plan Name"
                                InputProps={false}
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                disabled={true}
                            />
                            </div>
                        </Grid>

                        {/* <Grid item xs={6}> 
                            <div className="field_box">
                            <BaseTextField
                                value={id}
                                onChange={handleChange("id")}
                                label='Plan ID'
                                placeholder="Plan ID"
                                InputProps={false}
                                phone={false}
                                search={false}
                                disabled={false}
                                style={styles.input}
                            />
                            </div>
                            </Grid> */}

                        <Grid item xs={6}> 
                            <div className="field_box">
                            <BaseSelect
                                value={status}
                                onChange={handleChange("status")}
                                object={[
                                    {
                                        value: "Available",
                                        label: "Available",
                                    },
                                    {
                                        value: "Not Available",
                                        label: "Not Available",
                                    },
                                    {
                                        value: "Coming Soon",
                                        label: "Coming Soon",
                                    },
                                ]}
                                style={styles.input}
                                helperText=""
                                label='Plan Status'
                                placeholder="Plan Status"
                            />
                             </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="input-fields-bottom-line">
                            </div>
                        </Grid>


                        {name.toLowerCase() !== "free" && name.toLowerCase() !== "custom" && <Fragment>

                        <Grid item xs={12}> 
                            <h1 className="title-top title-top-section">Pricing</h1>	 
                        </Grid>	 

                        <Grid item xs={4}> 
                            <div className="field_box">
                            <TextField
                                value={monthlyBilling.price}
                                onChange={handleChange2("price")}
                                label='Price'
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="number"
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <TextField
                                value={monthlyBilling.billingPeriod}
                                onChange={handleChange2("billingPeriod")}
                                label='Billing Cycle'
                                phone={false}
                                search={false}
                                style={styles.input}
                                disabled
                            />
                             </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <TextField
                                value={monthlyBilling.discountPercentage}
                                onChange={handleChange2("discountPercentage")}
                                helperText=""
                                label='Discount'
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="number"
                                InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </div>
                        </Grid>  

                        <Grid item xs={12}> 
                        <div className="field_box">
                            <TextField
                                value={monthlyBilling.stripePlanId}
                                onChange={handleChange2("stripePlanId")}
                                helperText=""
                                label='Stripe Price ID'
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                            />
                            </div>
                        </Grid>  

                        <Grid item xs={12}>
                            <div className="title_top"></div>
                        </Grid>             

                        <Grid item xs={4}> 
                            <div className="field_box">
                            <TextField
                                value={annuallyBilling.price}
                                onChange={handleChange3("price")}
                                label='Price'
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="number"
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <TextField
                                 value={annuallyBilling.billingPeriod}
                                 onChange={handleChange3("billingPeriod")}
                                label='Billing Cycle'
                                phone={false}
                                search={false}
                                style={styles.input}
                                disabled
                            />
                             </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <TextField
                                value={annuallyBilling.discountPercentage}
                                onChange={handleChange3("discountPercentage")}
                                helperText=""
                                label='Discount'
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="number"
                                InputProps={{
                                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                             </div>
                        </Grid>

                        <Grid item xs={12}> 
                        <div className="field_box">
                            <TextField
                                value={annuallyBilling.stripePlanId}
                                onChange={handleChange3("stripePlanId")}
                                helperText=""
                                label='Stripe Price ID'
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="input-fields-bottom-line">
                            </div>
                        </Grid> 
                        </Fragment>}

                        <Grid item xs={12}> 
                            <h1 className="title-top title-top-section">Features</h1>	 
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box feature-table">
                            <SimpleTable
                                showHeader={false}
                                rows={rows}
                                columns={columns1(columns)}
                                colSpan={6}
                                actionSendButton={true}
                                classProps={changeClassProps()}
                                showFilter={false}
                                iconTextSize={14}
                                editIcon={CreateIcon}
                                showIconCol={true}
                                showFooter={rows?.length > 5 ? true : false}
                                editAction={(data) => editAction(data)}
                                deleteIconChild={false}
                                donationsCount={rows?.length ? 1 : 0}
                                noTitle="No Features"
                                noPara="Add features to plan."
                            />
                            </div>
                        </Grid>


                        <Grid item xs={12}> 
                            <div className="form_button">
                            <BaseButton
                            onClick={() => redirectToStepper()}
                            text="GO BACK"
                            textStyle={styles.txt}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            variant='text'
                        />
                        <BaseButton
                            onClick={onSubmit}
                            type="submit"
                            text={'SAVE NOW'}
                            disabled={name?.toLowerCase() === "custom" ? false : disableBtn}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            style={ name?.toLowerCase() === "custom" ? styles.inviteBtn : disableBtn ? styles.disableBtn : styles.inviteBtn}
                        />
                            </div>
                        </Grid>

                        </Grid>
                    </form>

                }



                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }

                isWhiteBackground={true}


                />


        </Fragment>
    )

}

const mapStateToProps = state => {
    return {
        //   page: state.page,
        //   errors: state.errors
        applicationInfo: state.products.applicationInfo
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddAppInfo: (application) => dispatch(actions.addAppInfo(application, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditPlan);