import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseSelect, SimpleTable,MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import * as actions from '../../../store/Actions/index';
// import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import "../products.css";
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';
import CreateIcon from "../../../assets/img/create-24px.svg";


function AddPlan(props) {
    const {applicationInfo} = props;

    const [open, setOpen] = React.useState(false);
    const [plan, setplan] = useState({
        id: "", name: "", status: "", 
    })

    const [feature, setfeature] = useState({
        title: "", description: "", featureType: "", price: 0, status: "Available",
    })

    const [monthlyBilling, setmonthlyBilling] = useState({
        price: 0,
        billingPeriod: "Monthly",
        discountPercentage: 0,
    })

    const [annuallyBilling, setannuallyBilling] = useState({
        price: 0,
        billingPeriod: "Annually",
        discountPercentage: 0,
    })

    const [features, setfeatures] = useState([])

    useEffect(() => {
        setfeatures(applicationInfo?.features?.length ? applicationInfo.features.map((el) =>{ return { ...el, featureType: el.featureType === "Free" ? "Included" : "Add-on" }}) : [])
     }, [applicationInfo])
    
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    let handleEditFeatureChange = (prop) => (event) => {
        setfeature({...feature, [prop]: event.target.value });
    }

    let handleChange = (prop) => (event) => {
        setplan({...plan, [prop]: event.target.value });
    }

    let handleChange2 = (prop) => (event) => {
        setmonthlyBilling({...monthlyBilling, [prop]: event.target.value });
    }

    let handleChange3 = (prop) => (event) => {
        setannuallyBilling({...annuallyBilling, [prop]: event.target.value });
    }

    const redirectToStepper = () => {
        if(props.location?.state?.appId) {
            props.history.push({pathname: `/edit-product/${props.location?.state?.appId}`, state: {step: 2}});
        } else {
            props.history.push({pathname: `/add-product`, state: {step: 2}});
        }
    }
    

    let onSubmit = (e) => {
        e.preventDefault();
        let plans = applicationInfo?.plans?.length ? applicationInfo.plans : [];
        let formData = {
            plans: [...plans, {
                ...plan, 
                annuallyBilling, 
                monthlyBilling, 
                features: features?.length ? features.map((el) => { return { ...el, price: el.featureType === "Included" ? 0 : el.price }}) : []
            }]
        }
        console.log("submit", formData)
        props.onAddAppInfo(formData)
        redirectToStepper()
    }

    
  let columns = [
    // { type: "number", name: "serial_number", label: "NO.", width:"auto" },
    { type: "string", name: "name", label: "FEATURE", width:"60%" },
    { type: "string", name: "name", label: "TYPE", width:"20%" },
    { type: "action", name: "actionIcons", label: "ACTION", width:"20%"  },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      return obj;
    });

    
  let rows = [];
  if (features && features.length) {
    rows = features.map((app, index) => {
      return (
        [
          { type: "string", id: index, name: "category", value: app.title },
          { type: "string", name: "category", value: app.featureType },
        ]
      )
    });
  }

  const editAction = (data) => {
    console.log("edit", data[0].id)
    let id = data[0].id;
    let editFeature = features.find((el, i) => i === id);
    setfeature({...editFeature, id: id, featureType: "Add-on"});
    setOpen(true)
  }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const style = {
        width:'100%',
        background: "#fff",
      };

    const closeDrawer = () => {
        setOpen(!open);
      };
      
    const onCancel = () => {
    setOpen(false);
    };
      
    const onSave = () => {
    if(feature.id != undefined) {
        let prevFeatures = [...features];
        prevFeatures[feature.id] = { id: undefined, ...feature};
        setfeatures([...prevFeatures]);
        setOpen(false);
        setfeature({
            title: "", description: "", featureType: "", price: 0, status: "",
        });
        }
    };



    const { id, name, status } = plan;
    let disableBtn = id && name && status && features?.length ? false : true;
    

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Add Feature"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                saveDisabled={false}
            >

            <div className="field_box">
            <BaseTextField
                value={feature.title}
                label='Feature Title'
                placeholder="Feature Title"
                InputProps={false}
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                disabled
            />
          </div>

       {feature.price > 0 && <Fragment><div className="field_box">
            <TextField
                value={feature.price}
                label='Price'
                placeholder="Price"
                phone={false}
                search={false}
                style={styles.input}
                color="green"
                disabled
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
            />
          </div>

          {/* <div className="field_box">
            <BaseSelect
                value={feature.billingPeriod}
                disabled
                object={[
                    {
                        value: feature.billingPeriod,
                        label: feature.billingPeriod,
                    }
                ]}
                style={styles.input}
                helperText=""
                label='Billing Cycle'
                placeholder="Billing Cycle"
            />
          </div> */}
       </Fragment>}

       <div className="field_box">
            <BaseSelect
                value={feature.featureType}
                onChange={handleEditFeatureChange("featureType")}
                object={[
                    {
                        value: "Add-on",
                        label: "Add-on",
                    },
                  {
                    value: "Included",
                    label: "Included",
                },
                ]}
                style={{width: "100%", marginLeft: 0}}
                helperText=""
                label='Pricing'
                placeholder="Pricing"
                style={styles.input}
            />
        </div>
       
            
            </DrawerMain>

            <MasterLayoutMiddle
                header={
                    <div>
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => redirectToStepper()}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {/* {props.page && props.page.loading && <Spinner />} */}
                </div>
                }
                isForm={true}
                formData ={
                    <form onSubmit={onSubmit}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}> 
                            <h1 className="title_top">Add Plan</h1>	 
                        </Grid>	      

                        <Grid item xs={6}> 
                            <div className="field_box">
                            <BaseTextField
                                value={name}
                                onChange={handleChange("name")}
                                label='Plan Name'
                                placeholder="Plan Name"
                                InputProps={false}
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                            />
                            </div>
                        </Grid>

                        <Grid item xs={6}> 
                            <div className="field_box">
                            <BaseTextField
                                value={id}
                                onChange={handleChange("id")}
                                label='Plan ID'
                                placeholder="Plan ID"
                                InputProps={false}
                                phone={false}
                                search={false}
                                // count={100}
                                style={styles.input}
                            />
                            </div>
                            </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">
                            <BaseSelect
                                value={status}
                                onChange={handleChange("status")}
                                object={[
                                    {
                                        value: "Available",
                                        label: "Available",
                                    },
                                    {
                                        value: "Not Available",
                                        label: "Not Available",
                                    }
                                ]}
                                style={styles.input}
                                helperText=""
                                label='Plan Status'
                                placeholder="Plan Status"
                            />
                             </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="input-fields-bottom-line">
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <h1 className="title-top title-top-section">Pricing</h1>	 
                        </Grid>	 

                        <Grid item xs={4}> 
                            <div className="field_box">
                            <TextField
                                value={monthlyBilling.price}
                                onChange={handleChange2("price")}
                                label='Price'
                                placeholder="Price"
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="number"
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <BaseSelect
                                value={monthlyBilling.billingPeriod}
                                onChange={handleChange2("billingPeriod")}
                                object={[
                                    {
                                        value: "Monthly",
                                        label: "Monthly",
                                    },
                                ]}
                                style={styles.input}
                                helperText=""
                                label='Billing Cycle'
                                placeholder="Billing Cycle"
                            />
                             </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <BaseSelect
                                value={monthlyBilling.discountPercentage}
                                onChange={handleChange2("discountPercentage")}
                                object={[
                                    { value: 0, label: "None" },
                                    { value: 5, label: "5%" },
                                    { value: 10, label: "10%" },
                                    { value: 15, label: "15%" },
                                    { value: 20, label: "20%" },
                                    { value: 25, label: "25%" },
                                ]}
                                style={styles.input}
                                helperText=""
                                label='Discount'
                                placeholder="Discount"
                            />
                             </div>
                        </Grid>

                        <Grid item xs={4}> 
                            <div className="field_box">
                            <TextField
                                value={annuallyBilling.price}
                                onChange={handleChange3("price")}
                                label='Price'
                                placeholder="Price"
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="number"
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <BaseSelect
                                value={annuallyBilling.billingPeriod}
                                onChange={handleChange3("billingPeriod")}
                                object={[
                                    {
                                        value: "Annually",
                                        label: "Annually",
                                    },
                                ]}
                                style={styles.input}
                                helperText=""
                                label='Billing Cycle'
                                placeholder="Billing Cycle"
                            />
                             </div>
                        </Grid>

                        <Grid item xs={4}> 
                        <div className="field_box">
                            <BaseSelect
                                value={annuallyBilling.discountPercentage}
                                onChange={handleChange3("discountPercentage")}
                                object={[
                                    { value: 0, label: "None" },
                                    { value: 5, label: "5%" },
                                    { value: 10, label: "10%" },
                                    { value: 15, label: "15%" },
                                    { value: 20, label: "20%" },
                                    { value: 25, label: "25%" },
                                ]}
                                style={styles.input}
                                helperText=""
                                label='Discount'
                                placeholder="Discount"
                            />
                             </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="input-fields-bottom-line">
                            </div>
                        </Grid> 

                        <Grid item xs={12}> 
                            <div className="field_box feature-table">
                            <SimpleTable
                                showHeader={false}
                                rows={rows}
                                columns={columns1(columns)}
                                colSpan={6}
                                actionSendButton={true}
                                classProps={changeClassProps()}
                                showFilter={false}
                                iconTextSize={14}
                                deleteIconChild={false}
                                editIcon={CreateIcon}
                                showIconCol={true}
                                deleteAction={(data) => console.log("delete")}
                                editAction={(data) => editAction(data)}
                                donationsCount={rows?.length ? 1 : 0}
                                noTitle="No Features"
                                noPara="Add features to plan."
                                // noBtnText="Add Plan"
                            />
                            </div>
                        </Grid>


                        <Grid item xs={12}> 
                            <div className="form_button">
                            <BaseButton
                            onClick={() => redirectToStepper()}
                            text="GO BACK"
                            textStyle={styles.txt}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            variant='text'
                        />
                        <BaseButton
                            onClick={onSubmit}
                            type="submit"
                            text={'SAVE NOW'}
                            disabled={disableBtn}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                        />
                            </div>
                        </Grid>

                        </Grid>
                    </form>

                }



                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }

                isWhiteBackground={true}


                />


        </Fragment>
    )

}

const mapStateToProps = state => {
    return {
        //   page: state.page,
        //   errors: state.errors
        applicationInfo: state.products.applicationInfo
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddAppInfo: (application) => dispatch(actions.addAppInfo(application, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddPlan);