import React, { useState } from "react";
import {
  BaseTextField,
  BasePhoneInput,
  SimpleOtp,
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";

import "./onboarding.css";

function OwnerDetails(props) {
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    phoneNo: "",
  });
  React.useEffect(() => {
    props.callback(values);
  }, [values]);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };
  const PhoneChange = (e) => {
    setValues({ ...values, phoneNo: e });
  };

  const styles = {
    btn: {
      height: 66,
      width: "100%",
      margin: 8,
      border: "#D3D8DA",
    },
  };

  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Owner First Name"
              InputProps={false}
              onChange={handleChange("firstName")}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BaseTextField
              onChange={handleChange("lastName")}
              label="Owner Last Name"
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Email"
              onChange={handleChange("userName")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BasePhoneInput
              phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
              country={"us"}
              onChange={(e) => PhoneChange(e)}
              countryCodeEditable={false}
              //   onChange={(phone, country) =>
              //     console.log("onChange", phone, country)
              //   }
              //   onBlur={() => console.log("onBlur")}
              //   onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
              variant="outlined"
              onlyCountries={["us", "ca", "gb", "my"]}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "35px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              //errorText={"Phone number is required"}
              error={true}
              errorStyles={{ marginBottom: "5px" }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              inputProps={{}}
              specialLabel={"Phone Number"}
              // preferredCountries={[]}
              // excludeCountries={[]}
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="field_box">
            <BaseTextField
              onChange={handleChange("password")}
              label="Temporary Password"
              type="password"
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default OwnerDetails;
