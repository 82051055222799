import React, { Component } from 'react';
import { BaseButton, BaseTextField, AlertAction, MasterLayoutMiddle, BasePhoneInput,
  FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classes from './invite.module.css';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions';
import EventsLogs from './eventsLogs';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import './invite.css'


class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAccordian: true,
      successAlert: false,
      errorAlert: true,

      errors: {},
      loading: false,

      //invited organization detial
      id: '',
      invtiteOrganization: {},

      name: "",
      fullName: '',
      phoneNo: '',
      email: '',
      inviteCode: this.generateNumber(),
      dateTime: '',
      reInvite: false,
      logs: [],

      nameError: false,
      fullNameError: false,
      phoneNoError: false,
      emailError: false,
    };
  }



  generateNumber = () => {
    return Math.floor(100000 + Math.random() * 900000)
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  static getDerivedStateFromProps(props, state) {
    let errors = props.errors;
    let page = props.page;
    let invtiteOrganization = props.invtiteOrganization ? props.invtiteOrganization : {};

    let stateChanged = false;
    let changedState = {};

    const paramId = props.match.params.id;
    if (paramId) {
      if (invtiteOrganization && JSON.stringify(state.invtiteOrganization) !== JSON.stringify(invtiteOrganization)) {
        changedState.invtiteOrganization = invtiteOrganization;
        stateChanged = true;

        changedState.name = invtiteOrganization.orgName ? invtiteOrganization.orgName : '';
        changedState.fullName = invtiteOrganization.orgAdmin ? invtiteOrganization.orgAdmin : '';
        changedState.phoneNo = invtiteOrganization?.phoneFax?.primaryPhone ? invtiteOrganization.phoneFax.primaryPhone : '';
        changedState.email = invtiteOrganization.orgEmail ? invtiteOrganization.orgEmail : '';
        changedState.dateTime = invtiteOrganization.createdAt ? invtiteOrganization.createdAt : '';
        changedState.logs = invtiteOrganization?.invitationsDetails?.inviteLogs ? invtiteOrganization.invitationsDetails.inviteLogs : [];
        // changedState.reInvite = invtiteOrganization.logs ? invtiteOrganization.logs : [];
      }
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }
    return null;
  }


  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const id = this.props.match.params.id;
    if (id) {
      this.setState({
        id: id, reInvite: true, inviteCode: this.generateNumber()
      });
      this.props.onGetSingleInvitedOrganiztion(id);
    } else {
      this.setState({
        showAccordian: true,
        successAlert: false,
        errors: {},
        loading: false,
        id: '',
        invtiteOrganization: {},
        name: "",
        fullName: '',
        phoneNo: '',
        email: '',
        inviteCode: this.generateNumber(),
        dateTime: '',
        logs: [],
        reInvite: false,
        nameError: false,
        fullNameError: false,
        phoneNoError: false,
        emailError: false,
      });
    }
  }


  click = () => {
    console.log("button clicked");
    console.log("checking Value: ", this.state);
  }

  onSubmit = async (e) => {
    e.preventDefault();
    console.log("submit")
    this.setState({
      dateTime: new Date(),
      errorAlert: true,
    });
    const { name, fullName, phoneNo, email, inviteCode, dateTime, logs, id } = this.state;

    if (name && fullName && phoneNo && email && inviteCode) {
      const formData = {
        orgName: name,
        orgAdmin: fullName,
        orgEmail: email,
        phoneFax: {
          primaryPhone: phoneNo
        },
        invitationsDetails: {
          inviteAccessCode: inviteCode
        },
        dateTime: new Date(),
        //For Logs
        logs: logs,
        id: id,
      };
      let orgInvited = await this.props.onSubmit(formData, this.props.history, this.props.match.params.id);
      if (this.props.match.params.id && orgInvited) {
        this.props.onGetSingleInvitedOrganiztion(this.props.match.params.id);
      }
      this.setState({
        successAlert: true,
        inviteCode: this.generateNumber()
      });
    }

  }

  handleChange = (prop) => (event) => {
    if(prop == 'phoneNo' && event.target.value.length > 15) return
    this.setState({
      [prop]: event.target.value
    });
  }

  closeAlert = () => {
    this.setState({
      errorAlert: false,
    });
  };


  styles = {
    txt: {
      color: '#4D4D4D',
      letterSpacing: '1.26px',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 500,
      fontSize: '15px'
    },
    backBtn: {
      backgroundColor: 'white',
      borderRadius: '18px',
      color: '#4D4D4D',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 400,
      fontSize: '15px',
      width: '86px',
      height: '36px'
    },
    routes: {
      marginLeft: '70px',
      width: '90%',
      padding: '30px 0',
      display: 'flex'
    },
    input: {
      height: '48px',
      marginTop: '30px',
      width: '100%',
      border: "#D3D8DA",
    },
    input2: {
      height: '48px',
      marginTop: '30px',
      marginRight: '22px',
      width: '48%',
      float: 'left',
      border: "#D3D8DA",
    },
    input3: {
      height: '48px',
      marginTop: '30px',
      width: '48%',
      border: "#D3D8DA",
    },
    inviteBtn: {
      textAlign: 'center',
      color: 'white',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#2490F9',
      borderRadius: '23px',
      padding: '14px 45px'
    },
    disableBtn: {
      textAlign: 'center',
      color: 'black',
      letterSpacing: '1.26px',
      height: '45px',
      background: '#eee',
      borderRadius: '23px',
      padding: '14px 45px',
      opacity: 0.7,
    },
    cancelBtn: {
      textAlign: 'center',
      color: 'black',
      letterSpacing: '1.26px',
      height: '45px',
      borderRadius: '23px',
      backgroundColor: 'white',
      padding: '14px 45px'
    }
  }

  render() {
    let pageContent = '';
    const styles = this.styles;
    const { loading, name, reInvite, fullName, phoneNo, email, inviteCode, errors, nameError, fullNameError, phoneNoError, emailError, errorAlert } = this.state;
    // console.log("Checking state: ", this.state);
    const phoneRegex = /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{5})$/g;

    let disableBtn = name && fullName && phoneNo && this.validateEmail(email) && inviteCode;

    if (loading) {
      pageContent = <Spinner />
    }

    const style = {
      width:'100%',
      background: "#fff",
    };

    return (
      <React.Fragment>
        <MasterLayoutMiddle
            header={  
              <div>        
                    {pageContent}
                      <div className="backBtnFrom">
                      <BaseButton
                        onClick={() => this.props.history.push("/dashboard?tab=1")}
                        text="back"
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        startIcon={<ChevronLeftIcon />}
                      />  

                      
                      </div>

                      <div className="errorMessage">
                      {
                      errors.error && errorAlert && <AlertAction
                      alert={errors.message}
                      alertType={"error"}
                      onClick={this.closeAlert}
                    />
                    }
                    </div>
              </div>
            }

            isForm={true}
              formData ={
                <form onSubmit={this.onSubmit}>
                    
                  <Grid container spacing={3}>	      

                    <Grid item xs={12}> 
                        <h1 className="title_top">Invite Organization</h1>
                    </Grid>

                    <Grid item xs={12}>
                      <div className="field_box">
                      <BaseTextField
                        value={name}
                        onChange={this.handleChange('name')}
                        label='Organization Name'
                        placeholder="Organization Name"
                        InputProps={false}
                        phone={false}
                        search={false}
                        count={50}
                        color="green"
                        onBlur={() => name ? this.setState({ nameError: false }) : this.setState({ nameError: true })}
                        error={!name && nameError ? true : false}
                        helperText={!name && nameError ? "Required" : ""}
                      />
                      </div> 
                    </Grid>


                    <Grid item xs={12}>
                    <div className="field_box"> 
                      <BaseTextField
                        value={fullName}
                        onChange={this.handleChange("fullName")}
                        label='Admin Full Name'
                        placeholder="Admin Full Name"
                        InputProps={false}
                        phone={false}
                        search={false}
                        // count={100}
                        color="green"
                        type="string"
                        // onBlur={() => fullName ? this.setState({ fullNameError: false }) : this.setState({ fullNameError: true })}
                        // error={!fullName && fullNameError ? true : false}
                        onBlur={() => fullName ? this.setState({ fullNameError: false }) : this.setState({ fullNameError: true })}
                        error={!fullName && fullNameError ? true : false}
                        helperText={!fullName && fullNameError ? "Required" : ""}
                      />
                      </div>
                    </Grid>

                    <Grid item xs={6}> 
                      <div className="field_box">
                      <BaseTextField
                        value={email}
                        onChange={this.handleChange("email")}
                        label='Email'
                        placeholder="Email"
                        InputProps={false}
                        phone={false}
                        search={false}
                        // count={100}
                        color="green"
                        type="email"
                        onBlur={() => email && this.validateEmail(email) ? this.setState({ emailError: false }) : this.setState({ emailError: true })}
                        error={!email && emailError ? "Required" : email && !this.validateEmail(email) && emailError ? true : false}
                        helperText={!email && emailError ? "Enter valid email address" : email && !this.validateEmail(email) && emailError ? "Enter valid email address" : ""}
                      />
                      </div>
                    </Grid>

                    <Grid item xs={6}>
                   
                    <BasePhoneInput
                          phoneInputVariant={"underlineWithFlag"}
                          countryCodeEditable={false}
                          specialLabel={'Phone Number'}
                          placeholder=' '
                          country={'us'}
                          value={phoneNo}
                          onChange={(e, f, g, h) => {
                            if (!phoneRegex.test(e)) {
                              this.setState({ phoneNoError: false, phoneNo: h });
                            } else {
                              this.setState({ phoneNoError: false, phoneNo: h });
                            }
                          }}
                          onBlur={() => {
                            if (phoneNo.length) {
                              this.setState({ phoneError: true });
                            } else {
                              this.setState({ phoneError: false });
                            }
                          }
                          }
                          errorText={!phoneNo && phoneNoError ? 'Enter valid phone number' : ' '}
                          error={!phoneNo && phoneNoError ? true : false}
                        />
                      
                    </Grid>

                    <Grid item xs={12}>
                    <div className="field_box"> 
                      <BaseTextField
                        value={inviteCode}
                        label='Private Invitation Code'
                        placeholder="Private Invitation Code"
                        InputProps={false}
                        phone={false}
                        search={false}
                        color="green"
                        disabled
                      />
                      </div>
                      {reInvite ? <div className="accordian-dropdown-timeline" style={{ marginTop: '65px' }}>
                          <EventsLogs invtiteOrganization={this.state.invtiteOrganization} />
                        </div> : null
                      }
                    </Grid>

                    <Grid item xs={12}> 
                    <div className="form_button">
                      <BaseButton
                      onClick={() => this.props.history.push("/dashboard?tab=1")}
                      text="cancel"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        variant='text'
                      />
                      <BaseButton
                        onClick={this.onSubmit}
                        type="submit"
                        text={reInvite ? 'Resend invite' : 'Invite'}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={disableBtn ? styles.inviteBtn : styles.disableBtn}
                        disabled={disableBtn ? false : true}
                      />
                    </div>
                    </Grid>



                  </Grid>

                </form>

              }



            isFooter={true}
            footer={
              <FooterInner style={style}/>
            }

            isWhiteBackground={true}


            />

      </React.Fragment>
    )
  }
}


const mapStateToProps = state => {
  return {
    invtiteOrganization: state.organizations.invtiteOrganization,
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onSubmit: (formData, history, paramId) => dispatch(actions.inviteOrganization(formData, history, paramId)),
    onGetSingleInvitedOrganiztion: (id) => dispatch(actions.getInvitedOrganizationDetail(id))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);






