import React from 'react';
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import Pic1 from "../../assets/images/Ellipse_1.png";
import CreateIcon from "../../assets/img/create-24px.svg";
import DeleteIcon from "../../assets/img/delete_forever-24px.svg";
import { ReactComponent as Crown } from '../../assets/img/crown.svg'
import './teamMember.css'

export default function TabContent2(props) {
  const teamMembers = props.teamMembers;
  const id = props.id;
  const deleteAction = props.deleteAction;

  let columns = [
    { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    { type: "url", name: "single_pic", label: "IMAGE", width: 'auto'},
    { type: "string", name: "name", label: "NAME", width: '15%' },
    { type: 'string', name: 'date', label: 'ROLE', width: '55%' },
    { type: "string", name: "email", label: "EMAIL", width: '20%' },
    { type: "status", name: "status", label: "Access", width: '20%' },
    { type: "action", name: "actionIcons", label: "ACTION", width: '20%' },
  ];
  
  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };
  
  const columns1 = (columns) =>
    columns.map((obj) => {
      // delete obj.id;
      // delete obj.name;
      return obj;
    });

    let rows = [];
    if(teamMembers && teamMembers.length){
      rows = teamMembers.map((member, index) => {
        let userFullName = member?.name?.firstName || member?.name?.lastName ? member.name.firstName + " " + member.name.lastName : '';
        let userImage = member?.imageDetails?.image ? member.imageDetails.image : ""
        return (
          [
            { type: "id", id:member._id ? member._id : '', name: 'serial_number',  value: index+1 },
            { type: "url", name: "userPic", src: userImage, userInitials: userFullName  },
            { type: "string", name: "name", value: userFullName },
            
            { type: "string", name: "category",value: (member.role.length) ? member.role.map(role => {
              return role && role.roles === "hikmah_owner" ? <span class="owner-crown"> Owner <Crown /> </span> : role && role.roles === "hikmah_admin" ? "Admin" : role && role.roles === "hikmah_manager" ? "Manager" : ""
            }) : '' },
            { type: "string", name: "date", value: member.userName ? member.userName : '' },
            // { type: 'string', name: 'collapse', value: 'Show' },
            // {
            //   type: 'additionalData',
            //   columns: subMenuColumns,
            //   value:  member.role.length ? member.role.map((role) => {
            //     return [
            //       { type: 'string', name: 'menu', value: role.application },
            //       { type: 'string', name: 'link', value: role.roles },
            //     ]
            //   }) : []
            // },

            { type: "status", status: member?.access?.toLowerCase() === "active" ?  "success" : 'failed', value:  member?.access?.toLowerCase() === "active" ?  "Active" : 'Revoked' },
          ]
        )
      });
    }
  
  const click = (msg) => {
    console.log(msg);
  };
  
  const btnClick=() => {
    console.log('invite')
  }
  
  const editAction = (data) => {
    console.log("/edit-finance-team-member/:id", data[0].id)
    props.history.push(`/edit-finance-team-member/${data[0].id}`)
  }

  const actionLink = () => {
    props.history.push(`/add-finance-team-member`)
  }
  
  return (
    <SimpleTable
    title="Team Members"
    showHeader={true}
    addText="INVITE NEW"
    rows={rows}
    columns={columns1(columns)}
    colSpan={6}
    actionSendButton={false}
    classProps={changeClassProps()}
    onClick={(message, index) => click(message)}
    // showFilter={true}
    // showFilterText={true}
    showAdd={true}
    donationsCount={10}
    noTitle="No recent donations"
    noPara="Add donation categories so that people can donate for a specific cause."
    noBtnText="Invite Team Members"
    onInviteClick={() => btnClick()}
    editIcon={CreateIcon}
    deleteIcon={DeleteIcon}
    showIconCol={true}
    onAddClick={() => props.history.push(`/add-finance-team-member`)}
    editAction={(data) => editAction(data)}
    deleteAction = { (data) => deleteAction(data)}
    showFooter={true}
    actionLink={(name) => actionLink(name)}
    iconTextSize={14}
    sortColumnIndex={[5]}
  />
  );
}





