import React, { useState } from "react";
import {
  BaseTextField,
  BasePhoneInput,
  BaseSelect,
  MediaUploader,
} from "@dclab/dc-react-ui-kit";
import Grid from "@material-ui/core/Grid";
import "./onboarding.css";

function OrgDetails(props) {
  const [values, setValues] = React.useState({
    orgName: "",
    orgShortName: "",
    orgType: "",
    orgAddress: {
      city: "",
      line1: "",
      line2: "",
      postalCode: "",
      state: "",
    },
    phoneFax: {
      primaryPhone: "",
      secondaryPhone: "",
    },
    orgEmail: "",
    orgWebsite: "",
  });
  React.useEffect(() => {
    delete values.line1;
    delete values.line2;
    delete values.city;
    delete values.postalCode;
    delete values.state;
    props.callback(values);
  }, [values]);

  const handleChange = (prop) => (event) => {
    console.log(values);
    if (prop === "line1") {
      values.orgAddress.line1 = event.target.value;
    }
    if (prop === "city") {
      values.orgAddress.city = event.target.value;
    }
    if (prop === "state") {
      values.orgAddress.state = event.target.value;
    }
    if (prop === "line2") {
      values.orgAddress.line2 = event.target.value;
    }
    if (prop === "postalCode") {
      values.orgAddress.postalCode = event.target.value;
    }
    if (prop === "state") values.orgAddress.state = event.target.value;

    setValues({
      ...values,
      [prop]: event.target.value,
    });
    delete values.line1;
    delete values.line2;
    delete values.city;
    delete values.postalCode;
    delete values.state;
  };
  const PhoneChange = (e, prop) => {
    setValues({ ...values, phoneFax: { ...values.phoneFax, [prop]: e } });
  };

  const styles = {
    btn: {
      height: 66,
      width: "100%",
      margin: 8,
      border: "#D3D8DA",
    },
  };

  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });

  const object = [
    {
      value: "Academic/Educational institution",
      label: "Academic/Educational institution",
    },
    {
      value: "Religious Organization",
      label: "Religious Organization",
    },
    {
      value: "Community/Youth Center",
      label: "Community/Youth Center",
    },
    {
      value: "Education/Career Devolopment",
      label: "Education/Career Devolopment",
    },
    {
      value: "Entrepreneurship & Ideas",
      label: "Entrepreneurship & Ideas",
    },
    {
      value: "Funeral/Cemetery Services",
      label: "Funeral/Cemetery Services",
    },
    {
      value: "Masjid/Islamic Centre",
      label: "Masjid/Islamic Centre",
    },
    {
      value: "Not-for-Profit Organization",
      label: "Not-for-Profit Organization",
    },
    {
      value: "Private Foundation",
      label: "Private Foundation",
    },
    {
      value: "Public Affairs/Politics",
      label: "Public Affairs/Politics",
    },
    {
      value: "Scientific Research",
      label: "Scientific Research",
    },
    {
      value: "Social/Recreational Clubs",
      label: "Social/Recreational Clubs",
    },
    {
      value: "Veterans",
      label: "Veterans",
    },
    {
      value: "Voluntary Association",
      label: "Voluntary Association",
    },
  ];

  const State = [
    {
      value: "New York",
      label: "New York",
    },
    { value: "united states", label: "united states" },
    { value: "Washington", label: "Washington" },
    { value: "Guam", label: "Guam" },
  ];

  const [mediaList, setMediaList] = React.useState([]);
  const mediaObject = [
    {
      imageMetaData: {
        imageUrl:
          "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
        imageName: "Thumbnail",
        imageThumbnail:
          "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
        fileType: "Video",
        imageSize: 0,
      },
      metaData: {
        videoUrl: "https://www.youtube.com/watch?v=6WE3nJiECRw",
        duration: "7m 16s ",
      },
      _id: "610d393141e4dd5be3026723",
      isVideo: true,
      title:
        "Kun Faya Kun - A.R. Rahman, Mohit Chauhan [Drifting Lights x WORMONO Lofi Remake] | Bollywood Lofi",
      mediaURL:
        "https://i.ytimg.com/vi/6WE3nJiECRw/hqdefault.jpg?sqp=-oaymwEjCPYBEIoBSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAckAfnE7HtfnjxhAH-8sEdXdMgLQ",
      createdAt: "2021-08-06T13:29:21.978Z",
      orgId: "600aa7cd4350100e18c7370e",
      updatedAt: "2021-08-06T13:29:21.978Z",
    },
  ];

  const getMediaItems = () => {
    setMediaList(mediaObject);
  };

  const selectMediaItem = (obj) => {
    console.log("Obj is: ", obj);
  };

  const addMediaItem = (obj) => {
    console.log("Media data is: ", obj);
  };

  const imageData = (obj) => {
    const tempMediaArray = mediaList;
    tempMediaArray.push(obj);
    setMediaList(tempMediaArray);
  };

  const videoData = (obj) => {
    console.log("Video data is: ", obj);
  };

  const onDelete = () => {
    console.log("Ondelete Called");
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Organization Name"
              onChange={handleChange("orgName")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Organization Short Name"
              onChange={handleChange("orgShortName")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="field_box OrgypeField">
            <BaseSelect
              value={values.age}
              onChange={handleChange("orgType")}
              object={object}
              label="Organization Type"
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="field_box">
            <MediaUploader
              onDelete={onDelete}
              mediaList={mediaList}
              getMediaItems={getMediaItems}
              showMediaTab={"image"}
              typeOfMedia="/images"
              label="Logo"
              onChange={selectMediaItem}
              addButtonHandler={addMediaItem}
              imageData={imageData}
              maxImageLimit={100}
              videoData={videoData}
              // replyIcon={replyIcon}
              // FileIcon={FileIcon}
              buttonName="Download"
              currentImageSize={1500}
              totalFileUploadLimit={100}
              // selectedMedia={{
              //   logoUrl:
              //     "https://d2imgltiwla1iy.cloudfront.net/6659a2c4f2102d698fff5e934851a98c-test shieyt/1626175177704fd3e1073-3d62-46a3-bba9-37e877b7c900.png",
              //   logoName: "add product",
              // }}
            />
          </div>
        </Grid>

        <Grid item md={12} xs={12}>
          <div className="field_box">
            <MediaUploader
              onDelete={onDelete}
              mediaList={mediaList}
              getMediaItems={getMediaItems}
              showMediaTab={"image"}
              typeOfMedia="/images"
              label="Logo Icon"
              onChange={selectMediaItem}
              addButtonHandler={addMediaItem}
              imageData={imageData}
              maxImageLimit={100}
              videoData={videoData}
              // replyIcon={replyIcon}
              //   FileIcon={FileIcon}
              buttonName="Download"
              currentImageSize={1500}
              totalFileUploadLimit={100}
              // selectedMedia={{
              //   logoUrl:
              //     "https://d2imgltiwla1iy.cloudfront.net/6659a2c4f2102d698fff5e934851a98c-test shieyt/1626175177704fd3e1073-3d62-46a3-bba9-37e877b7c900.png",
              //   logoName: "add product",
              // }}
            />
          </div>
        </Grid>

        <Grid item md={8} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Street Address"
              onChange={handleChange("line1")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={4} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="FL/Suite Number"
              onChange={handleChange("line2")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={4} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="City"
              InputProps={false}
              onChange={handleChange("city")}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={4} xs={12}>
          <div className="field_box">
            <BaseSelect
              value={values.age}
              onChange={handleChange("state")}
              object={State}
              label="State"
            />
          </div>
        </Grid>

        <Grid item md={4} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Zip Code"
              onChange={handleChange("postalCode")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BasePhoneInput
              phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
              country={"us"}
              value={phone.number}
              countryCodeEditable={false}
              onChange={(e) => PhoneChange(e, "primaryPhone")} //   onChange={(phone, country) =>
              //     console.log("onChange", phone, country)
              //   }
              //   onBlur={() => console.log("onBlur")}
              //   onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
              variant="outlined"
              onlyCountries={["us", "ca", "gb", "my"]}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "35px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              //errorText={"Phone number is required"}
              error={true}
              errorStyles={{ marginBottom: "5px" }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              inputProps={{}}
              specialLabel={"Primary Phone Number"}
              // preferredCountries={[]}
              // excludeCountries={[]}
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BasePhoneInput
              phoneInputVariant={"underlineWithFlag"} // default, materialui, underlineInput, underlineWithFlag
              country={"us"}
              value={phone.number}
              countryCodeEditable={false}
              onChange={(e) => PhoneChange(e, "secondaryPhone")} //   onChange={(phone, country) =>
              //   onChange={(phone, country) =>
              //     console.log("onChange", phone, country)
              //   }
              //   onBlur={() => console.log("onBlur")}
              //   onKeyDown={(e) => console.log("onKeyDown", e.target.value)}
              variant="outlined"
              onlyCountries={["us", "ca", "gb", "my"]}
              containerStyle={{}}
              buttonStyle={{}}
              inputStyle={{
                marginLeft: "0px",
                height: "35px",
                width: "100%",
                fontFamily: "Roboto-Regular",
                fontSize: "16px",
              }}
              containerClass={{}}
              //errorText={"Phone number is required"}
              error={true}
              errorStyles={{ marginBottom: "5px" }}
              autoFormat={true}
              disableDropdown={false}
              disabled={false}
              disableCountryCode={false}
              enableSearch={false}
              disableSearchIcon={false}
              placeholder={""}
              inputProps={{}}
              specialLabel={"Secondary Phone Number"}
              // preferredCountries={[]}
              // excludeCountries={[]}
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Organization Email"
              InputProps={false}
              onChange={handleChange("orgEmail")}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>

        <Grid item md={6} xs={12}>
          <div className="field_box">
            <BaseTextField
              label="Organization Website"
              onChange={handleChange("orgWebsite")}
              InputProps={false}
              phone={false}
              search={false}
              style={styles.btn}
              color="primary"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default OrgDetails;
