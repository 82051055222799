import React, { useState, useEffect, Fragment, } from 'react'
import { BaseButton, BaseTextField, BaseSelect, MasterLayoutMiddle, FooterInner } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classes from './editHardware.module.css';
import axios from 'axios'
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Grid from '@material-ui/core/Grid';

function SelectHardware(props) {
    console.log("notAssignedDevices: ", props.notAssignedDevices)

    const { notAssignedDevices } = props;

    const [device, setdevice] = useState({
        appStream: "",
        deviceId: "",
        macAddress: "",
        status: "",
    })


    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onGetNotAssignedDevices();
    }, [])

    // useEffect(() => {
    //     setdevice({
    //         appStream: editDevice.appStream ? editDevice.appStream : "",
    //         deviceId: editDevice.deviceId ? editDevice.deviceId : "",
    //         macAddress: editDevice.macAddress ? editDevice.macAddress : "",
    //         status: editDevice.status ? editDevice.status : "",
    //         image: editDevice.image ? editDevice.image : "",
    //         status: editDevice.status ? editDevice.status : ""
    //     })
    // }, [notAssignedDevices])

    let handleChange = (prop) => (event) => {
        if (prop === "deviceId" && notAssignedDevices && notAssignedDevices.length) {
            let selectedDeviceId = event.target.value;
            let selectedDevice = notAssignedDevices.find((device) => device.deviceId === selectedDeviceId);
            setdevice({ ...selectedDevice });
            console.log("selectedDevice : ", selectedDevice)
        }
    }

    let onSubmit = () => {

        if (appStream && deviceId && macAddress && status && props.match.params.id) {
            let formData = { deviceId: device.deviceId, organizationId: props.match.params.id };
            const orgBaseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
            axios.put(`${orgBaseUrl}/digital-device/assignDeviceToOrganization/${props.match.params.id}`, formData, {
                headers: {
                    'token': localStorage.authToken
                }
            })
                .then((response) => {
                    console.log(response);
                    props.history.push(`/assign-hardware/${props.match.params.id}`)
                    props.showAssignedAlert();
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    let click = () => {
        console.log("button clicked");
        console.log("checking Value: ", device);
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px'
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    let deviceSelect = notAssignedDevices && notAssignedDevices.length ? notAssignedDevices.map((device) => {
        return {
            value: device.deviceId, label: device.deviceId,
        }
    }) : []

    const { appStream, deviceId, macAddress, status } = device;
    let disableBtn = appStream && deviceId && macAddress && status ? false : true;

    
    const style = {
        width:'100%',
        background: "#fff",
      };

    return (
        <Fragment>

            <MasterLayoutMiddle

            header={                
            
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => props.history.push(`/assign-hardware/${props.match.params.id}`)}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
               
            }


            isForm={true}
            formData ={
                <div>

                <Grid container spacing={3}>	      

                <Grid item xs={12}> 
                    <h1 className="title_top">Add Hardware/Device</h1>	
                </Grid>
            
                <Grid item xs={12}> 
                <p className={classes.secondheading}>Select Hardware/Device</p>
                <BaseSelect
                        value={deviceId}
                        onChange={handleChange("deviceId")}
                        object={deviceSelect}
                        style={styles.input}
                        helperText="Announcements are manage at home screen"
                        label='Device Id'
                        placeholder="Device Id"
                        disabled
                    />
                </Grid>

                <Grid item xs={12}> 
                <BaseTextField
                        value={appStream}
                        onChange={handleChange("appStream")}
                        label='App Stream'
                        placeholder="App Stream"
                        InputProps={false}
                        phone={false}
                        search={false}
                        style={styles.input}
                        disabled
                    />
                </Grid>
                <Grid item xs={12}> 
                <BaseTextField
                        value={macAddress}
                        onChange={handleChange("macAddress")}
                        label='Mac Address'
                        placeholder="Mac Address"
                        InputProps={false}
                        phone={false}
                        search={false}
                        style={styles.input}
                        disabled
                    />	
                </Grid>
                <Grid item xs={12}> 
                <BaseTextField
                        value={status}
                        onChange={handleChange("status")}
                        label='Device Status'
                        placeholder="Device Status"
                        InputProps={false}
                        phone={false}
                        search={false}
                        style={styles.input}
                        disabled
                    />
                </Grid>

                <Grid item xs={12}> 
                <div className="form_button">
                    <BaseButton
                        onClick={() => props.history.push(`/assign-hardware/${props.match.params.id}`)}
                        text="cancel"
                            textStyle={styles.txt}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            style={styles.cancelBtn}
                            variant='text'
                        />
                        <BaseButton
                            onClick={onSubmit}
                            text={'Add Now'}
                            disabled={disableBtn}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            style={styles.inviteBtn}
                        />	

                        </div>
                </Grid>



                </Grid>


                
               
            </div>
            }

            isFooter={true}
            footer={
            <FooterInner style={style}/>
            }

            isWhiteBackground={true}

            />



            

        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        notAssignedDevices: state.devices.notAssignedDevices,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onGetNotAssignedDevices: () => dispatch(actions.getNotAssignedDevices()),
        showAssignedAlert: () => dispatch(actions.showAssignedAlert()),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(SelectHardware);