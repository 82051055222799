import React, { Component } from 'react'

import { BaseTabs, MasterLayoutMiddle, FooterInner, } from "@dclab/dc-react-ui-kit";

import TabContent1 from './tabContent1';
import TabContent2 from './tabContent2';

import Alert from '../../components/common/AlertAction';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import TopCardsData from '../../components/common/TopCardsData';
import './adminOrganization.css';
import Grid from '@material-ui/core/Grid';


class index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      errors: {},
      value: 0,
      loading: false,
      organizations: [],
      invitedOrganizations: [],
      orgsAndInvitedOrgs: {}
    };
  }

  static getDerivedStateFromProps(props, state) {

    let errors = props.errors;
    let page = props.page;
    let organizations = props.organizations ? props.organizations : [];
    let invitedOrganizations = props.invitedOrganizations ? props.invitedOrganizations : [];
    let orgsAndInvitedOrgs = props.orgsAndInvitedOrgs ? props.orgsAndInvitedOrgs : [];


    let stateChanged = false;
    let changedState = {};

    if (organizations && JSON.stringify(state.organizations) !== JSON.stringify(organizations)) {
      changedState.organizations = organizations;
      stateChanged = true;
    }

    if (invitedOrganizations && JSON.stringify(state.invitedOrganizations) !== JSON.stringify(invitedOrganizations)) {
      changedState.invitedOrganizations = invitedOrganizations;
      stateChanged = true;
    }

    if (orgsAndInvitedOrgs && JSON.stringify(state.orgsAndInvitedOrgs) !== JSON.stringify(orgsAndInvitedOrgs)) {
      changedState.orgsAndInvitedOrgs = orgsAndInvitedOrgs;
      stateChanged = true;
    }

    if (errors && JSON.stringify(state.errors) !== JSON.stringify(errors)) {
      changedState.errors = errors;
      stateChanged = true;
    }

    if (page && JSON.stringify(state.loading) !== JSON.stringify(page.loading)) {
      changedState.loading = page.loading;
      stateChanged = true;
    }

    if (stateChanged) {
      return changedState;
    }


    return null;
  }
  
  styles = {};

  showAlertHandler = () => {
    console.log("into showAlertHandler");
    console.log(this.state.show);
    if (this.state.errors && this.state.errors.message) {
      this.props.onClearError();
    }
    else {
      this.setState({
        show: !this.state.show
      });
    }

  }

  onClick = (x, y) => {
    console.log('checking onClick function: ', x)
    console.log('checking onClick function Y:', y)
    if (y === 1) {
      this.props.onGetInvitedOrganizations();
      this.setState({ show: true });
    }
    else {
      this.props.onGetOrganizations();
      this.setState({ show: false });
    }
  }

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };


  componentDidMount() {
    const {location, match } = this.props;
    if(match && match.url === "/dashboard" && location && location.search) {
      var escapedString = location.search.replace("?", "").split("=")[1];
      if(escapedString === "1" || escapedString === "0" ) {
        this.props.onGetInvitedOrganizations();
        this.setState({value: parseInt(escapedString)})
      }
    }
    
    this.props.onClearError();
    this.props.onGetOrganizations();
    this.props.onGetOrganizationsCardData();
  }



  render() {

    let pageContent = '';
    let message = 'Invite sent successfully! ';
    let type = 'success';
    let styles = this.styles;

    let { errors, loading, organizations, invitedOrganizations, orgsAndInvitedOrgs, show } = this.state;


    if (loading) {
      pageContent = <Spinner />

    }
    if (errors && errors.message) {
      message = errors.message;
      type = 'error';
      show = true;
    }

    let object = [
      {
        label: "ORGANIZATIONS",
        item: <TabContent1 organizations={organizations} history={this.props.history} />
      },
      {
        label: "INVITED ORGANIZATION",
        item: <TabContent2 invitedOrganizations={invitedOrganizations} history={this.props.history} />,
      }
    ];

    const style = {
      width:'100%',
      background: "#f7f8fa",
    };

    return (
      <div>

          <MasterLayoutMiddle

            content={

              <div>

                  <Grid container spacing={4}>	      

                    <Grid item xs={12}> 
                        <TopCardsData orgsAndInvitedOrgs={orgsAndInvitedOrgs} />
                    </Grid>

                    <Grid item xs={12}> 
                      <BaseTabs
                          title=""
                          object={object}
                          indicatorColor="#7266fb"
                          onClick={(x, y) => this.onClick(x, y)}
                          onChange={(event, newValue) => this.handleChange(event, newValue)}
                          value={this.state.value}
                        />
                      {pageContent}
                    </Grid>

                  </Grid>

              </div>
            }


          isFooter={true}
          footer={
          <FooterInner style={style}/>
          }

          isWhiteBackground={false}


          />

        

        
      </div>
    )
  }
}





const mapStateToProps = state => {
  return {
    organizations: state.organizations.organizations,
    invitedOrganizations: state.organizations.invitedOrganizations,
    orgsAndInvitedOrgs: state.organizations.orgsAndInvitedOrgs,
    page: state.page,
    errors: state.errors,
    showMessage: state.errors.showMessage
  }
};

const mapDispatchToProps = dispatch => {
  return {
    onGetOrganizations: () => dispatch(actions.getAllOrganization()),
    onGetInvitedOrganizations: () => dispatch(actions.getAllInvitedOrganizations()),
    onGetOrganizationsCardData: () => dispatch(actions.getOrganizationsCardData()),
    onClearError: () => dispatch(actions.clearErrors())

  }
};


export default connect(mapStateToProps, mapDispatchToProps)(index);


