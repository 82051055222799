import React, { useState, useEffect, Fragment } from 'react'
import { BaseButton, DrawerMain, BaseTextField, BaseSelect, TextEditor, BaseAvatar, BaseFileSelect, AlertAction, BaseImgChip , MasterLayoutMiddle, FooterInner} from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import classes from './editHardware.module.css';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import MediaUpload from '../../components/common/MediaUpload/MediaUpload';
import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';


function EditHardware(props) {
    const { editDevice } = props;
    const [open, setOpen] = React.useState(false);
    const [uploadedImage, setuploadedImage] = useState(null)
    const [device, setdevice] = useState({ appStream: "", deviceId: "", macAddress: "", status: "", image: "", price: ""
    })
    const [details, setdetails] = useState([{ type: 'paragraph', children: [{ text: "" }] }]);
    const [chipData, setchipData] = useState([])

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        props.onGetEditDevice(props.match.params.id);
    }, [props.match?.params?.id])

    useEffect(() => {
        setdevice({
            appStream: editDevice.appStream ? editDevice.appStream : "",
            deviceId: editDevice.deviceId ? editDevice.deviceId : "",
            macAddress: editDevice.macAddress ? editDevice.macAddress : "",
            price: editDevice.price ? editDevice.price : "",
            image: editDevice?.imageMetaData?.imageUrl ? editDevice.imageMetaData.imageUrl : ""
        })
        setdetails(editDevice.details ? JSON.parse(editDevice.details) : [{ type: 'paragraph', children: [{ text: "" }] }])
        if(editDevice?.imageMetaData?.imageUrl) {
            setchipData([{ key: 0, label: editDevice?.imageMetaData?.imageName ? editDevice.imageMetaData.imageName : "", src: editDevice.imageMetaData.imageUrl, alt: "device-image" }])
        }
    
    }, [editDevice])

    let handleChange = (prop) => (event) => {
        setdevice({
            ...device, [prop]: event.target.value
        });
    }

    const onChange = (e) => {
        setdetails(e)
    };

    let onSubmit = (e) => {
        e.preventDefault();
        let imageMetaData;
        if(uploadedImage) {
            imageMetaData = {
                imageUrl: uploadedImage.src,
                imageName: uploadedImage.title,
                imageSize: 0
            }
        }
        let formData = { ...device,price: device.price, macAddress: device.macAddress.toUpperCase(), details: JSON.stringify(details), imageMetaData };

        if (appStream && deviceId && macAddress && price && image && details) {
            props.onEditDevice(formData)
        }
    }

    let click = () => {
        console.log("button clicked");
        console.log("checking Value: ", device);
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const styles2 = {
        border: '1px solid black'
    };


    const callback = (selectedFiles) => {
        if(selectedFiles.src) {
            setuploadedImage(selectedFiles)
        }
    };

    const closeDrawer = () => {
        setOpen(!open);
      };
      
      const onCancel = () => {
        setOpen(false);
      };
      
      const onSave = () => {
        if(uploadedImage) {
            setchipData([{ key: 0, label: uploadedImage.title, src: uploadedImage.src, alt: uploadedImage.title }])
            setdevice({ ...device, image: uploadedImage.src })
            setOpen(!open);
        }
      };


    const onInputClick = () => {
    console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
        setOpen(!open);
    };

    const deleteChip = () => {
        setdevice({ ...device, image: "" })
        setchipData([])
    }

    const { appStream, deviceId, macAddress, image, price } = device;
    let disableBtn = appStream && deviceId && macAddress&& price && image? false : true;

    const style = {
        width:'100%',
        background: "#fff",
      };

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Image"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                saveDisabled={false}
            >
                <BaseFileSelect
                    selectedFiles={callback}
                />
            </DrawerMain>

            <MasterLayoutMiddle
                header={ 
                <div>
                      {
                    props.errors && props.errors.error && <AlertAction
                    alert={props.errors.message}
                    alertType={"error"}
                    //   onClick={this.closeAlert}
                    />
                    }

                    {/* <Link to="/admin-organization" style={{textDecoration: 'none'}} > */}
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => props.history.push(`/hardware-details/${props.match?.params?.id}`)}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {/* </Link> */}


                </div>
                }
                isForm={true}
                formData ={
                    <form onSubmit={onSubmit}>
                    <Grid container spacing={3}>

                        <Grid item xs={12}> 
                            <h1 className="title_top">Edit Hardware/Device</h1>	 
                        </Grid>

                        <Grid item xs={12}> 
                            <h1 className="sub_title">Device Information</h1>	 
                        </Grid>	 	      

                        <Grid item xs={12}> 
                            <div className="field_box">
                            {props.page && props.page.loading && <Spinner />}
                            <div className="field_box">
                                <BaseTextField
                                    value={appStream}
                                    onChange={handleChange("appStream")}
                                    label='App Stream'
                                    placeholder="App Stream"
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    //count={100}
                                    style={styles.input}
                                    color="green"
                                    disabled
                                //type="search"
                                />
                            </div>
                            {/* <BaseSelect
                                value={appStream}
                                onChange={handleChange("appStream")}
                                object={[
                                    {
                                        value: appStream,
                                        label: appStream,
                                    }
                                ]}
                                style={styles.input}
                                helperText=""
                                label='App Stream'
                                placeholder="App Stream"
                                disabled
                            /> */}
                         </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">
                                <BaseTextField
                                    value={deviceId}
                                    onChange={handleChange("deviceId")}
                                    label='Device Id'
                                    placeholder="Device Id"
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    //count={100}
                                    style={styles.input}
                                    color="green"
                                    disabled
                                //type="search"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">
                                <BaseTextField
                                    value={macAddress.toUpperCase()}
                                    onChange={handleChange("macAddress")}
                                    label='Mac Address'
                                    placeholder="Mac Address"
                                    InputProps={false}
                                    phone={false}
                                    search={false}
                                    //count={100}
                                    style={styles.input}
                                    color="green"
                                    disabled
                                //type="search"
                                />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">
                            {/* <BaseTextField
                                value={price}
                                onChange={handleChange("price")}
                                label='Device Cost'
                                placeholder="Device Cost"
                                InputProps={false}
                                phone={false}
                                search={false}
                                style={styles.input}
                                color="green"
                                type="string"
                                onBlur={() => price ? setdeviceError({priceError: false }) : setdeviceError({ priceError: true })}
                                error={!price && priceError ? true : false}
                            /> */}
                            <TextField
                                value={price}
                                onChange={handleChange("price")}
                                label='Device Cost'
                                placeholder="Device Cost"
                                style={styles.input}
                                type="number"
                                // onBlur={() => price ? setdeviceError({priceError: false }) : setdeviceError({ priceError: true })}
                                // error={!price && priceError ? true : false}
                                InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                }}
                            />
                            </div>
                        </Grid>

                        {/* <Grid item xs={12}> 
                            <div className="field_box">
                            <BaseSelect
                                value={status}
                                onChange={handleChange("status")}
                                label='Device Status'
                                placeholder="Device Status"
                                object={[
                                    { value: "Available", label: "Available" },
                                    { value: "Unavailable", label: "Unavailable"},
                                    { value: "Defective", label: "Defective"},
                                    { value: "Lost", label: "Lost"},
                                    { value: "Locked", label: "Locked"},
                                    { value: "Active", label: "Active"},
                                    { value: "Shipped", label: "Shipped"},
                                    { value: "Pending", label: "Pending"},
                                ]}
                                style={styles.input}

                            />
                            </div>
                        </Grid> */}

                        <Grid item xs={12}> 
                        {console.log("LogoUrl is: ", chipData?.[0]?.src)}
                        {chipData?.[0]?.src && 
                            <MediaUpload
                                addMediaItem = {callback}
                                label = "Device Image"
                                buttonName = "ADD Image"
                                typeOfMedia='/images'  
                                selectedMedia = {{logoUrl: chipData?.[0]?.src, logoName: chipData?.[0]?.label }} 
                                 
                            />
                        }
                        
                            {/* <div className="field_box">
                                {/* <BaseFileSelect
                                    selectedFiles={callback}
                                /> */}
                            {/*<BaseImgChip
                                chipData={chipData}
                                onInputClick={onInputClick}
                                onChipInputClick={onChipInputClick}
                                deleteChip={deleteChip}
                                // helperText='Helper Text'
                                label="Image"
                            /> */}

                            {/* <div style={{ margin: "25px", display: "inline-block", position: "relative", top: "13px" }}>
                                <BaseAvatar
                                    alt="GettyImage"
                                    src={image}
                                    size="large"
                                    variant="square"
                                />
                            </div> 
                        </div>*/}
                        </Grid>

                        <Grid item xs={12}> 
                            <TextEditor label={"Details"} maxCharCount={100} onChange={(e) => onChange(e)} value={details} style={styles2} />
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="form_button">
                                <BaseButton
                                    onClick={() => props.history.push("/hardware")}
                                    text="cancel"
                                    textStyle={styles.txt}
                                    disableElevation={false}
                                    disableFocusRipple={false}
                                    disableRipple={false}
                                    variant='text'
                                />
                                <BaseButton
                                    onClick={onSubmit}
                                    type="submit"
                                    text={'Save'}
                                    disabled={disableBtn}
                                    disableElevation={false}
                                    disableFocusRipple={false}
                                    disableRipple={false}
                                    style={styles.inviteBtn}
                                />
                            </div>
                        </Grid>

                    </Grid>
                </form>

                }



                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }

                isWhiteBackground={true}


                />


        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        editDevice: state.devices.editDevice,
        page: state.page,
        errors: state.errors
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onGetEditDevice: (id) => dispatch(actions.getEditDevice(id)),
        onEditDevice: (device) => dispatch(actions.editDevice(device, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(EditHardware);