import React, { useState, Fragment, useEffect } from 'react'
import { DrawerMain, BaseButton, BaseTextField, BaseTextFieldPassword,DrawerListRadioButton , BaseImgChip, AlertAction, MasterLayoutMiddle, FooterInner, FormSwitch } from "@dclab/dc-react-ui-kit";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';
import Grid from '@material-ui/core/Grid';
import './teamMember.css';
import Right from '../../assets/images/done-14px.svg';
import Close from '../../assets/images/clear-24px.svg';

function AddFinanceTeamMember(props) {
    // console.log(props)
    const [open, setOpen] = React.useState(false);
    const [member, setmember] = useState({ password: "", access: false, fullName: "", email: "", role: "" })

    const [memberError, setmemberError] = useState({ passwordError: false, fullNameError: false, imageError: false, emailError: false})
    const [chipData, setchipData] = useState([])
    const [selectedData, setselectedData] = useState("")

    const roleData = [
        {
          id: 1,
          primary: "Admin",
          secondary: "The Administrator has the highest access of the console after Owner.",
        },
        {
          id: 2,
          primary: "Manager",
          secondary: "Best for employees who regularly create/manage/publish contents.",
        },
      ];

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, [])

    let handleChange = (prop) => (event) => {
        setmember({ ...member, [prop]: event.target.value });
    }

    const handleSwitchChange = (event) => {
        setmember({ ...member, access: event });
    };

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }

    const checkPassword = (password) => {
        return password.length < 8 ||
          !/[a-z]/.test(password) ||
          !/[A-Z]/.test(password) ||
          !/[0-9]/.test(password)
          ? false
          : true;
    }
    

    let onSubmit = () => {
        const {fullName, email, password, role, access} = member;
        let formData = { 
            name: {
                firstName: fullName && fullName.split(" ")[0] ? fullName.split(" ")[0] : "",
                lastName: fullName && fullName.split(" ")[1] ? fullName.split(" ")[1] : "",
            },
            userName: email,
            role: [{ application: 'deenconnect', roles: role === "Admin" ? "hikmah_admin" : "hikmah_manager" }],
            access: access ? "active" : "revoked",
            password: password
        };

        if (password && fullName && role && email && formData.access) {
            props.onAddMember(formData)
        }
    }

    let styles = {
        txt: {
            color: '#4D4D4D ',
            letterSpacing: '1.26px',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '15px'
        },
        chipInput: {
            width: "100%"
        },
        backBtn: {
            backgroundColor: 'white',
            borderRadius: '18px',
            color: '#4D4D4D',
            textAlign: 'left',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '15px',
            width: '86px',
            height: '36px'
        },
        routes: {
            marginLeft: '70px',
            width: '90%',
            padding: '30px 0',
            display: 'flex'
        },
        input: {
            height: '48px',
            marginTop: '20px',
            width: '100%',
            border: "#707070",
        },
        input2: {
            height: '48px',
            marginTop: '20px',
            marginRight: '22px',
            width: '48%',
            float: 'left',
            border: "#707070",
        },
        input3: {
            height: '48px',
            marginTop: '20px',
            width: '48%',
            border: "#707070",
        },
        inviteBtn: {
            textAlign: 'center',
            color: 'white',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#2490F9 0% 0% no-repeat padding-box',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        disableBtn: {
            textAlign: 'center',
            color: '#222',
            letterSpacing: '1.26px',
            height: '45px',
            background: '#eee',
            borderRadius: '23px',
            padding: '14px 45px'
        },
        cancelBtn: {
            textAlign: 'center',
            color: 'black',
            letterSpacing: '1.26px',
            height: '45px',
            borderRadius: '23px',
            backgroundColor: 'white',
            padding: '14px 45px'
        }
    }

    const style = {
        width:'100%',
        background: "#fff",
      };

    const closeDrawer = () => {
        setOpen(!open);
      };
      
      const onCancel = () => {
        setOpen(false);
      };
      
      const onSave = () => {
        if(member.role) {
            setchipData([{ key: 0, label: member.role, src: "https://www.w3schools.com/howto/img_avatar.png", alt: "avatar" }])
            // setmember({ ...member, role: member.role})
            setOpen(!open);
        }
      };


    const onInputClick = () => {
    console.log("input clicked");
    };

    const onChipInputClick = () => {
        console.log("input chip clicked");
        setOpen(!open);
    };

    const deleteChip = () => {
        setmember({ ...member, role: "" })
        setchipData([])
        setselectedData("")
    }

    const getData = (value, secondary) => {
        setmember({ ...member, role: value })
        setselectedData({
            id: 3,
            primary: value,
            secondary: secondary,
        })
    };

    const { password, fullName, access, role, email } = member;
    const { fullNameError, emailError } = memberError;
    let disableBtn = checkPassword(password) && fullName && role && validateEmail(email) ? false : true;    

    return (
        <Fragment>
            <DrawerMain
                open={open}
                openHandler={closeDrawer}
                title="Assign Role"
                onCancel={onCancel}
                onSave={onSave}
                loading={false}
                // img={Logo}
                saveDisabled={!member.role}
            >
            
            <DrawerListRadioButton objects={roleData} getData={getData} selectedData={selectedData} />
            </DrawerMain>

            <MasterLayoutMiddle
                header={
                    <div>


                    {/* <Link to="/admin-organization" style={{textDecoration: 'none'}} > */}
                    <div className="backBtnFrom">
                    <BaseButton
                        onClick={() => props.history.push("/team-members")}
                        text="back"
                        textStyle={styles.txt}
                        disableElevation={false}
                        disableFocusRipple={false}
                        disableRipple={false}
                        style={styles.backBtn}
                        startIcon={<ChevronLeftIcon />}
                    />
                    </div>
                    {/* </Link> */}
                    {props.page && props.page.loading && <Spinner />}
                </div>
                }
                isForm={true}
                formData ={
                    <div>
                                            {
                    props.errors && props.errors.error && <AlertAction
                    alert={props.errors.message}
                    alertType={"error"}
                    //   onClick={this.closeAlert}
                    />
                    }
                    <Grid container spacing={3}>

                        <Grid item xs={12}> 
                            <h1 className="title_top">Add Team Member</h1>	 
                        </Grid>	      


                        <Grid item xs={12}> 
                            <div className="field_box">
                            <BaseTextField
                                value={fullName}
                                onChange={handleChange("fullName")}
                                label='Full Name'
                                placeholder="Full Name"
                                InputProps={false}
                                phone={false}
                                search={false}
                                // count={100}
                                style={styles.input}
                                onBlur={() => fullName ? setmemberError({...memberError, fullNameError: false }) : setmemberError({...memberError, fullNameError: true })}
                                error={!fullName && fullNameError ? true : false}
                                helperText={!fullName && fullNameError ? "Required" : "Helper Text"}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">
                            <BaseTextField
                                value={email}
                                onChange={handleChange("email")}
                                label='Email Address'
                                placeholder="Email Address"
                                InputProps={false}
                                phone={false}
                                search={false}
                                // count={100}
                                style={styles.input}
                                color="green"
                                type="string"
                                onBlur={() => email && validateEmail(email) ? setmemberError({ ...memberError, emailError: false }) : setmemberError({ ...memberError, emailError: true })}
                                error={!email && emailError ? "Required" : email && !validateEmail(email) && emailError ? true : false}
                                helperText={!email && emailError ? "Enter valid email address" : email && !validateEmail(email) && emailError ? "Enter valid email address" : "Helper Text"}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box" style={{marginTop: '20px'}} onClick={onChipInputClick}>
                            <BaseImgChip
                                chipData={chipData}
                                onInputClick={onInputClick}
                                onChipInputClick={onChipInputClick}
                                deleteChip={deleteChip}
                                //helperText='Helper Text'
                                label="Assign Role"
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">
                            <BaseTextFieldPassword
                                value={member.password}
                                onChange={handleChange("password")}
                                label='Password'
                                placeholder="Password"
                               // helpText="Helper Text"
                                showCheck={!password ? "none" : "flex"}
                                visible={!password ? "none" : "flex"}
                                // onBlur={() => password && checkPassword(password) ? setmemberError({ ...memberError, passwordError: false }) : setmemberError({ ...memberError, passwordError: true })}
                                // error={!password && passwordError ? "Required" : password && checkPassword(password) && passwordError ? true : false}
                                helperText={password ? "Password Strength" : "Helper Text"}
                                tick={Right}
                                close={Close}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}>
                            <div className="border_top_bottm">
                            <FormSwitch
                            headline='Hikmah Access '
                            body="Allow member to login to DEENCONNECT Hikmah or revoke access"
                            values={access}
                            handleSwitchChange={(e) => handleSwitchChange(e)}
                            />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="form_button">
                            <BaseButton
                            onClick={() => props.history.push("team-members")}
                            text="cancel"
                            textStyle={styles.txt}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            variant='text'
                        />

                        <BaseButton
                            onClick={onSubmit}
                            text={'Continue'}
                            disabled={disableBtn}
                            disableElevation={false}
                            disableFocusRipple={false}
                            disableRipple={false}
                            style={disableBtn ? styles.disableBtn : styles.inviteBtn}
                        />
                            </div>
                        </Grid>

                        <Grid item xs={12}> 
                            <div className="field_box">

                            </div>
                        </Grid>

                        </Grid>
                    </div>
                }

                isFooter={true}
                footer={
                <FooterInner style={style}/>
                }
                isWhiteBackground={true}
                />
        </Fragment>
    )

}

const mapStateToProps = state => {
    return {
          page: state.page,
          errors: state.errors
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onAddMember: (member) => dispatch(actions.addFinanceTeamMember(member, ownProps)),
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(AddFinanceTeamMember);
