import React, {useEffect, useState} from "react";
import Grid from '@material-ui/core/Grid';
import {MasterLayoutMiddle, FooterInner, CardDetailsTable, EventLogs,  AlertAction} from "@dclab/dc-react-ui-kit";
import './style.css';
import Moment from "moment";
import { DeleteContact } from "./Messages/api";
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import Check from "./check_circle-24px.svg";
import { serialize2 } from "./markdowntoreact";
import { AlertDialogue } from "@dclab/dc-react-ui-kit";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import * as actions from '../../store/Actions/index';
import Spinner from '../../components/common/Spinner';

 

function ContactUsDetails(props) {
  const [contact, setcontact] = useState({})
  const [open, setOpen] = useState(false);
  const [showAlert, setshowAlert] = useState(false);
  const [colorObject, setcolorObject] = useState({
    'New': '#7266FB',
    'In-progress': '#EEB000',
    'Closed': '#B00020',
    'Done': '#3CC480'
  })

  useEffect(() => {
    if(props?.location?.state?.contact) {
      props?.location?.state?.contact && setcontact(props.location.state.contact)
    }
}, [props?.location?.state?.contact])

useEffect(() => {

  if(props?.location?.state?.assigneeUpdated) {
    setshowAlert(true);
    setTimeout(() => {
      setshowAlert(true)
    }, 8000);
  }
}, [])


// ======= Table Start ========//
let header = {
  tableName: `Details`,
  
};

let data = [
  {
    field: "Date",
    value: contact && Moment(contact.updatedAt).format('MMM DD, YYYY, hh:mm A')
  },
  {
    field: "Name",
    value: contact && contact?.name?.firstName + (contact?.name?.lastName ? ' ' + contact?.name?.lastName : '')
  },
  {
    field: "Email",
    value: contact?.email ? contact.email : "<span style = 'color: #818E94'>No data</span>"
  },
  {
    field: "Phone number",
    value: contact?.phone ? contact.phone : "<span style = 'color: #818E94'>No data</span>"
  },
  {
    field: "App stream",
    value: contact?.stream ? contact?.stream === "DCWEBSITE" ? "DC WEBSITE" : contact.stream  : "<span style = 'color: #818E94'>No data</span>"
  },
];

const card2 = [
  {
    field: "Inquiry type",
    value: contact?.inquiryType ?? "<span style = 'color: #818E94'>No data</span>"
  },
  // {
  //   field: "Status",
  //   value:  contact?.states ?? "New"
  // },
  {
    field: "Message",
    value: contact?.message ?? "<span style = 'color: #818E94'>No data</span>"
  },
]


let footerLinkName = "ASSIGN";
let footerDeleteButton = "Delete";

// HANDLE FOOTER LINK
const onClick = () => {
  console.log('edit')
  props.history.push({pathname: "/communication/contact-us-respond", state: {contact: contact} })
}

const onDeleteClick = () => {
  setOpen(true)
}
// =====Table Start ==========//
  const handleConfirm = async () => {
    setOpen(false);
    props.onDeleteContact(contact._id);
    // console.log('delete', contact._id)
    // props.history.push({pathname: "/communication/contact-us", state: { showMessageAlert: true }});
    // setOpen(false)
  };

  const style2 = {
    width:470,
    marginLeft:800
  };
    
  // ============================== Timeline Start ======================================//

  let details = contact?.responseTimeline?.length ? contact.responseTimeline.map((log, i) => {
    let x = log?.note && log?.note?.includes('"type":') && JSON.parse(log?.note);
    let editor;
    if(x) {
      editor = { children: [ ...x ] }
    }

    const description = log?.note && log?.note?.includes('"type":') ? serialize2(editor) : log?.note;

    return {
      id: i + 1, icon: Check, status: log.status, date: log.createdAt ? Moment(log.createdAt).format('MMM DD, YYYY, hh:mm A') : "",  details: [
        { title: 'Reviewed by', message: log?.reviewedBy ? log.reviewedBy : 'Admin' },
        { title: 'Assignee', message: log?.assignee?.[0]?.name ? log.assignee[0].name : '' },
        { title: 'Note', message: log?.note?.length > 0 && !description.includes("<p>") ? description : "No Data" }
      ]
    }
}) : [];

  details = details && details.length ? details.reverse() : [];

  // ============================== Timeline End ======================================//
  
  
  const style = {
    width:'100%',
    background: "#f7f8fa",
  };
  
  return (
    <MasterLayoutMiddle

    header={
      <>
        <div className="backBtn">
          <ChevronLeft style={{ alignSelf: "center", marginLeft: 7 }} />
          <Link
            to={{ pathname: "/communication/contact-us", state: 1, }}
            style={{ alignSelf: "center", color: "black", textDecoration: "none", }} > Back </Link>{" "}
        </div>

        {showAlert && (
              <div className="header_alert">
                <AlertAction
                  alert={"Inquiry updated successfully"}
                  alertType={"success"}
                  onClick={() => setshowAlert(false)}
                />
              </div>
            )}

      </>
    }

      content={
        <div className="detailsTableContainer">

            <Grid container spacing={3} >
            {props.page && props.page.loading && <Spinner />}
            <Grid item md={12} sm={12} xs={12}>
            <CardDetailsTable 
              data={data} 
              data2={card2} 
              twoColumns 
              header={header} 
              footerLinkName={footerLinkName} 
              footerDeleteButton={footerDeleteButton}
              onClick={() => onClick()}
              onDeleteClick={() => onDeleteClick()}

              Status={true}
              StatusLabel="Status"
              StatusText={contact?.status ?? "New"}  
                StatusText={contact?.status ?? "New"}  
              StatusText={contact?.status ?? "New"}  
              statuscolor={colorObject[contact?.status ?? "New"]}
            />
            </Grid>
              
            <Grid item md={12} sm={12} xs={12} >
              <div className="FullTable">
             <EventLogs data={details}
                headTitle="Timeline"
                schedule={true}
              />
              </div>
              </Grid>
            </Grid>
        
            <AlertDialogue
              title="Delete Inquiry?"
              description="Are you sure you want to delete this inquiry?
              Deleting will permanently remove from everywhere."
              cancelText="Cancel"
              confirmText="Confirm"  
              open={open}
              handleCancel={() => setOpen(false)}
              handleClose={() => setOpen(false)}
              handleConfirm={() => handleConfirm()}
              styles={style2}
            />
            
     
        </div>
      }
      isFooter={true}
      footer={
        <FooterInner style={style} />
      }
      isWhiteBackground={false}
    />
  );
}
const mapStateToProps = state => {
  return {
    page: state.page,
    errors: state.errors
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onDeleteContact: (id) => dispatch(actions.deleteContact(id, ownProps)),
    onClearError: () => dispatch(actions.clearErrors())
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUsDetails);