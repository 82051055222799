import React from "react";
import { SimpleTable } from "@dclab/dc-react-ui-kit";
import Pic1 from "../../assets/images/Ellipse_1.png";
import CreateIcon from "../../assets/img/create-24px.svg";
import DeleteIcon from "../../assets/img/delete_forever-24px.svg";
import { ReactComponent as Crown } from "../../assets/img/crown.svg";
import Grid from "@material-ui/core/Grid";
import {
  TextInputFixedLabel,
  ModalFour,
  SelectFieldOne,
} from "master-react-lib";

import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
import axios from "axios";
let backendServerURL =
  process.env.REACT_APP_PROJECT_ENV === "prod"
    ? process.env.REACT_APP_API_URL_PROD
    : process.env.REACT_APP_API_URL_DEV;

export default function Team(props) {
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    userName: "",
    access: "Active",
    orgId: localStorage.getItem("OrgId"),
  });
  const [role, setRole] = React.useState("owner");
  const teamMembers = props.teamMembers;
  const id = props.id;
  const deleteAction = props.deleteAction;
  const getTeams = props.callback;

  let columns = [
    // { type: "number", name: "serial_number", label: "NO.", width: 'auto' },
    // { type: "url", name: "single_pic", label: "IMAGE", width: 'auto'},
    { type: "string", name: "name", label: "NAME", width: "25%" },
    { type: "string", name: "date", label: "ROLE", width: "25%" },
    { type: "string", name: "email", label: "EMAIL", width: "25%" },
    { type: "action", name: "actionIcons", label: "ACTION", width: "25%" },
    // { type: "status", name: "status", label: "", width: '5%' },
  ];

  let classProps = () => {
    const tempArray = [];
    columns.forEach((column, index) => {
      tempArray.push(`class${index}`);
    });
    return tempArray;
  };

  const changeClassProps = () => {
    let tempArray = classProps();
    tempArray[0] = "class15";
    return tempArray;
  };

  const columns1 = (columns) =>
    columns.map((obj) => {
      // delete obj.id;
      // delete obj.name;
      return obj;
    });

  let rows = [];
  if (teamMembers && teamMembers.length) {
    rows = teamMembers.map((member, index) => {
      let userFullName =
        member?.name?.firstName || member?.name?.lastName
          ? member.name.firstName + " " + member.name.lastName
          : "";
      let userImage = member?.imageDetails?.image
        ? member.imageDetails.image
        : "";
      return [
        // { type: "id", id:member._id ? member._id : '', name: 'serial_number',  value: index+1 },
        // { type: "url", name: "userPic", src: userImage, userInitials: userFullName  },
        { type: "string", name: "name", value: userFullName },

        {
          type: "string",
          name: "category",
          value: member.role.length
            ? member.role.map((role) => {
                return role && role.roles === "owner" ? (
                  <span class="owner-crown">
                    {" "}
                    Owner <Crown />{" "}
                  </span>
                ) : role && role.roles === "admin" ? (
                  "Admin"
                ) : role && role.roles === "manager" ? (
                  "Manager"
                ) : (
                  ""
                );
              })
            : "",
        },
        {
          type: "string",
          name: "date",
          value: member.userName ? member.userName : "",
        },
        // { type: 'string', name: 'collapse', value: 'Show' },
        // {
        //   type: 'additionalData',
        //   columns: subMenuColumns,
        //   value:  member.role.length ? member.role.map((role) => {
        //     return [
        //       { type: 'string', name: 'menu', value: role.application },
        //       { type: 'string', name: 'link', value: role.roles },
        //     ]
        //   }) : []
        // },

        // { type: "status", status: member?.access?.toLowerCase() === "active" ? "success" : 'failed', value: member?.access?.toLowerCase() === "active" ? "Active" : 'Revoked' },
      ];
    });
  }

  const click = (msg) => {
    console.log(msg);
  };

  const btnClick = () => {
    console.log("invite");
  };

  const editAction = (data) => {
    console.log("/edit-finance-team-member/:id", data[0].id);
    props.history.push(`/edit-finance-team-member/${data[0].id}`);
  };

  const actionLink = () => {
    props.history.push(`/add-finance-team-member`);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const selectdata = [
    { value: "owner", select: "Owner" },
    // { value: "manager", select: "Manager" },
  ];

  const handleChange = (event) => {
    setRole(event.target.value);
  };
  const teamsInputHandler = (event) => {
    setValues({ ...values, [event.target.name]: event.target.value });
    console.log(values);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        backendServerURL + "/team-members",
        {
          name: { firstName: values.firstName, lastName: values.lastName },
          userName: values.userName,
          role: [{ application: "deenconnect", roles: role }],
          orgId: values.orgId,
          access: values.access,
        },
        { headers: { token: localStorage.getItem("authToken2") } }
      );
      if (response) {
        getTeams();
        console.log(response);
      }
      setOpen(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div>
      <SimpleTable
        title="Team Members"
        showHeader={true}
        addText="INVITE NEW"
        rows={rows}
        columns={columns1(columns)}
        colSpan={6}
        actionSendButton={false}
        classProps={changeClassProps()}
        onClick={(message, index) => click(message)}
        // showFilter={true}
        // showFilterText={true}
        showAdd={true}
        donationsCount={10}
        noTitle="No recent donations"
        noPara="Add donation categories so that people can donate for a specific cause."
        noBtnText="Invite Team Members"
        onInviteClick={() => btnClick()}
        editIcon={CreateIcon}
        deleteIcon={DeleteIcon}
        showIconCol={true}
        onAddClick={() => handleOpen()}
        editAction={(data) => editAction(data)}
        deleteAction={(data) => deleteAction(data)}
        showFooter={true}
        actionLink={(name) => actionLink(name)}
        iconTextSize={14}
        sortColumnIndex={[5]}
      />

      <ModalFour
        open={open}
        handleClose={handleClose}
        width="540px"
        height="512px"
        title="Invite team member"
        LocationStatus={false}
        eventTitleStatus={false}
        // Programlink="http://localhost:3000/"

        buttonlabel="SEND INVITE"
        clickSubmit={handleSubmit}
        buttoncolor="#33B651"
        color="#fff"
        target="_blank"
        buttonwidth="80%"
        buttonheight="50px"
        ButtonRadius="4px"
        textAlign="center"
        body={
          <div className="ModalForm">
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <div className="field_box">
                  <TextInputFixedLabel
                    label="First Name"
                    labelStatus={true}
                    helpertext="Helper text"
                    onChange={teamsInputHandler}
                    name="firstName"
                  />
                </div>
              </Grid>

              <Grid item md={6} xs={12}>
                <div className="field_box">
                  <TextInputFixedLabel
                    label="Last Name"
                    labelStatus={true}
                    helpertext="Helper text"
                    onChange={teamsInputHandler}
                    name="lastName"
                  />
                </div>
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="field_box">
                  <TextInputFixedLabel
                    label="Email address"
                    labelStatus={true}
                    helpertext="Helper text"
                    onChange={teamsInputHandler}
                    name="userName"
                  />
                </div>
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="field_box">
                  <SelectFieldOne
                    label="Role"
                    selectdata={selectdata}
                    defaultValue={"owner"}
                    handleChange={handleChange}
                    // onChange={handleChange}
                    name="role"
                  />
                </div>
              </Grid>

              <Grid item md={12} xs={12}>
                <div className="formInfoSection">
                  <InfoRoundedIcon />
                  <p>
                    If the invitee doesn't have an account with DEENCONNECT,
                    he'll get an invitation email to open account. Once he opens
                    an account he'll become a part of your organization.
                  </p>
                </div>
              </Grid>
            </Grid>
          </div>
        }
      />
    </div>
  );
}
